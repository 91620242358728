import React, { Component } from "react";

import { Link, Redirect } from "react-router-dom";

// Import custom components
import UserPanel from "./user-panel.component";

// koneksi
import { STATUS_LAYANAN_KUA, STATUS_LAYANAN_CAPIL } from "../../../function/Koneksi";

class SidebarAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="bayu-sidebar custom-scrollbar bayu-sidebar-open">
        <ul className="sidebar-menu">
          <div className="dropdown-basic">
            <div className="row">
              <div className="dropdown">
                <Link to={process.env.PUBLIC_URL}>
                  <div className="btn-group m-1">
                    <button
                      type="button"
                      className="dropbtn btn-primary-custom"
                      style={{ borderRadius: "10px", width: "155px" }}
                    >
                      <i className="fa fa-home"></i> Dashboard
                    </button>
                  </div>
                </Link>
              </div>
              <div className="dropdown">
                <Link to={process.env.PUBLIC_URL+ "/admin/perangkat-by-instansi"}>
                  <div className="btn-group m-1">
                    <button
                      type="button"
                      className="dropbtn btn-primary-custom"
                      style={{ borderRadius: "10px", width: "155px" }}
                    >
                      <i className="fa fa-users"></i> Perangkat
                    </button>
                  </div>
                </Link>
              </div>
              {/* <div className="dropdown">
                <Link to={process.env.PUBLIC_URL+ "/admin/konfigurasi-wa"}>
                  <div className="btn-group m-1">
                    <button
                      type="button"
                      className="dropbtn btn-success"
                      style={{ borderRadius: "10px", width: "155px" }}
                    >
                      <i className="fa fa-whatsapp"></i> Konfigurasi WA
                    </button>
                  </div>
                </Link>
              </div> */}
              
              
            </div>
          </div>
        </ul>
      </div>
    );
  }
}

export default SidebarAdmin;
