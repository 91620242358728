import React, { Component } from 'react';

// Import custom components
import HeaderBaru from "../components/common/header/header.baru";
import SidebarAdmin from "./common/sidebar/sidebar.admin";
import SidebarSuperAdmin from "./common/sidebar/sidebar.superadmin";
// import Customizer from './common/customizer.component';

import { Link, Redirect } from 'react-router-dom';
import { DataToken } from '../function/Format';
// import { JENIS_DESA } from '../function/Koneksi';

import Sidebar from './common/sidebar/sidebar.component';

const jwt = require('jsonwebtoken');

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			alert: null,
			show: false,
			basicTitle: '',
			basicType: 'default',
			pesanAlert: '',
			layanan: [],
			perizinan: [],
			menuUtama: 'inherit',
			menuWilayah: 'none',
			menuPengaturan: 'none',
			menuPerangkat: 'none',
			menuLaporan: 'none',
			menuAdministrasi: 'none',
			menuPerubahanData: 'none',
			akun: DataToken(sessionStorage.getItem('access_token'))
		};
	}
	bukaMenu = (wil) => {
		switch (wil) {
			case 'laporan':
				this.setState({ menuUtama: 'none', menuLaporan: 'inherit' });
				break;
			case 'wilayah':
				this.setState({ menuUtama: 'none', menuWilayah: 'inherit' });
				break;
			case 'perangkat':
				this.setState({ menuUtama: 'none', menuPerangkat: 'inherit' });
				break;
			case 'pengaturan':
				this.setState({ menuUtama: 'none', menuPengaturan: 'inherit' });
				break;

			case 'perubahan_data':
				this.setState({
					menuUtama: 'none',
					menuAdministrasi: 'none',
					menuPerubahanData: 'inherit'
				});
				break;
			case 'administrasi':
				this.setState({
					menuUtama: 'none',
					menuPerubahanData: 'none',
					menuAdministrasi: 'inherit'
				});
				break;
			case 'utama':
				this.setState({
					menuPengaturan: 'none',
					menuPerangkat: 'none',
					menuWilayah: 'none',
					menuLaporan: 'none',
					menuAdministrasi: 'none',
					menuPerubahanData: 'none',
					menuUtama: 'inherit'
				});
				break;
		}
	};
	render() {
		// console.log(this.state.akun)
		// if (this.state.akun == null) {
		// 	return <Redirect to="/" push={true} />;
		// }

		const menuWithRole = () => {
			return (
				<div className="row" style={{ display: this.state.menuUtama }}>
					<div className="card col-sm-1 p-0 mr-2 mb-0">
						<Link to={process.env.PUBLIC_URL + '/admin/kartu-keluarga'}>
							<div className="card-body p-1 text-center text-middle">
								<i className="fa fa-file-o" style={{ fontSize: '60px', color: '#2e99b0' }} />
								<p style={{ fontSize: '12px', color: 'black' }}>Kartu Keluarga</p>
							</div>
						</Link>
					</div>
					<div className="card col-sm-1 p-0 mr-2 mb-0">
						<Link to={process.env.PUBLIC_URL + '/admin/penduduk'}>
							<div className="card-body p-1 text-center text-middle">
								<i className="fa fa-users" style={{ fontSize: '60px', color: '#2e99b0' }} />
								<p style={{ fontSize: '12px', color: 'black' }}>Penduduk</p>
							</div>
						</Link>
					</div>
					<div className="card col-sm-1 p-0 mr-2 mb-0">
						<a
							href="javascript:void"
							onClick={() => {
								this.bukaMenu('administrasi');
							}}
						>
							<div className="card-body p-1 text-center text-middle">
								<i className="fa fa-envelope-o" style={{ fontSize: '60px', color: '#2e99b0' }} />
								<p style={{ fontSize: '12px', color: 'black' }}>Administrasi</p>
							</div>
						</a>
					</div>
					<div className="card col-sm-1 p-0 mr-2 mb-0">
						<Link to={process.env.PUBLIC_URL + '/admin/data-potensi'}>
							<div className="card-body p-1 text-center text-middle">
								<i className="fa fa-bar-chart-o" style={{ fontSize: '60px', color: '#2e99b0' }} />
								<p style={{ fontSize: '12px', color: 'black' }}>Data Potensi Masyarakat</p>
							</div>
						</Link>
					</div>
					<div className="card col-sm-1 p-0 mr-2 mb-0">
						<Link to={process.env.PUBLIC_URL + '/admin/catatan'}>
							<div className="card-body p-1 text-center text-middle">
								<i className="fa fa-edit" style={{ fontSize: '60px', color: '#2e99b0' }} />
								<p style={{ fontSize: '12px', color: 'black' }}>Catatan</p>
							</div>
						</Link>
					</div>
					<div className="card col-sm-1 p-0 mr-2 mb-0">
						<Link to={process.env.PUBLIC_URL + '/admin/berita'}>
							<div className="card-body p-1 text-center text-middle">
								<i
									className="icofont icofont-newspaper"
									style={{ fontSize: '60px', color: '#2e99b0' }}
								/>
								<p style={{ fontSize: '12px', color: 'black' }}>Berita/Pengumuman</p>
							</div>
						</Link>
					</div>
					<div className="card col-sm-1 p-0 mr-2 mb-0">
						{/* <a
							href="javascript:void"
							onClick={() => {
								this.bukaMenu('laporan');
							}}
						>
							<div className="card-body p-1 text-center text-middle">
								<i className="fa fa-files-o" style={{ fontSize: '60px', color: '#2e99b0' }} />
								<p style={{ fontSize: '12px', color: 'black' }}>Laporan</p>
							</div>
						</a> */}
						<Link to={process.env.PUBLIC_URL + '/admin/laporan'}>
							<div className="card-body p-1 text-center text-middle">
								<i className="fa fa-files-o" style={{ fontSize: '60px', color: '#2e99b0' }} />
								<p style={{ fontSize: '12px', color: 'black' }}>Laporan</p>
							</div>
						</Link>
					</div>
					<div className="card col-sm-1 p-0 mr-2 mb-0">
						<Link to={process.env.PUBLIC_URL + '/admin/laporan-potensi'}>
							<div className="card-body p-1 text-center text-middle">
								<i className="fa fa-files-o" style={{ fontSize: '60px', color: '#2e99b0' }} />
								<p style={{ fontSize: '12px', color: 'black' }}>Laporan Potensi Masyarakat</p>
							</div>
						</Link>
					</div>
					<div className="card col-sm-1 p-0 mr-2 mb-0">
						<Link to={process.env.PUBLIC_URL + '/admin/banner'}>
							<div className="card-body p-1 text-center text-middle">
								<i className="fa fa-image" style={{ fontSize: '60px', color: '#2e99b0' }} />
								<p style={{ fontSize: '12px', color: 'black' }}>Banner</p>
							</div>
						</Link>
					</div>
					<div className="card col-sm-1 p-0 mr-2 mb-0">
						<Link to={process.env.PUBLIC_URL + '/admin/perangkat'}>
							<div className="card-body p-1 text-center text-middle">
								<i
									className="fa fa-building"
									style={{ fontSize: '60px', color: '#2e99b0' }}
								/>
								{/* <p style={{ fontSize: '12px', color: 'black' }}>Perangkat {JENIS_DESA}</p> */}
								<p style={{ fontSize: '12px', color: 'black' }}>Perangkat </p>
							</div>
						</Link>
					</div>
					{/* <div className="card col-sm-1 p-0 mr-2 mb-0">
						<Link to={process.env.PUBLIC_URL + '/admin/data-master-potensi'}>
							<div className="card-body p-1 text-center text-middle">
								<i className="fa fa-bar-chart-o" style={{ fontSize: '60px', color: '#2e99b0' }} />
								<p style={{ fontSize: '12px', color: 'black' }}>Data Master Potensi Desa</p>
							</div>
						</Link>
					</div> */}
					{/* <div className="card col-sm-1 p-0 mr-2 mb-0">
						<Link to={process.env.PUBLIC_URL + '/admin/wisata'}>
							<div className="card-body p-1 text-center text-middle">
								<i className="fa fa-image" style={{ fontSize: '60px', color: '#2e99b0' }} />
								<p style={{ fontSize: '12px', color: 'black' }}>Wisata</p>
							</div>
						</Link>
					</div> */}

					{/* <div className="card col-sm-1 p-0 mr-2 mb-0">
						<a
							href="javascript:void"
							onClick={() => {
								this.bukaMenu('wilayah');
							}}
						>
							<div className="card-body p-1 text-center text-middle">
								<i className="fa fa-globe" style={{ fontSize: '60px', color: '#2e99b0' }} />
								<p style={{ fontSize: '12px', color: 'black' }}>Wilayah</p>
							</div>
						</a>
					</div> */}
				</div>
			);
		};

		let title = '';
		if (this.props.location.pathname == '/admin/dashboard') {
			title = 'Dashboard';
		} else if (this.props.location.pathname == '/admin/role') {
			title = 'Role';
		} else if (this.props.location.pathname == '/admin/pengguna') {
			title = 'Pengguna';
		} else if (this.props.location.pathname == '/admin/master-pelayanan') {
			title = 'Master Pelayanan';
		} else if (this.props.location.pathname == '/admin/pelayanan-instansi') {
			title = 'Pelayanan Instansi';
		} else if (this.props.location.pathname == '/admin/instansi') {
			title = 'Instansi';
		} else if (this.props.location.pathname == '/admin/instansiDetail') {
			title = 'Detail Instansi';
		} else if (this.props.location.pathname == '/admin/detailInstansi/pelayanan') {
			title = 'Pelayanan';
		} else if (this.props.location.pathname == '/admin/perangkat') {
			title = 'Perangkat';
		} else if (this.props.location.pathname == '/admin/perangkat-by-instansi') {
			title = 'Perangkat Instansi';
		} else if (this.props.location.pathname == '/admin/konfigurasi-wa') {
			title = 'Konfigurasi Whatsapp';
		} else if (this.props.location.pathname == '/akta-kelahiran') {
			title = 'Penerbitan Akta Kelahiran';
		} else if (this.props.location.pathname == '/pindah-datang') {
			title = 'Penerbitan Pindah / Datang Keluar Daerah';
		} else if (this.props.location.pathname == '/akta-kematian') {
			title = 'Penerbitan Akta Kematian';
		} else if (this.props.location.pathname == '/akta-perkawinan') {
			title = 'Penerbitan Akta Perkawinan';
		} else if (this.props.location.pathname == '/akta-perceraian') {
			title = 'Penerbitan Akta Perceraian';
		} else if (this.props.location.pathname == '/kartu-keluarga') {
			title = 'Penerbitan Kartu Keluarga';
		} else if (this.props.location.pathname == '/kartu-tanda-penduduk') {
			title = 'Penerbitan Kartu Tanda Penduduk';
		} else if (this.props.location.pathname == '/kartu-identitas-anak') {
			title = 'Penerbitan Kartu Identitas Anak (KIA)';
		} else if (this.props.location.pathname == '/pendaftaran-pernikahan') {
			title = 'Pedaftaran Pernikahan';
		} else if (this.props.location.pathname == '/rekomendasi-usaha') {
			title = 'Rekomendasi Izin Usaha';
		} else if (this.props.location.pathname == '/coming_soon') {
			title = 'Coming Soon';
		} else if (this.props.location.pathname == '/izin-usaha-mikro') {
			title = 'Izin Usaha Mikro';
		} else if (this.props.location.pathname == '/rekomendasi-izin-usaha') {
			title = 'Rekomendasi Izin Usaha';
		}
		console.log(this.props.location);

		const dataUser = jwt.decode(sessionStorage.getItem('access_token'));
		const jenisUser = dataUser.sub.jenis;

		return (
			<div>
			  <div className="page-wrapper box-layout" style={{ overflow: "auto" }}>
				<HeaderBaru />
				<div className="page-body-wrapper">
				  {/* {this.state.akun.sub.jenis && this.state.akun.sub.jenis == "Capil" || this.state.akun.sub.jenis == "KUA" || this.state.akun.sub.jenis == "Kecamatan" ? (
					<SidebarAdmin />
				  ) : (
					<SidebarSuperAdmin />
				  )} */}
				  {localStorage.getItem('role').toLowerCase() === 'admin' ? (
					<SidebarAdmin />
				  ) : (
					<>
					</>
				  )}
				  <div className="bayu-body">
					<div className="container-fluid">
					  {title != "Dashboard" ? (
						<div className="page-header">
						  <div className="row">
							<div className="col-lg-6">
							  <h3>
								{title}
								<small>
									Aplikasi Manajemen OPD
								</small>
							  </h3>
							</div>
							<div className="col-lg-6">
							  <ol className="breadcrumb pull-right">
								<li className="breadcrumb-item">
								  <Link to="/admin/dashboard">
									<i className="fa fa-home" />
								  </Link>
								</li>
								<li className="breadcrumb-item">Dashboard</li>
								{title != "Dashboard" ? (
								  <li className="breadcrumb-item active">{title}</li>
								) : (
								  <div />
								)}
							  </ol>
							</div>
						  </div>
						</div>
					  ) : (
						<div />
					  )}
					</div>
					{this.props.children}
				  </div>
				</div>
			  </div>
			</div>
		  );
	}
}

export default App;
