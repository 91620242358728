import React, { Component } from "react";

// import Custom Componenets
import BootstrapTable from "react-bootstrap-table-next";
// import paginationFactory from 'react-bootstrap-table2-paginator';

import Modal from "react-responsive-modal";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  ButtonGroup,
  CustomInput,
  Row,
  Col
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import Dropzone from "react-dropzone";
import Resizer from "react-image-file-resizer";

// koneksi
import { PostStatus, Post, Get, Delete, cekLogin } from "../../function/Koneksi";

// css files
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import DatePicker from "reactdatepicker";

//json file
// var data = require('../assets/json/company');
const frmDef = {
  uuid: '',
  link_kop: '',
  kabupaten_kop: 'Pemerintahan Kota Pekanbaru',
  kecamatan_kop: 'Kecamatan Sukajadi',
  alamat_kop: 'Jln........',
  kode_pos_kop: 'Kode Pos : 212123',
  jenis_kabkota: '',
  kabupaten: '',
  ibu_kota_kabupaten: '',
  kecamatan: '',
	nomor_wa_perangkat: [{isi:[""]}],
	status: '',
}

class Perangkat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: {
        form: false,
        btnForm: false,
        btnAksi: false,
      },
      dt: frmDef,
      alert: null,
      show: false,
      basicTitle: "",
      basicType: "default",
      pesanAlert: "",
      // =========== Select 2 ============
      foto_info: "",
      link_gambar: "",
			konfig_umum: "none",
      data_cek: '',
      get_all_nomor: null,
      dataInstansi: this.props.dataParentToChild,
    };
  }


  // ================== Sweet Alert ==================
  closeAlert = () => {
    this.setState({
      show: false
    });
  };

  onRecieveInput = value => {
    this.setState({
      alert: (
        <SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
          You wrote: {value}
        </SweetAlert>
      )
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  // =================================================


  // ========================================================================

  // Proses Upload Kop Surat
  processGbr = d => {
    if (d.length === 1) {
      let type = d[0].type.split("/");
      if (
        type[1] === "jpg" ||
        type[1] === "jpeg" ||
        type[1] === "png" ||
        type[1] === "bmp" ||
        type[1] === "tiff" ||
        type[1] === "webp"
      ) {
        let bodyFormData = new FormData();
        bodyFormData.append("files", d[0]);

        Post("upload-file/foto-kop", null, bodyFormData, data => {
          console.log('respon upload gambar')
          console.log(data)
          Resizer.imageFileResizer(
            d[0],
            300,
            300,
            type[1],
            100,
            0,
            uri => {
              this.setState({
                foto_info: uri,
                link_gambar: data.data.results
              });
            },
            "base64"
          );
        });

      } else {
        this.setState({
          foto_info: (
            <div className="text-danger font-size-10">
              Type file tidak valid
            </div>
          )
        });
      }
    } else {
      this.setState({
        foto_info: (
          <div className="text-danger font-size-10">
            Silahkan masukan satu gambar
          </div>
        )
      });
    }
  };


  // ================== simpan data ==================
  simpan = e => {

      if (this.state.link_gambar) {
          let get_nomor_wa = JSON.stringify(this.state.dt.nomor_wa_perangkat);
          let pch = get_nomor_wa.split(':');
          let pch2 = pch[1].split('}');
          let nomor_wa = pch2[0];
          let set = JSON.parse(nomor_wa)

          console.log("Data Nomor Perangkat Simpan")
          console.log(get_nomor_wa)
          console.log(set)

          let add = {
            uuid: this.state.dt.uuid,
            id_instansi: this.state.dataInstansi.uuid,
            link_kop: this.state.link_gambar,
            kabupaten_kop: 'Pemerintahan Kota '+ document.getElementById("kabupaten").value,
            kecamatan_kop: 'Kecamatan ' + document.getElementById("kecamatan").value,
            alamat_kop: document.getElementById("alamat_kop").value,
            kode_pos_kop: document.getElementById("kode_pos_kop").value,
            jenis_kabkota: document.getElementById("jenis_kabkota").value,
            kabupaten: document.getElementById("kabupaten").value,
            ibu_kota_kabupaten: document.getElementById("ibu_kota_kabupaten").value,
            kecamatan: document.getElementById("kecamatan").value,
            nomor_wa_perangkat: JSON.stringify(set),
          };
          
          console.log("Data Simpan")
          console.log(add)
          let psn = "";
          let resstat = 204;
          let metode = "create";

          if (add.uuid == "") {
            psn = "Tambah";
            resstat = 201;
            add.uuid = null;
            add.status='0';
          } else {
            psn = "Ubah";
            resstat = 200;
            metode = "update";
            add.status = this.state.dt.status.value
          }
          
          Post("konfigurasi-instansi/" + metode, add.uuid, add, res => {
            console.log('respon simpan');
            console.log(res);
            if (res.status === resstat) {
              this.setState({
                show: true,
                basicType: "success",
                basicTitle: "Data Konfigurasi Cetak Instansi",
                pesanAlert: "Berhasil " + psn + " Data"
              });
              this.state.status.form = false;
              this.state.status.btnForm = false;
              this.tutupForm();
            } else {
              this.setState({
                show: true,
                basicType: "danger",
                basicTitle: "Data Konfigurasi Cetak Instansi",
                pesanAlert: "Gagal " + psn + " Data"
              });
            }
            this.componentDidMount();
            this.forceUpdate();
          });
      }
      else{
        this.setState({
          show: true,
          basicType: "danger",
          basicTitle: "Data Konfigurasi Cetak",
          pesanAlert: "Data Konfigurasi Cetak Tidak Boleh Kosong"
        });
      }
  };
  // ========================================================================


  tutupForm = () => {
    this.setState({
      // dt:frmDef,
      foto_info: "",
      link_gambar: "",
			konfig_umum: "none",
    })
    this.forceUpdate();
    this.componentDidMount();
  };

  tombolAksi = (cell, row) => {
    return (
      <>
        <Button
          size="xs"
          color="success"
          className="btn-icon"
          onClick={() => {
            this.setState({
              judul: "Ubah Perangkat",
              statUbah: false
            });
            this.ubahData(row);
          }}
          disabled={this.state.status.btnAksi}
        >
          <i className="fa fa-pencil"></i>
        </Button>{" "}
        <Button 
          size="xs" 
          color="danger" 
          className="btn-icon" 
          onClick={() => this.konfirmHapus(row.uuid)} disabled={this.state.status.btnAksi}
        >
          <i className="fa fa-trash"></i>
        </Button>
      </>
    );
  };

  
	changeSelectValue = (e, sel) => {
		if (e) {
			switch (sel) {
				case 'instansi':
					this.state.statInstansi = e;
					break;
				case 'status':
					this.state.dt.status = e;
					break;
			}
		} else {
			switch (sel) {
				case 'instansi':
					this.state.statInstansi = null;
					break;
				case 'status':
					this.state.dt.status = null;
					break;
			}
		}
		this.state.status.select = false;
		this.forceUpdate();
	};

  componentDidMount() {
    
    Get("konfigurasi-instansi/instansi/"+this.state.dataInstansi.uuid, null, data => {
      console.log("Konfig Instansi")
      console.log(data)

      if(data.results){
        let nomor_wa_perangkat = JSON.parse(data.results.nomor_wa_perangkat)
        this.setState({
          dt:data.results,
          data_cek: "Tidak Kosong",
          get_all_nomor: nomor_wa_perangkat,
        })
      }
      else{
        this.setState({
          data_cek: "Kosong",
        })
      }
    });

  }

  ubahKonfig = (e) => {
		e.preventDefault();
    if(this.state.dt.kecamatan){
      let nomor_wa_perangkat = [
        { 
          isi:[""]
        }
      ]

      if (this.state.dt.nomor_wa_perangkat != '') {
        nomor_wa_perangkat = JSON.parse(this.state.dt.nomor_wa_perangkat)
        console.log("BERAPA")
        console.log(nomor_wa_perangkat)
      }

      this.setState({
        link_gambar:this.state.dt.link_kop,
        dt:{
          ...this.state.dt,
          status: {value: this.state.dt.status, label: this.state.dt.status==0?'Aktif':'Tidak Aktif'},
          nomor_wa_perangkat: [
            { 
              isi:nomor_wa_perangkat
            }
          ],
        }
      })

    }
		this.setState({ konfig_umum: 'inherit'});
	};

  inputChange = (event) => {
		console.log(event.target.value);
		this.setState({ 
			dt: {...this.state.dt, 
				nomor_wa_perangkat: {...this.state.dt.nomor_wa_perangkat,
				}
			}
		})
		console.log(this.state.dt);
	}

  render() {
    console.log("Data Render konfig surat")
    console.log(this.state.dt)
    console.log(this.state.get_all_nomor)

    const frmDef = {
      uuid: "",
      username: "",
      password: "",
      nik: "",
      id_role: "",
      status: null,
      no_hp: null,
      email: null
    };
    
    return (
      <div>
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          confirmBtnBsStyle="success"
          onConfirm={this.closeAlert}
        >
          {this.state.pesanAlert}
        </SweetAlert>

        {this.state.alert}

        <div className="row">
          <div className="card-body datatable-react">
            <div className="col-12">
              {
                this.state.konfig_umum === 'none'
                ?
                  this.state.data_cek === "Kosong"
                  ?
                  <>
                    <div className="col-md-12 text-center">
                      <br/><br/><br/>
                      <h4>Konfigurasi Cetak Belum Diset</h4>
                      <br/>
                      <Button  size="md" color="info" className="btn-icon" onClick={this.ubahKonfig}><i className="fa fa-wrench"></i> Konfigurasi Sekarang</Button> 
                    </div>
                    <br/> <br/> <br/>
                  </>
                  :
                  <>
                  <div className="col-md-12 text-right">
                    <Button  size="md" color="info" className="btn-icon btn-warning" onClick={this.ubahKonfig}><i className="fa fa-pencil"></i> Ubah Konfigurasi</Button> 
                  </div>
                  <br/>
                  <div className="col-12">
                    <div className="row">
                      {/* ===================================== */}
                        <div className="col-4">
                          <div className="col-md-12">
                            <b>Jenis Kabupaten</b>
                          </div>
                          <div className="col-md-12">
                            {this.state.dt.jenis_kabkota}
                          </div>

                          <div className="col-md-12">
                            <b>&nbsp;</b>
                          </div>
                          <div className="col-md-12">
                            <b>Ibu Kota Kabupaten/Kota</b>
                          </div>
                          <div className="col-md-12">
                            {this.state.dt.ibu_kota_kabupaten}
                          </div>

                          <div className="col-md-12">
                            <b>&nbsp;</b>
                          </div>

                          <div className="col-md-12">
                            <b>Nama {this.state.dt.jenis_kabkota}</b>
                          </div>
                          <div className="col-md-12">
                            {this.state.dt.kabupaten}
                          </div>

                          <div className="col-md-12">
                            <b>&nbsp;</b>
                          </div>

                          <div className="col-md-12">
                            <b>Nama Kecamatan</b>
                          </div>
                          <div className="col-md-12">
                            {this.state.dt.kecamatan}
                          </div>

                          <div className="col-md-12">
                            <b>&nbsp;</b>
                          </div>
                          <div className="col-md-12">
                            <b>Alamat</b>
                          </div>
                          <div className="col-md-12">
                            {this.state.dt.alamat_kop} {this.state.dt.kode_pos_kop}
                          </div>

                          <div className="col-md-12">
                            <b>&nbsp;</b>
                          </div>
                          <div className="col-md-12">
                            <b>&nbsp;</b>
                          </div>
                        </div>
                        {/* ======================= */}
                        <div className="col-4">
                          <div className="col-md-12" style={{ paddingLeft: '60px' }}>
                            <b>Foto Kop</b>
                          </div>
                          <div className="col-md-12">
                            <img alt="foto_kop" src={this.state.dt.link_kop} style={{ width: '150px' }} />
                          </div>
                        </div>
                        {/* ======================= */}
                        <div className="col-4">
                          <div className="col-md-12">
                            <b>Notifikasi Whatsapp</b>
                          </div>
                          <div className="col-md-12">
                            {this.state.dt.status === 0 ? "Aktif" : "Tidak Aktif"}
                          </div>

                          <div className="col-md-12">
                            <b>&nbsp;</b>
                          </div>
                          <div className="col-md-12">
                            &nbsp;
                          </div>
                        
                          <div className="col-md-12">
                            <b>Nomor Whatsapp</b>
                          </div>
                          <div className="col-md-12">
                            {
                              this.state.data_cek === "Tidak Kosong" 
                              ?
                              this.state.get_all_nomor.map((dt, i) => {
                                return (
                                  <tr>
                                    <td style={{ padding: '5px 5px 5px 5px' }}>{i + 1}.</td>
                                    <td style={{ padding: '5px 5px 5px 5px' }}>{dt}</td>
                                  </tr>
                                );
                              })
                              :
                              <></>
                            }
                          </div>
                      </div>
                    </div>
                  </div>
                  </>
                :
                <>
                  <Form className="theme-form" onSubmit={this.simpan}>
                    <div className="col-md-12 text-right">
                        <Button  size="md" color="success" className="btn-icon" onClick={() => this.simpan()}><i className="fa fa-save"></i> Simpan</Button> 
                    </div>
                    <br/> <br/> <br/>
                          <FormGroup className="row">
                            <Label
                              className="col-sm-3 col-form-label"
                              htmlFor="inputEmail3"
                            >
                              Jenis Kabupaten
                            </Label>
                            <Col sm="9">
                              <input
                                className="form-control"
                                id="jenis_kabkota"
                                type="text"
                                placeholder="Contoh: Kota atau Kabupaten"
                                required
                                defaultValue={this.state.dt.jenis_kabkota}
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup className="row">
                            <Label
                              className="col-sm-3 col-form-label"
                              htmlFor="inputEmail3"
                            >
                              Nama Kabupaten/Kota
                            </Label>
                            <Col sm="9">
                              <input
                                className="form-control"
                                id="kabupaten"
                                type="text"
                                placeholder="Masukkan Nama Kabupaten"
                                required
                                defaultValue={this.state.dt.kabupaten}
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup className="row">
                            <Label
                              className="col-sm-3 col-form-label"
                              htmlFor="inputEmail3"
                            >
                              Ibu Kota Kabupaten/Kota
                            </Label>
                            <Col sm="9">
                              <input
                                className="form-control"
                                id="ibu_kota_kabupaten"
                                type="text"
                                placeholder="Masukkan Ibukota Kabupaten/Kota"
                                required
                                defaultValue={this.state.dt.ibu_kota_kabupaten}
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup className="row">
                            <Label
                              className="col-sm-3 col-form-label"
                              htmlFor="inputEmail3"
                            >
                              Nama Kecamatan
                            </Label>
                            <Col sm="9">
                              <input
                                className="form-control"
                                id="kecamatan"
                                type="text"
                                placeholder="Masukkan Nama Kecamatan"
                                required
                                defaultValue={this.state.dt.kecamatan}
                              />
                            </Col>
                          </FormGroup>

                          <FormGroup className="row">
                            <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
                              Alamat
                            </Label>
                            <Col sm="9">
                            <input
                                className="form-control"
                                id="alamat_kop"
                                type="text"
                                placeholder="Masukkan Alamat"
                                required
                                defaultValue={this.state.dt.alamat_kop}
                              />
                            </Col>
                          </FormGroup>

                          <FormGroup className="row">
                            <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
                              Kode Pos
                            </Label>
                            <Col sm="9">
                            <input
                                className="form-control"
                                id="kode_pos_kop"
                                type="text"
                                placeholder="Masukkan Kode Pos"
                                required
                                defaultValue={this.state.dt.kode_pos_kop}
                              />
                            </Col>
                          </FormGroup>

                          <FormGroup className="row">
                            <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
                              Logo Kop
                            </Label>
                            <Col sm="9">
                              <Dropzone
                                onDrop={acceptedFiles => this.processGbr(acceptedFiles)}
                                maxFiles={1}
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <section>
                                    <div
                                      {...getRootProps()}
                                      style={{
                                        minHeight: "100px",
                                        paddingTop: "40px",
                                        padding: "5px 5px 5px 5px",
                                        border: "1px solid #d2d2d2"
                                      }}
                                    >
                                      <input {...getInputProps()} />
                                      <div
                                        style={{
                                          justifyContent: "center",
                                          alignItems: "center"
                                        }}
                                      >
                                        <p className="text-center">
                                          {this.state.link_gambar ? (
                                            <img
                                              src={this.state.link_gambar}
                                              alt=""
                                              style={{
                                                maxHeight: "150px",
                                                maxWidth: "150px"
                                              }}
                                            />
                                          ) : (
                                            <Label
                                              className="col-form-label text-center"
                                              htmlFor="inputEmail3"
                                            >
                                              Drag 'n' drop some files here, or click to
                                              select files
                                            </Label>
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                  </section>
                                )}
                              </Dropzone>
                            </Col>
                          </FormGroup>
                          {this.state.dt.nomor_wa_perangkat.length > 0 ? (
                            <>
                              <FormGroup className="row" style={{ marginBottom: '5px' }}>
                                <Label className="col-sm-6 col-form-label" htmlFor="inputEmail3">
                                  <b>Nomor HP Perangkat</b>
                                </Label>
                              </FormGroup>
                              {this.state.dt.nomor_wa_perangkat.map((dti, i) => {
                                return (
                                  <div style={{ border: '1px solid black', borderRadius: '10px', padding: '10px', marginBottom: '15px' }}>
                                    {/* Tombol hapus petunjuk */}
                                    
                                    {dti.isi.map((dt_isi, j) => {
                                      return (
                                        <>
                                          <FormGroup className="row" style={{ marginBottom: '10px' }}>
                                            <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
                                              Nomor HP {j+1}
                                            </Label>
                                            <Col sm="8">
                                              <input
                                                className="form-control"
                                                type="text"
                                                placeholder="Contoh: 62823xxxxx"
                                                required
                                                defaultValue={dt_isi}
                                                onChange={(event2) => {
                                                  let isi = [...this.state.dt.nomor_wa_perangkat[i].isi]
                                                  isi[j] = event2.target.value

                                                  let nomor_wa_perangkat = [...this.state.dt.nomor_wa_perangkat]
                                                  nomor_wa_perangkat[i] = {...nomor_wa_perangkat[i], isi}
                  
                                                  this.setState({ 
                                                    dt: {...this.state.dt, 
                                                      nomor_wa_perangkat
                                                    }
                                                  })
                                                }}
                                              />
                                            </Col>
                                            {j > 0 ? (
                                              <Col lg="1">
                                                <button
                                                  className="btn btn-danger"
                                                  title="Hapus detail petunjuk ini"
                                                  style={{ padding: '6px 15px' }}
                                                  onClick={() => {
                                                    let isi = [...this.state.dt.nomor_wa_perangkat[i].isi]
                                                    isi.splice(j, 1)

                                                    let nomor_wa_perangkat = [...this.state.dt.nomor_wa_perangkat]
                                                    nomor_wa_perangkat[i] = {...nomor_wa_perangkat[i], isi}
                    
                                                    this.setState({ 
                                                      dt: {...this.state.dt, 
                                                        nomor_wa_perangkat
                                                      }
                                                    })
                                                  }}
                                                >-</button>
                                              </Col>
                                            ) : ''}
                                          </FormGroup>
                                        </>
                                      )
                                    })}
                                    <div className="row">
                                      <div className="col-sm-12">
                                        <button
                                          type="button"
                                          className="btn btn-info"
                                          // disabled={this.state.status.btnForm}
                                          onClick={() => {
                                            let isi = [...this.state.dt.nomor_wa_perangkat[i].isi]
                                            isi.push('')
                                            let nomor_wa_perangkat = [...this.state.dt.nomor_wa_perangkat]
                                            nomor_wa_perangkat[i].isi = isi
                                            this.setState({ 
                                              dt: {...this.state.dt,
                                                nomor_wa_perangkat
                                              }
                                            })
                                          }}
                                        >
                                          Tambah No HP Perangkat
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                )
                              })}
                            </>
                          ) : ''}

                  </Form>
                </>
              }
            </div>
          </div>
        </div>
        <br />
      </div>
    );
  }
}

export default Perangkat;
