import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { ScrollContext } from "react-router-scroll-4";
import "./index.scss";

// ** Import custom components **
import store from "./store";
import App from "./components/app";
// import ChartWidget from './components/widget/chart-widget';

// Custom
// import Operator from './feature/operator';
// import Role from './feature/role';
// import Pengguna from './feature/pengguna';
import Login from "./feature/login";
// import Register from './feature/register';
// import ForgetPassword from './feature/forget-password';
// import ResetPassword from './feature/reset-password';
// import Profile from './feature/profile';
// import PengaturanAplikasi from './feature/pengaturan-aplikasi';

// // // Dashboards
import Dashboard from "./feature/dashboard";
import Default from "./feature/dashboard";
import AktaKelahiran from "./feature/disdukcapil/akta-kelahiran";
import AktaKematian from "./feature/disdukcapil/akta-kematian";
import AktaPerceraian from "./feature/disdukcapil/akta-perceraian";
import AktaPerkawinan from "./feature/disdukcapil/akta-perkawinan";
import KartuIdentitasAnak from "./feature/disdukcapil/kartu-identitas-anak";
import KartuKeluarga from "./feature/disdukcapil/kartu-keluarga";
import KartuTandaPenduduk from "./feature/disdukcapil/kartu-tanda-penduduk";
import PindahDatang from "./feature/disdukcapil/pindah-datang";
import Pengguna from "./feature/pengguna";
import PendaftaranPernikahan from "./feature/kua/pendaftaran-pernikahan";
import Instansi from "./feature/instansi";
import InstansiDetail from "./feature/instansiDetail";
import Perangkat from "./feature/perangkat";
import PerangkatbyInstansi from "./feature/perangkat-by-instansi";
import KonfigurasiWA from "./feature/konfigurasi-wa";
import MasterPelayanan from "./feature/master-pelayanan";
import PelayananInstansi from "./feature/pelayanan-instansi";

// Layanan Kecamatan
import RekomendasiUsaha from "./feature/kecamatan/rekomendasi-usaha";
// Coming Soon
import ComingSoonLayanan from './feature/coming_soon';
import IzinUsahaMikro from "./feature/kecamatan/izin-usaha-mikro";
import RekomendasiIzinUsaha from "./feature/kecamatan/rekomendasi-izin-usaha";

// Surat
// import Suratpindahnikah from './feature/suratpindahnikah';

// import { DataToken } from './function/Format';

// private route n public route
const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (sessionStorage.getItem("access_token")) {
        // let token = DataToken(sessionStorage.getItem('access_token'));
        // if (token.sub.data_role.nama_role === 'Admin KiosK') {
        // 	return (
        // 		<Redirect
        // 			to={{
        // 				pathname: '/beranda',
        // 				state: { from: props.location }
        // 			}}
        // 		/>
        // 	);
        // } else {
        // return <Component {...props} />;
        // }
        return <Component {...props} />;
      } else {
        return (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        );
      }
    }}
  />
);

// Route untuk public, tidak perlu login bisa akses route ini
const PublicRoute = ({ component: Component, ...rest }) => (
	<Route
		{...rest}
		render={(props) => {
			if (sessionStorage.getItem('access_token')) {
					return (
						<Redirect
							to={{
								pathname: '/admin/dashboard',
								state: { from: props.location }
							}}
						/>
					);
			} else {
				return <Component {...props} />;
			}
		}}
	/>
);

class Root extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <BrowserRouter basename={"/"}>
          <ScrollContext>
            <Switch>
              <PublicRoute
                exact
                path={`${process.env.PUBLIC_URL}/`}
                component={Login}
              />

              <PublicRoute
                path={`${process.env.PUBLIC_URL}/login`}
                component={Login}
              />

              <App>
                {/* {
									<PrivateRoute
										path={`${process.env.PUBLIC_URL}/dashboard/default`}
										component={Default}
									/>
								} */}

                <PrivateRoute
                  path={`${process.env.PUBLIC_URL}/admin/dashboard`}
                  component={Dashboard}
                />
                <PrivateRoute
                  path={`${process.env.PUBLIC_URL}/admin/pengguna`}
                  component={Pengguna}
                />
                <PrivateRoute
                  path={`${process.env.PUBLIC_URL}/admin/instansi`}
                  component={Instansi}
                />
                <PrivateRoute
                  path={`${process.env.PUBLIC_URL}/admin/instansiDetail`}
                  component={InstansiDetail}
                />
                <PrivateRoute
                  path={`${process.env.PUBLIC_URL}/admin/perangkat`}
                  component={Perangkat}
                />
                <PrivateRoute
                  path={`${process.env.PUBLIC_URL}/admin/perangkat-by-instansi`}
                  component={PerangkatbyInstansi}
                />
                <PrivateRoute
                  path={`${process.env.PUBLIC_URL}/admin/konfigurasi-wa`}
                  component={KonfigurasiWA}
                />
                <PrivateRoute
                  path={`${process.env.PUBLIC_URL}/admin/master-pelayanan`}
                  component={MasterPelayanan}
                />
                <PrivateRoute
                  path={`${process.env.PUBLIC_URL}/admin/pelayanan-instansi`}
                  component={PelayananInstansi}
                />
                {/* <PublicRoute
									exact
									path={`${process.env.PUBLIC_URL}/`}
									component={Dashboard}
								/> */}
                <PrivateRoute
                  path={`${process.env.PUBLIC_URL}/akta-kelahiran`}
                  component={AktaKelahiran}
                />
                <PrivateRoute
                  path={`${process.env.PUBLIC_URL}/akta-kematian`}
                  component={AktaKematian}
                />
                <PrivateRoute
                  path={`${process.env.PUBLIC_URL}/akta-perceraian`}
                  component={AktaPerceraian}
                />
                <PrivateRoute
                  path={`${process.env.PUBLIC_URL}/akta-perkawinan`}
                  component={AktaPerkawinan}
                />
                <PrivateRoute
                  path={`${process.env.PUBLIC_URL}/kartu-identitas-anak`}
                  component={KartuIdentitasAnak}
                />
                <PrivateRoute
                  path={`${process.env.PUBLIC_URL}/kartu-keluarga`}
                  component={KartuKeluarga}
                />
                <PrivateRoute
                  path={`${process.env.PUBLIC_URL}/kartu-tanda-penduduk`}
                  component={KartuTandaPenduduk}
                />
                <PrivateRoute
                  path={`${process.env.PUBLIC_URL}/pindah-datang`}
                  component={PindahDatang}
                />

                {/* Pendaftaran pernikahan */}
                <PrivateRoute
                  path={`${process.env.PUBLIC_URL}/pendaftaran-pernikahan`}
                  component={PendaftaranPernikahan}
                />

                {/* Kecamatan */}
                <PrivateRoute
                  path={`${process.env.PUBLIC_URL}/izin-usaha-mikro`}
                  component={IzinUsahaMikro}
                />
                <PrivateRoute
                  path={`${process.env.PUBLIC_URL}/rekomendasi-izin-usaha`}
                  component={RekomendasiIzinUsaha}
                />

                <PrivateRoute
									path={`${process.env.PUBLIC_URL}/coming_soon`}
									component={ComingSoonLayanan}
								/>
                {/* <PrivateRoute
									path={`${process.env.PUBLIC_URL}/admin/daftar-aplikasi`}
									component={DaftarAplikasi}
								/>
								<PrivateRoute
									path={`${process.env.PUBLIC_URL}/admin/jenis-surat`}
									component={JenisSurat}
								/>
								<PrivateRoute
									path={`${process.env.PUBLIC_URL}/admin/surat-pernyataan-belum-menikah`}
									component={Suratbelumnikah}
								/>

								<SuperRoute
									path={`${process.env.PUBLIC_URL}/admin/alur-disposisi/:idalur`}
									component={AlurDisposisi}
								/>

								<SuperRoute
									path={`${process.env.PUBLIC_URL}/admin/pengaturan-aplikasi`}
									component={PengaturanAplikasi}
								/> */}
              </App>
            </Switch>
          </ScrollContext>
        </BrowserRouter>
      </Provider>
    );
  }
}

ReactDOM.render(<Root />, document.getElementById("root"));
