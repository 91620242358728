import React, { Component } from "react";

import { Link, Redirect } from "react-router-dom";

// koneksi
import { STATUS_LAYANAN_KUA, STATUS_LAYANAN_CAPIL } from "../../../function/Koneksi";

class MenuAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div
        className="mb-2 menuu"
        style={{
          display: "flex",
          justifyContent: "space-around",
          borderRadius: "10px",
          width: "100%"
        }}
      >
        <div className="dropdown-basic">
          <div className="dropdown">
            <Link to={process.env.PUBLIC_URL}>
              <div className="btn-group m-1">
                <button
                  type="button"
                  className="dropbtn btn-primary-custom"
                  style={{ width: "150px", borderRadius: "10px"}}
                >
                  <i className="fa fa-home"></i> Dashboard
                </button>
              </div>
            </Link>
          </div>
          <div className="dropdown">
            <Link to={process.env.PUBLIC_URL + "/admin/perangkat-by-instansi"}>
              <div className="btn-group m-1">
                <button
                  type="button"
                  className="dropbtn btn-primary-custom"
                  style={{ width: "150px", borderRadius: "10px"}}
                >
                  <i className="fa fa-users"></i> Perangkat
                </button>
              </div>
            </Link>
          </div>
          {/* <div className="dropdown">
            <Link to={process.env.PUBLIC_URL + "/admin/konfigurasi-wa"}>
              <div className="btn-group m-1">
                <button
                  type="button"
                  className="dropbtn btn-success"
                  style={{ width: "150px", borderRadius: "10px"}}
                >
                  <i className="fa fa-whatsapp"></i> Konfigurasi WA
                </button>
              </div>
            </Link>
          </div> */}

        </div>
      </div>
    );
  }
}

export default MenuAdmin;
