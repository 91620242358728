import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class KartuIdentitasAnak extends Component {
	render() {
		return (
			<div>
				<div className="container-fluid">
					<div className="row">
						<div className="col-lg-12 p-10 m-0">
							{/* <div className="card col-lg-12 p-10 bg-light">
								<div className="row" style={{ marginBottom: '10px' }}>
									<div className="col-sm-12 mb-0" style={{ color: 'black' }}>
										<h4>Penerbitan Kartu Identitas Anak (KIA)</h4>
									</div>
								</div>
							</div> */}
							<div style={{ color: 'black' }}>
								<h1>Coming Soon</h1>
								<Link
									to="/admin/dashboard"
								>
									Kembali ke dashboard
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default KartuIdentitasAnak;
