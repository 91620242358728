import React, { Component } from "react";

// import Custom Componenets
// import Breadcrumb from "../components/common/breadcrumb.component";
import BootstrapTable from "react-bootstrap-table-next";
// import paginationFactory from 'react-bootstrap-table2-paginator';
// import paginationFactory, {
//   PaginationProvider,
//   PaginationTotalStandalone,
//   PaginationListStandalone
// } from "react-bootstrap-table2-paginator";
// import Datepicker from "react-datepicker";
// import Lightbox from "react-image-lightbox";

import Modal from "react-responsive-modal";
import {
  Button,
  Form,
  FormGroup,
  Label,
  // Input,
  // FormText,
  // ButtonGroup,
  // CustomInput,
  // Row,
  Col
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

// koneksi
import { PostStatus, Post, Get, Delete, cekLogin } from "../../function/Koneksi";

// css files
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import DatePicker from "reactdatepicker";

//json file
// var data = require('../assets/json/company');
const frmDef = {
  uuid: "",
  username: "",
  password: "",
  nik: 0,
  id_role: "",
  status: null,
  no_hp: null,
  email: null
};

class Pengguna extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pagination: [],
      status: {
        form: false,
        btnForm: false,
        btnAksi: false,
        formVerifikasi: false,
        statGet: false,
        formReset:false
      },
      awal: "",
      sebelum: "",
      hal: [],
      setelah: "",
      akhir: "",
      statUbah: false,
      dt: frmDef,
      alert: null,
      show: false,
      basicTitle: "",
      basicType: "default",
      pesanAlert: "",
      // =========== Select 2 ============
      frmPrv: [],
      prvStat: null,
      frmKbkt: [],
      kbktStat: null,
      frmKec: [],
      kecStat: null,
      frmKel: [],
      kelStat: null,
      pendStat: [],
      frmPen: [],
      jpStat: [],
      frmJp: [],
      frmInstansi: [],
      statInstansi:null,
      formPenduduk: "none",
      dataVerifikasi: [],
      photoIndex: 0,
      isOpen: false,
      idVerifikasi: null,
      dataReset:{
        uuid:'',
        username:'',
      },
      dataInstansi: this.props.dataParentToChild
    };
  }

  // ================== DatePicker ==================
  gantiTglMulai = date => {
    this.state.dt.tanggal_mulai = date;
    this.forceUpdate();
  };
  gantiTglSelesai = date => {
    this.state.dt.tanggal_selesai = date;
    this.forceUpdate();
  };
  // ================== Sweet Alert ==================
  closeAlert = () => {
    this.setState({
      show: false
    });
  };

  onRecieveInput = value => {
    this.setState({
      alert: (
        <SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
          You wrote: {value}
        </SweetAlert>
      )
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  // =================================================

  // ================== Ambil data dari db untuk table ==================
  fetch = (params = {}) => {
    let link = "";
    let where = [];
    if (params.where) {
      where = params.where;
    }
    if (params.page) {
      switch (params.page) {
        case ">>":
          link =
            "?page=" +
            Math.ceil(
              this.state.pagination.total / this.state.pagination.per_page
            );
          break;
        case ">":
          link = "?page=" + (parseInt(this.state.pagination.current_page) + 1);
          break;
        case "<":
          link = "?page=" + (parseInt(this.state.pagination.current_page) - 1);
          break;
        case "<<":
          link = "?page=1";
          break;
        default:
          link = "?page=" + params.page;
          break;
      }
    }
    Get("pengguna/instansi/"+ this.state.dataInstansi.uuid + link, null, dtkat => {
      console.log("Data Pengguna")
      console.log(dtkat)
      this.setState({ data: dtkat.results.data, pagination: dtkat.results });
      this.pagination(dtkat.results);
    });
  };

  // Pagination
  pagination = data => {
    // const data = this.state.pagination;
    console.log(data);
    let awal = "";
    let hal = [];
    let sebelum = "";
    let setelah = "";
    let akhir = "";
    if (data.total > 0) {
      let start = 1;
      let end = 5;
      let n = 0;
      let p = 0;
      if (data.current_page <= 3) {
        start = 1;
        end = 5;
        if (data.last_page > data.current_page) {
          n = data.current_page + 1;
        }
      } else {
        p = data.current_page - 1;
        n = data.current_page + 1;
        start = data.current_page - 2;
        end = data.current_page + 2;
      }
      if (end >= data.last_page - 2) {
        p = data.current_page - 1;
        if (start >= 5) {
          start = data.last_page - 4;
        }
        end = data.last_page;
      }

      for (let i = start; i <= end; i++) {
        let warna = "success";
        if (i == data.current_page) {
          warna = "danger";
        }
        hal.push(
          <a
            onClick={() => this.fetch({ page: i })}
            className={
              "btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
            }
          >
            {i}
          </a>
        );
      }
      if (p > 0) {
        sebelum = (
          <a
            onClick={() => this.fetch({ page: p })}
            className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {"<"}
          </a>
        );
      }
      if (n > 0) {
        setelah = (
          <a
            onClick={() => this.fetch({ page: n })}
            className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {">"}
          </a>
        );
      }
      awal = (
        <a
          onClick={() => this.fetch({ page: 1 })}
          className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {"<<"}
        </a>
      );
      akhir = (
        <a
          onClick={() => this.fetch({ page: data.last_page })}
          className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {">>"}
        </a>
      );
    }
    this.setState({ awal, sebelum, hal, setelah, akhir });
  };
  // Cari Data Table
  cariData = e => {
    if (e.key == "Enter") {
      let addRole = {
        q: e.target.value
      };
      cekLogin("jenis-surat", addRole, data => {
        this.setState({
          data: data.data.results.data,
          pagination: data.data.results
        });
      });
    }
  };

  // ========================================================================

  // ================== simpan data ==================
  simpan = e => {
    e.preventDefault();

      // if (this.state.statInstansi) {
        let passnya = document.getElementById("password").value
        let add = {
          uuid: this.state.dt.uuid,
          username: document.getElementById("username").value,
          no_hp: document.getElementById("no_hp").value,
          email: document.getElementById("email").value,
          nama_lengkap: document.getElementById("nama_lengkap").value,
          nip: document.getElementById("nip").value,
          id_instansi: this.state.dataInstansi.uuid,
          status: 'Aktif',
        };
          
        console.log("Data Simpan")
        console.log(add)
        let psn = "";
        let resstat = 204;
        let metode = "create";
        if (add.uuid == "") {
          psn = "Tambah";
          resstat = 201;
          add.uuid = null;
          add.password = passnya
        } else {
          psn = "Ubah";
          resstat = 200;
          metode = "update";
            if(passnya){
              add.password = passnya
            }
        }
        
        PostStatus("pengguna/" + metode, add.uuid, add, res => {
          console.log(res);
          if (res === resstat) {
            this.setState({
              show: true,
              basicType: "success",
              basicTitle: "Data Pengguna",
              pesanAlert: "Berhasil " + psn + " Data"
            });
            
            this.tutupForm();
          } else {
            this.setState({
              show: true,
              basicType: "danger",
              basicTitle: "Data Pengguna",
              pesanAlert: "Gagal " + psn + " Data"
            });
          }
          this.componentDidMount();
          this.forceUpdate();
        });
      // }
      // else{
      //   this.setState({
      //     show: true,
      //     basicType: "danger",
      //     basicTitle: "Data Pengguna",
      //     pesanAlert: "Data Pengguna Tidak Boleh Kosong"
      //   });
      // }
  };
  // ========================================================================

  
   // ================== simpan data ==================
   simpanReset = id => {
    console.log(id);
    if(id){
      Get("pengguna/reset-password/" + id,null, res => {
        this.forceUpdate();
        console.log(res);
        if (res ) {
          this.setState({
            show: true,
            basicType: "success",
            basicTitle: "Data Pengguna",
            pesanAlert: "Berhasil reset Data"
          });
        } else {
          this.setState({
            show: true,
            basicType: "danger",
            basicTitle: "Data Pengguna",
            pesanAlert: "Gagal reset Data"
          });
        }
        this.tutupForm();

        this.componentDidMount();
      });
    }
    else {
      this.setState({
        show: true,
        basicType: "danger",
        basicTitle: "Data Pengguna",
        pesanAlert: "Gagal reset Data"
      });
    }
  
    
  };
  // =
  // ============================== Ubah Data ===============================

  ubahData = data => {
    console.log("IDNYA")
    console.log(data)
    this.forceUpdate();

      console.log(data);
      this.state.dt.uuid = data.uuid;
      this.state.dt.email = data.email;
      this.state.dt.nama_lengkap = data.nama_lengkap;
      this.state.dt.nip = data.nip;
      this.state.dt.no_hp = data.no_hp;
      this.state.dt.role = data.role;
      this.state.dt.status = data.status;
      this.state.dt.username = data.username;
      
      this.state.statInstansi = {value:data.data_instansi.uuid, label: data.data_instansi.nama};

      this.state.status.btnAksi = false;
      this.forceUpdate();
      this.bukaForm();
    // });
  };
  resetPassword = data => {
    console.log(data)
  
    this.setState({
      dataReset:data,
      status:{
        ...this.state.status,
        formReset:true,
      }
    })
  };
  
 
  // ========================================================================
  // ============================= Hapus Data ===============================
  konfirmHapus = id => {
    this.setState({
      alert: (
        <SweetAlert
          showCancel
          confirmBtnText="Hapus"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="success"
          type="danger"
          title="Yakin ingin hapus data ?"
          onCancel={this.hideAlert}
          onConfirm={() => this.hapusData(id)}
        >
          Proses ini tidak dapat dibatalkan!
        </SweetAlert>
      )
    });
  };

  hapusData = id => {
    this.hideAlert();
    let psn = "Hapus";
    Delete("pengguna/delete", id, res => {
      if (res == 200) {
        this.setState({
          show: true,
          basicType: "success",
          basicTitle: "Data Pengguna",
          pesanAlert: "Berhasil " + psn + " Data"
        });
      } else {
        this.setState({
          show: true,
          basicType: "danger",
          basicTitle: "Data Pengguna",
          pesanAlert: "Gagal " + psn + " Data"
        });
      }
      this.componentDidMount();
    });
  };
  // ========================================================================

  bukaForm = () => {
    this.state.status.form = true;
    this.forceUpdate();
  };

  tutupForm = () => {
    
    this.setState({
      status:{
        form: false,
        btnForm: false,
        formReset: false,
        formVerifikasi: false,
      },
      statInstansi:null,
      dt: frmDef,
    })
    this.forceUpdate();
  };

  tombolAksi = (cell, row) => {
    console.log(row)
    return (
      <>
        <Button
          size="xs"
          color="success"
          className="btn-icon"
          onClick={() => {
            this.setState({
              judul: "Ubah Perangkat",
              statUbah: false
            });
            this.ubahData(row);
          }}
          disabled={this.state.status.btnAksi}
        >
          <i className="fa fa-pencil"></i>
        </Button>{" "}
        <Button 
          size="xs" 
          color="danger" 
          className="btn-icon" 
          onClick={() => this.konfirmHapus(row.uuid)} disabled={this.state.status.btnAksi}
        >
          <i className="fa fa-trash"></i>
        </Button>
      </>
    );
  };

	
  changeSelectValue = (e, sel) => {
		if (e) {
			switch (sel) {
				case 'instansi':
					this.state.statInstansi = e;
					break;
				case 'nama':
					this.state.statLayanan = e;
					break;
			}
		} else {
			switch (sel) {
				case 'instansi':
					this.state.statInstansi = null;
					break;
				case 'nama':
					this.state.statLayanan = null;
					break;
			}
		}
		this.state.status.select = false;
		this.forceUpdate();
	};

  componentDidMount() {
    this.fetch({ page: null, where: [] });

    Get("provinsi/list", null, data => {
      let frmPrv = [];
      data.results.map(dt => {
        frmPrv.push({ value: dt.id, label: dt.nama });
      });
      this.setState({ frmPrv });
    });

    Get("instansi/list", null, data => {
      let frmInstansi = [];
      data.results.map(dt => {
        frmInstansi.push({ value: dt.uuid, label: dt.nama });
      });
      this.setState({ frmInstansi });
    });
    // Get("select-enum/pengguna/jenis", null, data => {
    //   console.log("Data Jenis User")
    //   console.log(data)
    //   let jenisUser = [];
    //   data.map(dt => {
    //     jenisUser.push({ value: dt, label: dt });
    //   });
    //   this.setState({ jenisUser });
    // });
    // Get("select-enum/pengguna/tingkat", null, data => {
    //   console.log("Data Tingkatan User")
    //   console.log(data)
    //   let tingkatUser = [];
    //   data.map(dt => {
    //     tingkatUser.push({ value: dt, label: dt });
    //   });
    //   this.setState({ tingkatUser });
    // });
  }

  render() {
    // console.log("Tingkatan User")
    // console.log(this.state.prvStat)
    // console.log(this.state.kbktStat)
    // console.log(this.state.kecStat)
    const { photoIndex, isOpen } = this.state;

    var size = Math.ceil(
      this.state.pagination.total / this.state.pagination.per_page
    );
    // var size = this.state.pagination.total;
    var from = this.state.pagination.from;
    var page = this.state.pagination.current;
    var to = this.state.pagination.to;
    var total = this.state.pagination.total;
    var data = this.state.data;

    // var data = require('../assets/json/company');

    const frmDef = {
      uuid: "",
      username: "",
      password: "",
      nik: "",
      id_role: "",
      status: null,
      no_hp: null,
      email: null
    };
    const { SearchBar } = Search;
    const columns = [
      {
        dataField: "nip",
        text: "NIP",
        sort: true
      },
      {
        dataField: "username",
        text: "Username",
        sort: true
      },
      
      {
        dataField: "role",
        text: "Role",
        sort: true
      },
      {
        dataField: "data_instansi.nama",
        text: "Nama Instansi",
        sort: true
      },
      {
        dataField: "data_instansi.tingkat",
        text: "Tingkat Instansi",
        sort: true
      },
      {
        dataField: "data_instansi",
        text: "Wilayah",
        sort: true,
        formatter: (cell, obj) => {
          let isinya = obj.data_instansi
          console.log("Data aoa ")
          console.log(isinya)
          if(isinya==null){
          } else if(isinya.data_kecamatan){
            return (isinya.data_kecamatan.nama);
          }
          else if(isinya.data_kabkota){
            return (isinya.data_kabkota.nama);
          }
        },
      },
      
      {
        dataField: "aksi",
        text: "Aksi",
        isDummyField: true,
        csvExport: false,
        formatter: this.tombolAksi
      }
    ];

    return (
      <div>
        {/* <Breadcrumb title="Penguna" parent="Admin" /> */}
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          confirmBtnBsStyle="success"
          onConfirm={this.closeAlert}
        >
          {this.state.pesanAlert}
        </SweetAlert>

        {this.state.alert}

        <div className="row">
          <div className="card-body datatable-react">
            <div className="col-12 text-right">
                <Button size="sm" color="success" className="btn-square" onClick={() => {
                    this.setState({ judul: "Tambah Pengguna", dt: frmDef, prvStat: null, kbktStat: null, kecStat: null, kldsStat: null});  
                    this.state.dt.uuid = null
                    this.forceUpdate();
                    this.bukaForm();
                }}>Tambah Data</Button>
            </div>
            <br/>
            <div className="col-12">
              <BootstrapTable keyField="id" data={data} columns={columns} />
              <div className="pull-right text-white">
                {this.state.awal}
                {this.state.sebelum}
                {this.state.hal.map(dt => {
                  return dt;
                })}
                {this.state.setelah}
                {this.state.akhir}
              </div>
            </div>
          </div>
        </div>
        <br />

        <Modal
          size="lg"
          open={this.state.status.formReset}
          onClose={this.tutupForm}
          styles={{ modal: { width: "80%" } }}
          closeOnEsc={false}
          closeOnOverlayClick={false}
        >
          <div size="lg" className="modal-header">
            <h5 className="modal-title">{this.state.judul}</h5>
          </div>
          <Form className="theme-form" >
            <div className="modal-body text-center">
            <p>Anda akan melakukan reset password terhadap {this.state.dataReset.username}?</p>
             
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-warning"
                disabled={this.state.status.btnForm}
                onClick={this.tutupForm}
              >
                Tutup
              </button>
              {" "}
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => {
                      this.simpanReset(this.state.dataReset.uuid)}
                    }
                    disabled={this.state.status.btnForm}
                  >
                    Reset Password
                  </button>
           
            </div>
          </Form>
        </Modal>
        
        <Modal
          size="lg"
          open={this.state.status.form}
          onClose={this.tutupForm}
          styles={{ modal: { width: "80%" } }}
          closeOnEsc={false}
          closeOnOverlayClick={false}
        >
          <div size="lg" className="modal-header">
            <h5 className="modal-title">{this.state.judul}</h5>
          </div>
          <Form className="theme-form" onSubmit={this.simpan}>
            <div className="modal-body">
              <input
                className="form-control"
                id="uuid"
                type="hidden"
                placeholder="UUID"
                defaultValue={this.state.dt.uuid}
              />

              <FormGroup className="row">
                <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
                  Nama Instansi
                </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="nama_instansi"
                    type="text"
                    defaultValue={this.state.dataInstansi.nama}
                    readOnly
                  />  
                </Col>
              </FormGroup>
              
              <FormGroup className="row">
                <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
                  Nama
                </Label>
                <Col sm="9">
                <input
                    className="form-control"
                    id="nama_lengkap"
                    type="text"
                    placeholder="Masukkan Nama Lengkap"
                    required
                    defaultValue={this.state.dt.nama_lengkap}
                  />
                </Col>
              </FormGroup>

              <FormGroup className="row">
                <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
                  NIP
                </Label>
                <Col sm="9">
                <input
                    className="form-control"
                    id="nip"
                    type="text"
                    placeholder="Masukkan NIP"
                    required
                    defaultValue={this.state.dt.nip}
                  />
                </Col>
              </FormGroup>

              <FormGroup className="row">
                <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
                  No. HP
                </Label>
                <Col sm="9">
                <input
                    className="form-control"
                    id="no_hp"
                    type="number"
                    placeholder="Masukkan Nomor Hp"
                    required
                    defaultValue={this.state.dt.no_hp}
                  />
                </Col>
              </FormGroup>

              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Email
                </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="email"
                    type="email"
                    placeholder="Masukkan email"
                    required
                    defaultValue={this.state.dt.email}
                  />
                </Col>
              </FormGroup>

              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Username
                </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="username"
                    type="text"
                    placeholder="masukan username"
                    required
                    defaultValue={this.state.dt.username}
                  />
                </Col>
              </FormGroup>

              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Password{" "}
                  {this.state.dt.uuid
                    ? "( Silahkan di isi jika ubah password )"
                    : ""}
                </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="password"
                    type="password"
                    placeholder="masukan Password"
                    required = {this.state.dt.uuid? false: true}
                    defaultValue={this.state.dt.password}
                    minLength={6}
                  />
                </Col>
              </FormGroup>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-warning"
                // disabled={this.state.status.btnForm}
                onClick={this.tutupForm}
              >
                Tutup
              </button>
              <button
                type="submit"
                className="btn btn-success"
                // disabled={this.state.status.btnForm}
              >
                Simpan
              </button>
            </div>
          </Form>
        </Modal>
      </div>
    );
  }
}

export default Pengguna;