import React, { Component } from "react";
import Modal from "react-responsive-modal";
import BootstrapTable from "react-bootstrap-table-next";
import { Link } from "react-router-dom";
import {
  Button,
  Table,
  CardImg,
  Form,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import { Get, Post } from "../../function/Koneksi";
import classnames from "classnames";

const listFile = {
  pindah_datang: '',
}

class PindahDatang extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      dataDiproses: [],
      dataSelesai: [],
      dataDitolak: [],
      dataDokumenOutput: [],

      detailSurat: null,
      dataPelayanan: null,
      dataPelapor: null,
      fotoPersyaratan: null,
      titlePersyaratan: "",
      linkGambar: "",
      // Tab Pane
			active_tab_icon: "1",
			cek_posisi:'Pertama',
			pagination: [],
			paginationDiproses: [],
			paginationSelesai: [],
			paginationDitolak: [],
      status: {
        alert: false,
        modalValidasi: false,
        modalUpload: false,
        modalDetail: false,
        tolakValidasi: false,
        fotoPersyaratan: false,
        btnForm: false,
        btnAksi: false,
      },
     
      
			// Diajukan
			awal: "",
			sebelum: "",
			hal: [],
			setelah: "",
			akhir: "",
			// Diproses
			awal2: "",
			sebelum2: "",
			hal2: [],
			setelah2: "",
			akhir2: "",
			
			// Selesai
			awal3: "",
			sebelum3: "",
			hal3: [],
			setelah3: "",
			akhir3: "",

			// Ditolak
			awal4: "",
			sebelum4: "",
			hal4: [],
			setelah4: "",
			akhir4: "",
	  
      // dt:frmDef,
      // ============== Select 2 ==============
      frmTingkat: [],
      tingkatStat: [],
      frmDetail: [],
      detailStat: [],
      judul: "Tambah Jabatan Perangkat",
      alert: null,
      show: false,
      basicTitle: "",
      basicType: "default",
      pesanAlert: "",

			loadingPreview: false,
			id_jenis_pelayanan: this.props.location.state.uuid,
    };
  }

  fetch = (params = {}) => {
		let link = "";
		// let where = [];
		// if (params.where) {
		//   where = params.where;
		// }
		if (params.page) {
		  switch (params.page) {
			case ">>":
			  switch (params.tab) {
				case "Diajukan":
				  link =
					"&page=" +
					Math.ceil(
					  this.state.pagination.total / this.state.pagination.per_page
					);
				  break;
	
				case "Diproses":
				  link =
					"&page=" +
					Math.ceil(
					  this.state.paginationDiproses.total /
					  this.state.paginationDiproses.per_page
					);
				  break;
	
				case "Ditolak":
				  link =
					"&page=" +
					Math.ceil(
					  this.state.paginationDitolak.total /
					  this.state.paginationDitolak.per_page
					);
				  break;
	
			  	case "Selesai":
				  link =
					"&page=" +
					Math.ceil(
					  this.state.paginationSelesai.total /
					  this.state.paginationSelesai.per_page
					);
				  break;
				default:
				  // no default
				  break;
			  }
			  break;
			case ">":
			  switch (params.tab) {
				case "Diajukan":
				  link =
					"&page=" + (parseInt(this.state.pagination.current_page) + 1);
				  break;
	
				case "Diproses":
				  link =
					"&page=" +
					(parseInt(this.state.paginationDiproses.current_page) +
					  1);
				  break;
	
				case "Ditolak":
				  link =
					"&page=" +
					(parseInt(this.state.paginationDitolak.current_page) + 1);
				  break;

				case "Selesai":
				link =
				  "&page=" +
				  (parseInt(this.state.paginationSelesai.current_page) + 1);
				break;
				default:
				  // no default
				  break;
	
			  }
			  break;
			case "<":
			  switch (params.tab) {
				case "Diajukan":
				  link =
					"&page=" + (parseInt(this.state.pagination.current_page) - 1);
				  break;
	
				case "Diproses":
				  link =
					"&page=" +
					(parseInt(this.state.paginationDiproses.current_page) -
					  1);
				  break;
	
				case "Ditolak":
				  link =
					"&page=" +
					(parseInt(this.state.paginationDitolak.current_page) - 1);
				  break;
	
				case "Selesai":
				  link =
					"&page=" +
					(parseInt(this.state.paginationSelesai.current_page) - 1);
				  break;
				default:
				  // no default
				  break;
			  }
			  break;
			case "<<":
			  link = "&page=1";
			  break;
			default:
			  link = "&page=" + params.page;
			  break;
		  }
		}
	
		// Get data from db
		if(this.state.cek_posisi === "Pertama"){
		  params.tab = "Diajukan"
		}
		else if(this.state.cek_posisi === "Kedua"){
		  params.tab = "Diproses"
		}
		else if(this.state.cek_posisi === "Ketiga"){
		  params.tab = "Selesai"
		}
		else if(this.state.cek_posisi === "Keempat"){
		  params.tab = "Ditolak"
		}
		
		Get(`pelayanan?uuid_pelayanan_instansi=${this.state.id_jenis_pelayanan}&status=` + params.tab + link, null, (dtkat) => {
		  //console.log("COba tampil bos")
		  //console.log(dtkat)
		  switch (params.tab) {
			case "Diajukan":
			  this.setState({
				data: dtkat.results.data,
				pagination: dtkat.results
			  });
			  break;
	
			case "Diproses":
			  this.setState({
				dataDiproses: dtkat.results.data,
				paginationDiproses: dtkat.results
			  });
			  break;

			case "Ditolak":
			  this.setState({
				dataDitolak: dtkat.results.data,
				paginationDitolak: dtkat.results
			  });
			  break;

			case "Selesai":
			  this.setState({
				dataSelesai: dtkat.results.data,
				paginationSelesai: dtkat.results
			  });
			  break;
			default:
			  // no default
			  break;
		  }
		  // //console.log(dtkat.results);
		  this.pagination(dtkat.results, params.tab);
		});
	};
	
	// Pagination
	pagination = (data, tab) => {
		// const data = this.state.pagination;
		let awal = "";
		let hal = [];
		let sebelum = "";
		let setelah = "";
		let akhir = "";
		if (data.total > 0) {
		  let start = 1;
		  let end = 5;
		  let n = 0;
		  let p = 0;
		  if (data.current_page <= 3) {
			start = 1;
			end = 5;
			if (data.last_page > data.current_page) {
			  n = data.current_page + 1;
			}
		  } else {
			p = data.current_page - 1;
			n = data.current_page + 1;
			start = data.current_page - 2;
			end = data.current_page + 2;
		  }
		  if (end >= data.last_page - 2) {
			p = data.current_page - 1;
			if (start >= 5) {
			  start = data.last_page - 4;
			}
			end = data.last_page;
		  }
	
		  for (let i = start; i <= end; i++) {
			let warna = "success";
			if (i === data.current_page) {
			  warna = "danger";
			}
			hal.push(
			  <a
				href={() => false}
				key={i}
				onClick={() => this.fetch({ page: i, tab: tab })}
				className={
				  "btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
				}
			  >
				{i}
			  </a>
			);
		  }
		  if (p > 0) {
			sebelum = (
			  <a
				href={() => false}
				onClick={() => this.fetch({ page: p, tab: tab })}
				className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
			  >
				{"<"}
			  </a>
			);
		  }
		  if (n > 0) {
			setelah = (
			  <a
				href={() => false}
				onClick={() => this.fetch({ page: n, tab: tab })}
				className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
			  >
				{">"}
			  </a>
			);
		  }
	
		  awal = (
			<a
			  href={() => false}
			  onClick={() => this.fetch({ page: 1, tab: tab })}
			  className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
			>
			  {"<<"}
			</a>
		  );
		  akhir = (
			<a
			  href={() => false}
			  onClick={() => this.fetch({ page: data.last_page, tab: tab })}
			  className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
			>
			  {">>"}
			</a>
		  );
		}
	
		switch (tab) {
		  case "Diajukan":
			this.setState({ awal, sebelum, hal, setelah, akhir });
			break;
		  case "Diproses":
			this.setState({
			  awal2: awal,
			  sebelum2: sebelum,
			  hal2: hal,
			  setelah2: setelah,
			  akhir2: akhir
			});
			break;

		  case "Selesai":
			this.setState({
			  awal3: awal,
			  sebelum3: sebelum,
			  hal3: hal,
			  setelah3: setelah,
			  akhir3: akhir
			});
			break;

		  case "Ditolak":
			this.setState({
			  awal4: awal,
			  sebelum4: sebelum,
			  hal4: hal,
			  setelah4: setelah,
			  akhir4: akhir
			});
			break;
		  default:
			// no default
			break;
		}
	};

  componentDidMount() {
		
		let tabnya = ""
		if(this.state.cek_posisi==="Pertama"){
			tabnya="Diajukan"
			this.setState({
				active_tab_icon: '1',
			})
		}
		else if(this.state.cek_posisi==="Kedua"){
			tabnya="Diproses"
			this.setState({
				active_tab_icon: '2',
			})
		}

		else if(this.state.cek_posisi==="Ketiga"){
			tabnya="Selesai"
			this.setState({
				active_tab_icon: '3',
			})
		}
		
		else if(this.state.cek_posisi==="Keempat"){
			tabnya="Ditolak"
			this.setState({
				active_tab_icon: '4',
			})
		}
		
		this.fetch({ page: null, where: [], tab: tabnya });
	}

  tombolAksi = (cell, value) => {
    
    const btnDetail = (
      <Button
        size="sm"
        color="info"
        className="btn-square"
        onClick={() => {
          let status = { ...this.state.status };
          status.modalDetail = true;
          this.setState({
            status,
            detailSurat: value,
            dataPelayanan: JSON.parse(value.data_pelayanan),
            dataPelapor: JSON.parse(value.data_pelapor),
            dataDokumenOutput: JSON.parse(value.dokumen_output)
          });
          // console.log(JSON.parse(value.data_pelayanan));
          // console.log(JSON.parse(value.data_pelapor));
        }}
        style={{ padding: "0.25rem 0.5rem", borderRadius: "25px" }}
      >
        {/* <i className="fa fa-eye"></i> */}
        Detail
      </Button>
    )

    if (value.data_status.length > 0) {
      if (value.data_status_baru.status == "Diajukan") {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
            }}
          >
            {/* {btnDetail} */}
            <Button
              size="sm"
              color="success"
              className="btn-square"
              onClick={() => {
                // Tampilkan modal validasi
                let status = { ...this.state.status };
                status.modalValidasi = true;
                this.setState({ 
                  status, 
                  detailSurat: value,
									dataPelayanan: JSON.parse(value.data_pelayanan),
									dataPelapor: JSON.parse(value.data_pelapor),
									dataDokumenOutput: JSON.parse(value.dokumen_output)
                });
                console.log(value);
                console.log(JSON.parse(value.data_pelayanan));
                console.log(JSON.parse(value.dokumen_input));
              }}
              style={{ borderRadius: "25px" }}
            >
              <b>Validasi</b>
            </Button>
          </div>
        );
      } else if (value.data_status_baru.status == "Diproses") {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
            }}
          >
            {btnDetail}
            <Button
              size="sm"
              color="success"
              className="btn-square"
              onClick={() => {
                let status = { ...this.state.status };
                status.modalUpload = true;
                this.setState({ 
                  status, 
                  detailSurat: value, 
                  linkGambar:listFile, 
                });
              }}
              style={{ padding: "0.25rem 0.5rem", borderRadius: "25px" }}
            >
              Upload
            </Button>
          </div>
        );
      } else if (value.data_status_baru.status == "Selesai") {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
            }}
          >
            {btnDetail}
          </div>
        );
      } else if (value.data_status_baru.status == "Ditolak") {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
            }}
          >
            {btnDetail}
          </div>
        );
      }
    } else {
      return "";
    }
  };

  tutupForm() {
    let status = { ...this.state.status };
    status.modalValidasi = false;
    status.tolakValidasi = false;
    status.modalUpload = false;
    status.modalDetail = false;
    status.fotoPersyaratan = false;

    this.setState({
      status,
      alert: null,
      detailSurat: null,
      dataPelayanan: null,
      dataPelapor: null,
      dataDokumenOutput: null,
      linkGambar: listFile,
    });
  }

  hideAlert = () => {
    let status = { ...this.state.status };
    status.alert = false;
    this.setState({ status });
  };

  updateSurat = (uuid_surat) => {
    this.setState({
      alert: (
        <SweetAlert
          showCancel
          confirmBtnText="Ya, saya yakin"
          confirmBtnBsStyle="warning"
          confirmBtnStyle={{ fontWeight: 600 }}
          cancelBtnText="Batal"
          cancelBtnBsStyle="default"
          cancelBtnStyle={{ fontWeight: 600 }}
          type="warning"
          title="Apakah anda yakin syarat yang diberikan telah sesuai ?"
          onCancel={() => {
            this.setState({ alert: null });
          }}
          onConfirm={() => {
            if (this.state.detailSurat) {
              this.setState({
								loadingPreview: true,
							})

              let bodyFormData = {
                status: "Diproses",
              };
              Post(
                "pelayanan/verifikasi-status",
                this.state.detailSurat.uuid,
                bodyFormData,
                (data) => {
                  console.log(data);
                  if (data.status == 200) {
                    let status = { ...this.state.status };
                    status.alert = true;
                    this.setState({
                      status,
                      basicType: "success",
                      basicTitle: "Validasi Pengajuan Pelayanan",
                      pesanAlert: "Berhasil validasi data",
                      loadingPreview: false,
                    });
                    this.componentDidMount(this.state.active_tab_icon);
                  } else {
                    let status = { ...this.state.status };
                    status.alert = true;
                    this.setState({
                      status,
                      basicType: "danger",
                      basicTitle: "Validasi Pengajuan Pelayanan",
                      pesanAlert: "Gagal validasi data",
                      loadingPreview: false,
                    });
                  }
                  this.tutupForm();
                }
              );
            }
          }}
        >
          Proses ini tidak dapat dibatalkan, pastikan sudah memeriksa seluruh
          persyaratan dengan teliti
        </SweetAlert>
      ),
    });
  };

  tolakSurat = (uuid_surat) => {
    let alasan = document.getElementById("alasan").value;
    if (alasan == "") {
      let status = { ...this.state.status };
      status.alert = true;

      this.setState({
        status,
        basicType: "warning",
        basicTitle: "Penolakan Pengajuan Layanan",
        pesanAlert: "Isikan alasan penolakan terlebih dahulu",
      });
    } else {
      this.setState({
        alert: (
          <SweetAlert
            showCancel
            confirmBtnText="Ya, saya yakin"
            confirmBtnBsStyle="warning"
            confirmBtnStyle={{ fontWeight: 600 }}
            cancelBtnText="Batal"
            cancelBtnBsStyle="default"
            cancelBtnStyle={{ fontWeight: 600 }}
            type="warning"
            title="Apakah anda yakin ingin menolak pengajuan layanan ini ?"
            onCancel={() => {
              this.setState({ alert: null });
            }}
            onConfirm={() => {
              if (this.state.detailSurat) {
                this.setState({
									loadingPreview: true,
								})

                let bodyFormData = {
                  status: "Ditolak",
                  keterangan: alasan,
                };
                Post(
                  "pelayanan/verifikasi-status",
                  uuid_surat,
                  bodyFormData,
                  (data) => {
                    console.log(data);
                    if (data.status == 200) {
                      let status = { ...this.state.status };
                      status.alert = true;
                      this.setState({
                        status,
                        basicType: "success",
                        basicTitle: "Validasi Pengajuan Pelayanan",
                        pesanAlert: "Berhasil melakukan penolakan pengajuan layanan",							loadingPreview: false,
                      });
                      this.componentDidMount(this.state.active_tab_icon);
                    } else {
                      let status = { ...this.state.status };
                      status.alert = true;
                      this.setState({
                        status,
                        basicType: "danger",
                        basicTitle: "Validasi Pengajuan Pelayanan",
                        pesanAlert: "Gagal melakukan penolakan pengajuan layanan",							loadingPreview: false,
                      });
                    }
                    this.tutupForm();
                  }
                );
              }
            }}
          >
            Proses ini tidak dapat dibatalkan, pastikan anda sudah teliti dalam
            melakukan proses penolakan pengajuan
          </SweetAlert>
        ),
      });
      // this.tutupForm()
    }
  };

  onFileChange = (event, attribute) => {
    const file = event.target.files[0];
    const fileType = file.type.split('/')
    const type = fileType[1]
    
    if (type == "pdf") {
      this.setState({
        loadingPreview: true,
      })

      let bodyFormData = new FormData();
      bodyFormData.append("files", file);
  
      Post("upload-file/pdf", null, bodyFormData, (response) => {
        const link = response.data.results
        let linkGambar = {...this.state.linkGambar};

        linkGambar[attribute] = link
  
        this.setState({ 
          linkGambar,
          loadingPreview: false, 
        });
      });
    } else {
      let status = { ...this.state.status };
      status.alert = true;

      this.setState({
        status,
        basicType: "warning",
        basicTitle: "Upload file",
        pesanAlert: "Format file harus berupa PDF",
      });
      document.getElementById("file-upload").value = null
    }

  };

	uploadFiles = (e) => {
    e.preventDefault()

    let isUploadEnable = true
    // Cek upload button
    Object.keys(this.state.linkGambar).map((attribute) => {
      isUploadEnable = isUploadEnable && this.state.linkGambar[attribute] != ''
    })

    if (isUploadEnable) {
      this.setState({
        loadingPreview: true,
      })

      let bodyFormData = {
        status: "Selesai",
        dokumen_output: this.state.linkGambar,
      };

      Post(
        "pelayanan/verifikasi-status",
        this.state.detailSurat.uuid,
        bodyFormData,
        (data) => {
          console.log(data);
          if (data.status == 200) {
            let status = { ...this.state.status };
            status.alert = true;
            this.setState({
              status,
              basicType: "success",
              basicTitle: "Upload Berkas",
              pesanAlert: "Berhasil upload berkas",
					    loadingPreview: false,
            });
            this.componentDidMount(this.state.active_tab_icon);
          } else {
            let status = { ...this.state.status };
            status.alert = true;
            this.setState({
              status,
              basicType: "danger",
              basicTitle: "Upload Berkas",
              pesanAlert: "Gagal upload berkas",
					    loadingPreview: false,
            });
          }
          this.tutupForm();
        }
      );
    } else {
      let status = { ...this.state.status };
      status.alert = true;

      this.setState({
        status,
        basicType: "warning",
        basicTitle: "Upload berkas",
        pesanAlert: "Pilih berkas terlebih dahulu",
      });
    }
  };

  toggle_icon(tab) {
    if (this.state.active_tab_icon !== tab) {
      if(tab === '1'){
        this.state.cek_posisi = 'Pertama'
      }
      else if(tab === '2'){
        this.state.cek_posisi = 'Kedua'
      }
      else if(tab === '3'){
        this.state.cek_posisi = 'Ketiga'
      }
      else if(tab === '4'){
        this.state.cek_posisi = 'Keempat'
      }
      
      this.setState({
        active_tab_icon: tab,
      });
    }
    
    this.forceUpdate();
    this.componentDidMount();
  }

  render() {
    var data = this.state.data;
		var dataDiproses = this.state.dataDiproses;
		var dataDitolak = this.state.dataDitolak;
		var dataSelesai = this.state.dataSelesai;

    let keluarga_datang = this.state.dataPelayanan;

    // const { SearchBar } = Search;
    const columns = [
      {
				dataField: "no",
				text: "No",
				isDummyField: true,
				csvExport: false,
				formatter: (key, obj, index) =>{ 
					let current_pagenum = '';
					let total_records_per_page = '';
					let row_index = '';
					let serial_num = '';
	  
					if(this.state.active_tab_icon ==='1'){
					  current_pagenum = this.state.pagination.current_page;
					  total_records_per_page = this.state.pagination.per_page;
					  row_index = (index+1);
					  serial_num = ((total_records_per_page*(current_pagenum-1))+row_index);
					}    
					else if(this.state.active_tab_icon ==='2'){
					  current_pagenum = this.state.paginationDiproses.current_page;
					  total_records_per_page = this.state.paginationDiproses.per_page;
					  row_index = (index+1);
					  serial_num = ((total_records_per_page*(current_pagenum-1))+row_index);
					}    
					else if(this.state.active_tab_icon ==='3'){
						current_pagenum = this.state.paginationSelesai.current_page;
						total_records_per_page = this.state.paginationSelesai.per_page;
						row_index = (index+1);
						serial_num = ((total_records_per_page*(current_pagenum-1))+row_index);
					}    
					else if(this.state.active_tab_icon ==='4'){
					  current_pagenum = this.state.paginationDitolak.current_page;
					  total_records_per_page = this.state.paginationDitolak.per_page;
					  row_index = (index+1);
					  serial_num = ((total_records_per_page*(current_pagenum-1))+row_index);
					}    
					
					return serial_num; 
				}
			},
      {
        dataField: "tgl_melapor",
        text: "Tanggal Melapor",
        sort: false,
      },
      {
        dataField: "jenis_layanan",
        text: "Layanan",
        sort: true,
      },
      {
        dataField: "nik_pelapor",
        text: "NIK",
        sort: true,
      },
      {
        dataField: "nama_pelapor",
        text: "Nama",
        sort: true,
      },
      {
        dataField: "data_status_baru.status",
        text: "status",
        sort: true,
        // formatter: (value)=> {
        //   return value[0]
        // }
      },
      {
        dataField: "aksi",
        text: "Aksi",
        isDummyField: true,
        csvExport: false,
        formatter: this.tombolAksi,
      },
    ];

    let uploadButton = true
    // Cek upload button
    Object.keys(this.state.linkGambar).map((attribute) => {
      uploadButton = uploadButton && this.state.linkGambar[attribute] != ''
    })

    return (
      <div>
        <div className="container-fluid">
          <SweetAlert
            show={this.state.status.alert}
            type={this.state.basicType}
            title={this.state.basicTitle}
            confirmBtnBsStyle="info"
            onConfirm={this.hideAlert}
          >
            {this.state.pesanAlert}
          </SweetAlert>

          {this.state.alert}

          <div className="row">
            <div className="col-lg-12 p-0 m-0">
              <div className="card col-lg-12 p-10">
                <div className="card mb-0">
                  <div className="card-header" style={{ padding: "20px" }}>
                    <div className="row">
                      <div className="col-sm-6 text-left">
                        <h6
                          style={{ color: "black", fontSize: "17px" }}
                          className="mb-0"
                        >
                          Data Penerbitan Pindah / Datang Keluar Daerah
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div
                    className="card-body datatable-react"
                    style={{ paddingTop: "15px" }}
                  >
                    <div className="row">
                      <div className="col-sm-12 p-0">
                        <Nav tabs className="tabs-color">
                          <NavItem style={{ cursor: "pointer" }}>
                            <NavLink
                              className={classnames({
                                active: this.state.active_tab_icon === "1",
                              })}
                              onClick={() => {
                                this.toggle_icon("1");
                              }}
                            >
                              Diajukan
                            </NavLink>
                          </NavItem>
                          <NavItem style={{ cursor: "pointer" }}>
                            <NavLink
                              className={classnames({
                                active: this.state.active_tab_icon === "2",
                              })}
                              onClick={() => {
                                this.toggle_icon("2");
                              }}
                            >
                              Diproses
                            </NavLink>
                          </NavItem>
                          <NavItem style={{ cursor: "pointer" }}>
                            <NavLink
                              className={classnames({
                                active: this.state.active_tab_icon === "3",
                              })}
                              onClick={() => {
                                this.toggle_icon("3");
                              }}
                            >
                              Selesai
                            </NavLink>
                          </NavItem>
                          <NavItem style={{ cursor: "pointer" }}>
                            <NavLink
                              className={classnames({
                                active: this.state.active_tab_icon === "4",
                              })}
                              onClick={() => {
                                this.toggle_icon("4");
                              }}
                            >
                              Ditolak
                            </NavLink>
                          </NavItem>
                        </Nav>
                        <TabContent activeTab={this.state.active_tab_icon}>
                          <TabPane tabId="1">
                            <div style={{ marginTop: "15px" }}>
                              <div className="row">
                                <div className="col-sm-12">
                                  <BootstrapTable
                                    keyField="uuid"
                                    data={data}
                                    columns={columns}
                                  />
                                  <div className="pull-right text-white">
                                    {this.state.awal}
                                    {this.state.sebelum}
                                    {this.state.hal.map(dt => {
                                      return dt;
                                    })}
                                    {this.state.setelah}
                                    {this.state.akhir}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </TabPane>
                          <TabPane tabId="2">
                            <div style={{ marginTop: "15px" }}>
                              <div className="row">
                                <div className="col-sm-12">
                                  <BootstrapTable
                                    keyField="uuid_surat"
                                    data={dataDiproses}
                                    columns={columns}
                                  />
                                  <div className="pull-right text-white">
                                    {this.state.awal2}
                                    {this.state.sebelum2}
                                    {this.state.hal2.map(dt => {
                                      return dt;
                                    })}
                                    {this.state.setelah2}
                                    {this.state.akhir2}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </TabPane>
                          <TabPane tabId="3">
                            <div style={{ marginTop: "15px" }}>
                              <div className="row">
                                <div className="col-sm-12">
                                  <BootstrapTable
                                    keyField="uuid_surat"
                                    data={dataSelesai}
                                    columns={columns}
                                  />
                                  <div className="pull-right text-white">
                                    {this.state.awal3}
                                    {this.state.sebelum3}
                                    {this.state.hal3.map(dt => {
                                      return dt;
                                    })}
                                    {this.state.setelah3}
                                    {this.state.akhir3}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </TabPane>
                          <TabPane tabId="4">
                            <div style={{ marginTop: "15px" }}>
                              <div className="row">
                                <div className="col-sm-12">
                                  <BootstrapTable
                                    keyField="uuid_surat"
                                    data={dataDitolak}
                                    columns={columns}
                                  />
                                  <div className="pull-right text-white">
                                    {this.state.awal4}
                                    {this.state.sebelum4}
                                    {this.state.hal4.map(dt => {
                                      return dt;
                                    })}
                                    {this.state.setelah4}
                                    {this.state.akhir4}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </TabPane>
                        </TabContent>
                      </div>
                    </div>
                    <div className="row" style={{ marginTop: "10px" }}>
                      <div className="col-sm-12">
                        <Link to="/admin/dashboard">Kembali ke dashboard</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Modal validasi */}
          <Modal
            open={this.state.status.modalValidasi}
            styles={{
              modal: {
                width: "85%",
              },
            }}
            onClose={() => {
              // Tutup modal validasi
              let status = { ...this.state.status };
              status.modalValidasi = false;
              this.setState({ 
                status,
                detailSurat: null,
								dataPelayanan: null,
								dataPelapor: null,
								dataDokumenOutput: [],
              });
            }}
          >
            <Form className="theme-form">
              <div className="modal-header">
                <h5 className="modal-title">
                  Form Validasi Penerbitan Pindah / Datang Keluar Daerah
                </h5>
              </div>
              <div className="modal-body">
                {this.state.detailSurat ? (
                  <div>
                    {/* Detail Surat */}
                    <FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-3 col-form-label"
                        style={{ fontWeight: 600 }}
                      >
                        Nama
                      </Label>
                      <div
                        className="col-sm-9"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6 style={{ marginBottom: "0px", fontWeight: 400 }}>
                          {this.state.detailSurat.nama_pelapor}
                        </h6>
                      </div>
                    </FormGroup>
                    <FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-3 col-form-label"
                        style={{ fontWeight: 600 }}
                      >
                        NIK
                      </Label>
                      <div
                        className="col-sm-9"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6 style={{ marginBottom: "0px", fontWeight: 400 }}>
                          {this.state.detailSurat.nik_pelapor}
                        </h6>
                      </div>
                    </FormGroup>
                    <FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-3 col-form-label"
                        style={{ fontWeight: 600 }}
                      >
                        Jenis Layanan
                      </Label>
                      <div
                        className="col-sm-9"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6 style={{ marginBottom: "0px", fontWeight: 400 }}>
                          {this.state.detailSurat.jenis_layanan}
                        </h6>
                      </div>
                    </FormGroup>
                    <FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-3 col-form-label"
                        style={{ fontWeight: 600 }}
                      >
                        Tanggal Pengajuan
                      </Label>
                      <div
                        className="col-sm-9"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6 style={{ marginBottom: "0px", fontWeight: 400 }}>
                          {this.state.detailSurat.tgl_melapor}
                        </h6>
                      </div>
                    </FormGroup>
                    <FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-3 col-form-label"
                        style={{ fontWeight: 600 }}
                      >
                        Nomor HP
                      </Label>
                      <div
                        className="col-sm-9"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6 style={{ marginBottom: "0px", fontWeight: 400 }}>
                          {this.state.detailSurat.no_hp_pelapor}
                        </h6>
                      </div>
                    </FormGroup>
                    <FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-3 col-form-label"
                        style={{ fontWeight: 600 }}
                      >
                        Pekerjaan
                      </Label>
                      <div
                        className="col-sm-9"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6 style={{ marginBottom: "0px", fontWeight: 400 }}>
                          {this.state.detailSurat.pekerjaan_pelapor}
                        </h6>
                      </div>
                    </FormGroup>
                    <FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-3 col-form-label"
                        style={{ fontWeight: 600 }}
                      >
                        Alamat
                      </Label>
                      <div
                        className="col-sm-9"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6 style={{ marginBottom: "0px", fontWeight: 400 }}>
                          {this.state.detailSurat.alamat_pelapor}
                        </h6>
                      </div>
                    </FormGroup>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="modal-body">
                {this.state.dataPelayanan ? (
                  <div>
                    {this.state.dataDokumenOutput ?
                      Object.keys(this.state.dataDokumenOutput).map((val, index) => {
                        let dokumenOutput = this.state.dataDokumenOutput

                        let titleFile = 'dokumen'
												switch (val) {
													case 'pindah_datang':
														titleFile = "Pindah Datang"
														break;
												}

                        return (
                          <FormGroup className="row" style={{ marginBottom: "0px" }}>
                            <Label
                              className="col-sm-4 col-form-label pb-1"
                              style={{ fontWeight: 600 }}
                            >
                              Berkas {titleFile}
                            </Label>
                            <div
                              className="col-sm-8"
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <a href={dokumenOutput[val]}>Lihat file</a>
                            </div>
                          </FormGroup>
                        )
                      })
                    : ''}
                    <FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1 pt-3"
                        style={{ fontWeight: 1000 }}
                      >
                        <h6 style={{ margin: "0px" }}>
                          <b>Data Wilayah</b>
                        </h6>
                      </Label>
                    </FormGroup>
                    <div
                      style={{
                        padding: "10px",
                        border: "1px solid black",
                        borderRadius: "15px",
                      }}
                    >
                    <FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1 pt-0"
                        style={{ fontWeight: 600 }}
                      >
                        Provinsi
                      </Label>
                      <div
                        className="col-sm-8"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6
                          style={{
                            marginBottom: "0px",
                            fontWeight: 400,
                            fontSize: "inherit",
                          }}
                        >
                          {this.state.detailSurat
                            ? this.state.detailSurat.id_desa
                                .toString()
                                .substr(0, 2) +
                              " - " +
                              this.state.detailSurat.data_kelurahan
                                .data_kecamatan.data_kabkota.data_provinsi.nama
                            : ""}
                        </h6>
                      </div>
                    </FormGroup>
                    <FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1"
                        style={{ fontWeight: 600 }}
                      >
                        Kabupaten / Kota
                      </Label>
                      <div
                        className="col-sm-8"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6
                          style={{
                            marginBottom: "0px",
                            fontWeight: 400,
                            fontSize: "inherit",
                          }}
                        >
                          {this.state.detailSurat
                            ? this.state.detailSurat.id_desa
                                .toString()
                                .substr(2, 2) +
                              " - " +
                              this.state.detailSurat.data_kelurahan
                                .data_kecamatan.data_kabkota.nama
                            : ""}
                        </h6>
                      </div>
                    </FormGroup>
                    <FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1"
                        style={{ fontWeight: 600 }}
                      >
                        Kecamatan
                      </Label>
                      <div
                        className="col-sm-8"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6
                          style={{
                            marginBottom: "0px",
                            fontWeight: 400,
                            fontSize: "inherit",
                          }}
                        >
                          {this.state.detailSurat
                            ? this.state.detailSurat.id_desa
                                .toString()
                                .substr(4, 2) +
                              " - " +
                              this.state.detailSurat.data_kelurahan
                                .data_kecamatan.nama
                            : ""}
                        </h6>
                      </div>
                    </FormGroup>
                    <FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1"
                        style={{ fontWeight: 600 }}
                      >
                        Desa / Kelurahan
                      </Label>
                      <div
                        className="col-sm-8"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6
                          style={{
                            marginBottom: "0px",
                            fontWeight: 400,
                            fontSize: "inherit",
                          }}
                        >
                          {this.state.detailSurat
                            ? this.state.detailSurat.id_desa
                                .toString()
                                .substr(6, 4) +
                              " - " +
                              this.state.detailSurat.data_kelurahan.nama
                            : ""}
                        </h6>
                      </div>
                    </FormGroup>
                    <FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1"
                        style={{ fontWeight: 600 }}
                      >
                        Dusun / Dukuh / Kampung
                      </Label>
                      <div
                        className="col-sm-8"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6
                          style={{
                            marginBottom: "0px",
                            fontWeight: 400,
                            fontSize: "inherit",
                          }}
                        >
                          {
                            this.state.dataPelayanan.data_daerah_asal
                              .jenis_desa_asal
                          }
                        </h6>
                      </div>
                    </FormGroup>
                    </div>

                    <FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1 pt-3"
                        style={{ fontWeight: 1000 }}
                      >
                        <h6 style={{ margin: "0px" }}>
                          <b>Data Daerah Asal</b>
                        </h6>
                      </Label>
                    </FormGroup>
                    <div
                      style={{
                        padding: "10px",
                        border: "1px solid black",
                        borderRadius: "15px",
                      }}
                    >
                      <FormGroup
                        className="row"
                        style={{ marginBottom: "0px" }}
                      >
                        <Label
                          className="col-sm-4 col-form-label pb-1 pt-0"
                          style={{ fontWeight: 600 }}
                        >
                          Nomor Kartu Keluarga
                        </Label>
                        <div
                          className="col-sm-8"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <h6
                            style={{
                              marginBottom: "0px",
                              fontWeight: 400,
                              fontSize: "inherit",
                            }}
                          >
                            {
                              this.state.dataPelayanan.data_daerah_asal
                                .no_kk_asal
                            }
                          </h6>
                        </div>
                      </FormGroup>
                      <FormGroup
                        className="row"
                        style={{ marginBottom: "0px" }}
                      >
                        <Label
                          className="col-sm-4 col-form-label pb-1"
                          style={{ fontWeight: 600 }}
                        >
                          Nama Kepala Keluarga
                        </Label>
                        <div
                          className="col-sm-8"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <h6
                            style={{
                              marginBottom: "0px",
                              fontWeight: 400,
                              fontSize: "inherit",
                            }}
                          >
                            {
                              this.state.dataPelayanan.data_daerah_asal
                                .nama_kepala_keluarga_asal
                            }
                          </h6>
                        </div>
                      </FormGroup>
                      <FormGroup
                        className="row"
                        style={{ marginBottom: "0px" }}
                      >
                        <Label
                          className="col-sm-4 col-form-label pb-1"
                          style={{ fontWeight: 600 }}
                        >
                          Alamat
                        </Label>
                        <div
                          className="col-sm-8"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <h6
                            style={{
                              marginBottom: "0px",
                              fontWeight: 400,
                              fontSize: "inherit",
                            }}
                          >
                            {
                              this.state.dataPelayanan.data_daerah_asal
                                .alamat_asal
                            }
                          </h6>
                        </div>
                      </FormGroup>
                      <FormGroup
                        className="row"
                        style={{ marginBottom: "0px" }}
                      >
                        <Label
                          className="col-sm-4 col-form-label pb-1"
                          style={{ fontWeight: 600 }}
                        >
                          RT/RW
                        </Label>
                        <div
                          className="col-sm-8"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <h6
                            style={{
                              marginBottom: "0px",
                              fontWeight: 400,
                              fontSize: "inherit",
                            }}
                          >
                            {this.state.dataPelayanan.data_daerah_asal.rt_asal}{" "}
                            /{" "}
                            {this.state.dataPelayanan.data_daerah_asal.rw_asal}
                          </h6>
                        </div>
                      </FormGroup>
                      <FormGroup
                        className="row"
                        style={{ marginBottom: "0px" }}
                      >
                        <Label
                          className="col-sm-4 col-form-label pb-1"
                          style={{ fontWeight: 600 }}
                        >
                          Dusun/Dukuh/Kampung
                        </Label>
                        <div
                          className="col-sm-8"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <h6
                            style={{
                              marginBottom: "0px",
                              fontWeight: 400,
                              fontSize: "inherit",
                            }}
                          >
                            {
                              this.state.dataPelayanan.data_daerah_asal
                                .jenis_desa_asal
                            }
                          </h6>
                        </div>
                      </FormGroup>
                      <FormGroup
                        className="row"
                        style={{ marginBottom: "0px", paddingLeft: "1.5rem" }}
                      >
                        <Label
                          className="col-sm-4 col-form-label pb-1"
                          style={{ fontWeight: 600 }}
                        >
                          Desa/Kelurahan
                        </Label>
                        <div
                          className="col-sm-8"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <h6
                            style={{
                              marginBottom: "0px",
                              fontWeight: 400,
                              fontSize: "inherit",
                            }}
                          >
                            {
                              this.state.dataPelayanan.data_daerah_asal
                                .deskel_asal
                            }
                          </h6>
                        </div>
                      </FormGroup>
                      <FormGroup
                        className="row"
                        style={{ marginBottom: "0px", paddingLeft: "1.5rem" }}
                      >
                        <Label
                          className="col-sm-4 col-form-label pb-1"
                          style={{ fontWeight: 600 }}
                        >
                          Kecamatan
                        </Label>
                        <div
                          className="col-sm-8"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <h6
                            style={{
                              marginBottom: "0px",
                              fontWeight: 400,
                              fontSize: "inherit",
                            }}
                          >
                            {
                              this.state.dataPelayanan.data_daerah_asal
                                .kecamatan_asal
                            }
                          </h6>
                        </div>
                      </FormGroup>
                      <FormGroup
                        className="row"
                        style={{ marginBottom: "0px", paddingLeft: "1.5rem" }}
                      >
                        <Label
                          className="col-sm-4 col-form-label pb-1"
                          style={{ fontWeight: 600 }}
                        >
                          Kab/Kota
                        </Label>
                        <div
                          className="col-sm-8"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <h6
                            style={{
                              marginBottom: "0px",
                              fontWeight: 400,
                              fontSize: "inherit",
                            }}
                          >
                            {
                              this.state.dataPelayanan.data_daerah_asal
                                .kabkota_asal
                            }
                          </h6>
                        </div>
                      </FormGroup>
                      <FormGroup
                        className="row"
                        style={{ marginBottom: "0px", paddingLeft: "1.5rem" }}
                      >
                        <Label
                          className="col-sm-4 col-form-label pb-1"
                          style={{ fontWeight: 600 }}
                        >
                          Provinsi
                        </Label>
                        <div
                          className="col-sm-8"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <h6
                            style={{
                              marginBottom: "0px",
                              fontWeight: 400,
                              fontSize: "inherit",
                            }}
                          >
                            {
                              this.state.dataPelayanan.data_daerah_asal
                                .provinsi_asal
                            }
                          </h6>
                        </div>
                      </FormGroup>
                      <FormGroup
                        className="row"
                        style={{ marginBottom: "0px" }}
                      >
                        <Label
                          className="col-sm-4 col-form-label pb-1"
                          style={{ fontWeight: 600 }}
                        >
                          Kode Pos
                        </Label>
                        <div
                          className="col-sm-8"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <h6
                            style={{
                              marginBottom: "0px",
                              fontWeight: 400,
                              fontSize: "inherit",
                            }}
                          >
                            {
                              this.state.dataPelayanan.data_daerah_asal
                                .kode_pos_asal
                            }
                          </h6>
                        </div>
                      </FormGroup>
                      <FormGroup
                        className="row"
                        style={{ marginBottom: "0px" }}
                      >
                        <Label
                          className="col-sm-4 col-form-label pb-1"
                          style={{ fontWeight: 600 }}
                        >
                          Telepon
                        </Label>
                        <div
                          className="col-sm-8"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <h6
                            style={{
                              marginBottom: "0px",
                              fontWeight: 400,
                              fontSize: "inherit",
                            }}
                          >
                            {this.state.detailSurat.no_hp_pelapor}
                          </h6>
                        </div>
                      </FormGroup>
                      <FormGroup
                        className="row"
                        style={{ marginBottom: "0px" }}
                      >
                        <Label
                          className="col-sm-4 col-form-label pb-1"
                          style={{ fontWeight: 600 }}
                        >
                          NIK Pemohon
                        </Label>
                        <div
                          className="col-sm-8"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <h6
                            style={{
                              marginBottom: "0px",
                              fontWeight: 400,
                              fontSize: "inherit",
                            }}
                          >
                            {this.state.detailSurat.nik_pelapor}
                          </h6>
                        </div>
                      </FormGroup>
                      <FormGroup
                        className="row"
                        style={{ marginBottom: "0px" }}
                      >
                        <Label
                          className="col-sm-4 col-form-label pb-1"
                          style={{ fontWeight: 600 }}
                        >
                          Nama Lengkap
                        </Label>
                        <div
                          className="col-sm-8"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <h6
                            style={{
                              marginBottom: "0px",
                              fontWeight: 400,
                              fontSize: "inherit",
                            }}
                          >
                            {this.state.detailSurat.nama_pelapor}
                          </h6>
                        </div>
                      </FormGroup>
                    </div>
                    <FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1 pt-3"
                        style={{ fontWeight: 1000 }}
                      >
                        <h6 style={{ margin: "0px" }}>
                          <b>Data Daerah Tujuan</b>
                        </h6>
                      </Label>
                    </FormGroup>
                    <div
                      style={{
                        padding: "10px",
                        border: "1px solid black",
                        borderRadius: "15px",
                      }}
                    >
                      <FormGroup
                        className="row"
                        style={{ marginBottom: "0px" }}
                      >
                        <Label
                          className="col-sm-4 col-form-label pb-1 pt-0"
                          style={{ fontWeight: 600 }}
                        >
                          Status Nomor KK Bagi Yang Pindah
                        </Label>
                        <div
                          className="col-sm-8"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <h6
                            style={{
                              marginBottom: "0px",
                              fontWeight: 400,
                              fontSize: "inherit",
                            }}
                          >
                            {
                              this.state.dataPelayanan.data_daerah_tujuan
                                .status_no_kk_tujuan
                            }
                          </h6>
                        </div>
                      </FormGroup>
                      <FormGroup
                        className="row"
                        style={{ marginBottom: "0px" }}
                      >
                        <Label
                          className="col-sm-4 col-form-label pb-1"
                          style={{ fontWeight: 600 }}
                        >
                          Nomor Kartu Keluarga
                        </Label>
                        <div
                          className="col-sm-8"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <h6
                            style={{
                              marginBottom: "0px",
                              fontWeight: 400,
                              fontSize: "inherit",
                            }}
                          >
                            {
                              this.state.dataPelayanan.data_daerah_tujuan
                                .no_kk_tujuan
                            }
                          </h6>
                        </div>
                      </FormGroup>
                      <FormGroup
                        className="row"
                        style={{ marginBottom: "0px" }}
                      >
                        <Label
                          className="col-sm-4 col-form-label pb-1"
                          style={{ fontWeight: 600 }}
                        >
                          NIK Kepala Keluarga
                        </Label>
                        <div
                          className="col-sm-8"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <h6
                            style={{
                              marginBottom: "0px",
                              fontWeight: 400,
                              fontSize: "inherit",
                            }}
                          >
                            {
                              this.state.dataPelayanan.data_daerah_tujuan
                                .nik_kepala_keluarga_tujuan
                            }
                          </h6>
                        </div>
                      </FormGroup>
                      <FormGroup
                        className="row"
                        style={{ marginBottom: "0px" }}
                      >
                        <Label
                          className="col-sm-4 col-form-label pb-1"
                          style={{ fontWeight: 600 }}
                        >
                          Nama Kepala Keluarga
                        </Label>
                        <div
                          className="col-sm-8"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <h6
                            style={{
                              marginBottom: "0px",
                              fontWeight: 400,
                              fontSize: "inherit",
                            }}
                          >
                            {
                              this.state.dataPelayanan.data_daerah_tujuan
                                .nama_kepala_keluarga_tujuan
                            }
                          </h6>
                        </div>
                      </FormGroup>
                      <FormGroup
                        className="row"
                        style={{ marginBottom: "0px" }}
                      >
                        <Label
                          className="col-sm-4 col-form-label pb-1"
                          style={{ fontWeight: 600 }}
                        >
                          Tanggal Kedatangan
                        </Label>
                        <div
                          className="col-sm-8"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <h6
                            style={{
                              marginBottom: "0px",
                              fontWeight: 400,
                              fontSize: "inherit",
                            }}
                          >
                            {
                              this.state.dataPelayanan.data_daerah_tujuan
                                .tgl_kedatangan
                            }
                          </h6>
                        </div>
                      </FormGroup>
                      <FormGroup
                        className="row"
                        style={{ marginBottom: "0px" }}
                      >
                        <Label
                          className="col-sm-4 col-form-label pb-1"
                          style={{ fontWeight: 600 }}
                        >
                          Alamat
                        </Label>
                        <div
                          className="col-sm-8"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <h6
                            style={{
                              marginBottom: "0px",
                              fontWeight: 400,
                              fontSize: "inherit",
                            }}
                          >
                            {
                              this.state.dataPelayanan.data_daerah_tujuan
                                .alamat_tujuan
                            }
                          </h6>
                        </div>
                      </FormGroup>
                      <FormGroup
                        className="row"
                        style={{ marginBottom: "0px" }}
                      >
                        <Label
                          className="col-sm-4 col-form-label pb-1"
                          style={{ fontWeight: 600 }}
                        >
                          RT/RW
                        </Label>
                        <div
                          className="col-sm-8"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <h6
                            style={{
                              marginBottom: "0px",
                              fontWeight: 400,
                              fontSize: "inherit",
                            }}
                          >
                            {
                              this.state.dataPelayanan.data_daerah_tujuan
                                .rt_tujuan
                            }{" "}
                            /{" "}
                            {
                              this.state.dataPelayanan.data_daerah_tujuan
                                .rw_tujuan
                            }
                          </h6>
                        </div>
                      </FormGroup>
                      <FormGroup
                        className="row"
                        style={{ marginBottom: "0px" }}
                      >
                        <Label
                          className="col-sm-4 col-form-label pb-1"
                          style={{ fontWeight: 600 }}
                        >
                          Dusun/Dukuh/Kampung
                        </Label>
                        <div
                          className="col-sm-8"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <h6
                            style={{
                              marginBottom: "0px",
                              fontWeight: 400,
                              fontSize: "inherit",
                            }}
                          >
                            {
                              this.state.dataPelayanan.data_daerah_tujuan
                                .jenis_desa_tujuan
                            }
                          </h6>
                        </div>
                      </FormGroup>
                      <FormGroup
                        className="row"
                        style={{ marginBottom: "0px", paddingLeft: "1.5rem" }}
                      >
                        <Label
                          className="col-sm-4 col-form-label pb-1"
                          style={{ fontWeight: 600 }}
                        >
                          Desa/Kelurahan
                        </Label>
                        <div
                          className="col-sm-8"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <h6
                            style={{
                              marginBottom: "0px",
                              fontWeight: 400,
                              fontSize: "inherit",
                            }}
                          >
                            {
                              this.state.dataPelayanan.data_daerah_tujuan
                                .deskel_tujuan
                            }
                          </h6>
                        </div>
                      </FormGroup>
                      <FormGroup
                        className="row"
                        style={{ marginBottom: "0px", paddingLeft: "1.5rem" }}
                      >
                        <Label
                          className="col-sm-4 col-form-label pb-1"
                          style={{ fontWeight: 600 }}
                        >
                          Kecamatan
                        </Label>
                        <div
                          className="col-sm-8"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <h6
                            style={{
                              marginBottom: "0px",
                              fontWeight: 400,
                              fontSize: "inherit",
                            }}
                          >
                            {
                              this.state.dataPelayanan.data_daerah_tujuan
                                .kecamatan_tujuan
                            }
                          </h6>
                        </div>
                      </FormGroup>
                      <FormGroup
                        className="row"
                        style={{ marginBottom: "0px", paddingLeft: "1.5rem" }}
                      >
                        <Label
                          className="col-sm-4 col-form-label pb-1"
                          style={{ fontWeight: 600 }}
                        >
                          Kab/Kota
                        </Label>
                        <div
                          className="col-sm-8"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <h6
                            style={{
                              marginBottom: "0px",
                              fontWeight: 400,
                              fontSize: "inherit",
                            }}
                          >
                            {
                              this.state.dataPelayanan.data_daerah_tujuan
                                .kabkota_tujuan
                            }
                          </h6>
                        </div>
                      </FormGroup>
                      <FormGroup
                        className="row"
                        style={{ marginBottom: "0px", paddingLeft: "1.5rem" }}
                      >
                        <Label
                          className="col-sm-4 col-form-label pb-1"
                          style={{ fontWeight: 600 }}
                        >
                          Provinsi
                        </Label>
                        <div
                          className="col-sm-8"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <h6
                            style={{
                              marginBottom: "0px",
                              fontWeight: 400,
                              fontSize: "inherit",
                            }}
                          >
                            {
                              this.state.dataPelayanan.data_daerah_tujuan
                                .provinsi_tujuan
                            }
                          </h6>
                        </div>
                      </FormGroup>
                    </div>
                    <FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-5 col-form-label mt-2"
                        style={{ fontWeight: 1000 }}
                      >
                        <h6 style={{ margin: "0px" }}>
                          <b>Keluarga Yang Datang :</b>
                        </h6>
                      </Label>
                    </FormGroup>

                    <FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <div className="col-sm-12" style={{ overflowX: "auto" }}>
                        <Table className="table-custom" striped>
                          <thead>
                            <tr>
                              <th>No</th>
                              <th>NIK</th>
                              <th>Nama</th>
                              <th>Masa Berlaku KTP s/d</th>
                              <th>SHDK</th>
                            </tr>
                          </thead>
                          <tbody>
                            {keluarga_datang.data_daerah_tujuan
                              .keluarga_yang_datang &&
                              keluarga_datang.data_daerah_tujuan.keluarga_yang_datang.map(
                                (kel, index) => (
                                  <tr>
                                    <td scope="row">{index + 1}</td>
                                    <td>{kel.nik_keluarga}</td>
                                    <td>{kel.nama_keluarga}</td>
                                    <td>{kel.expired_ktp}</td>
                                    <td>{kel.shdk}</td>
                                  </tr>
                                )
                              )}
                          </tbody>
                        </Table>
                      </div>
                    </FormGroup>
                    <hr />
                    <FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-3 col-form-label pt-0"
                        style={{ fontWeight: 600 }}
                      >
                        <h5>Persyaratan</h5>
                      </Label>
                      <div
                        className="col-sm-9"
                        style={{ display: "flex", alignItems: "center" }}
                      />
                    </FormGroup>
                    <FormGroup className="row">
                      {/* Looping foto persyaratan */}
                      {Object.keys(
                        JSON.parse(this.state.detailSurat.dokumen_input)
                      ).map((val, index) => {
                        let dokumen_input = JSON.parse(
                          this.state.detailSurat.dokumen_input
                        );
                        let nama_syarat = "Foto Persyaratan";

                        return (
                          <div className="col-sm-12">
                            <h6
                              style={{
                                textTransform: "capitalize",
                                marginBottom: "1rem",
                              }}
                            >
                              {val}
                            </h6>
                            <div className="row">
                              {Object.keys(dokumen_input[val]).map(
                                (syarat, index3) => {
                                  switch (syarat) {
                                    case "skpwni":
                                      nama_syarat = "SKPWNI";
                                      break;

                                    case "f1_03":
                                      nama_syarat = "Blank F1 03";
                                      break;

                                    case "f1_01_desa_tujuan":
                                      nama_syarat = "Blanko F1 01 Desa Tujuan";
                                      break;

                                    case "akta_nikah":
                                      nama_syarat = "Akta Nikah";
                                      break;

                                    case "kk_asli":
                                      nama_syarat = "Kartu Keluarga Asli";
                                      break;

                                    case "pas_poto":
                                      nama_syarat = "Pas Foto";
                                      break;

                                    case "surat_menikah":
                                      nama_syarat = "Surat Menikah";
                                      break;

                                    case "surat_pengantar":
                                      nama_syarat = "Surat Pengantar";
                                      break;

                                    default:
                                      break;
                                  }

                                  return (
                                    <div
                                      className="col-md-4"
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                      }}
                                    >
                                      {dokumen_input[val][syarat] != '' ? (
                                        <img
                                          src={
                                            dokumen_input[val][syarat] != ""
                                              ? dokumen_input[val][syarat]
                                              : require("../../assets/images/calender-bg.png")
                                          }
                                          style={{
                                            width: "200px",
                                            height: "200px", 
                                            objectFit: "cover",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            let status = { ...this.state.status };
                                            status.fotoPersyaratan = true;
                                            this.setState({
                                              status,
                                              fotoPersyaratan:
                                                dokumen_input[val][syarat],
                                              titlePersyaratan: nama_syarat,
                                            });
                                          }}
                                        />
                                      ) : (
                                        <div style={{ height: "200px", width: "200px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                          <p style={{ textAlign: "center", fontWeight: 600 }}>Tidak ada foto persyaratan</p>
                                        </div>
                                      )}
                                      <br/>
                                      <p style={{ textAlign: "center" }}><b>{nama_syarat}</b></p>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </FormGroup>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="modal-footer">
              <button
									type="button"
									className="btn btn-primary"
									// disabled={this.state.status.btnForm}
									onClick={() => {
										// Tutup modal validasi
										let status = { ...this.state.status };
										status.modalValidasi = false;
										this.setState({ 
										status,
										detailSurat: null,
										dataPelayanan: null,
										dataPelapor: null,
										dataDokumenOutput: [],
										});
									}}
									style={{ fontWeight: 600 }}
									>
									Tutup
								</button>
                <button
                  type="button"
                  className="btn btn-warning"
                  // disabled={this.state.status.btnForm}
                  onClick={() => {
                    let status = { ...this.state.status };
                    status.tolakValidasi = true;

                    this.setState({ status });
                  }}
                  style={{ fontWeight: 600 }}
                >
                  Tolak
                </button>
                <button
                  type="submit"
                  className="btn btn-success"
                  // disabled={this.state.status.btnForm}
                  onClick={(e) => {
                    e.preventDefault();
                    if (this.state.detailSurat) {
                      this.updateSurat(this.state.detailSurat.uuid);
                    }
                  }}
                  style={{ fontWeight: 600 }}
                >
                  Terima
                </button>
              </div>
            </Form>
          </Modal>
          {/* Modal Detail Gambar */}
          <Modal
            open={this.state.status.fotoPersyaratan}
            styles={{
              modal: {
                width: "90%",
              },
            }}
            onClose={() => {
              let status = { ...this.state.status };
              status.fotoPersyaratan = false;
              this.setState({ status });
            }}
          >
            <div className="modal-header">
              <h5 className="modal-title">{this.state.titlePersyaratan}</h5>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  {this.state.fotoPersyaratan != '' ? (
                    <img
                    src={this.state.fotoPersyaratan}
                    style={{ width: "100%" }}
                  />
                  ) : (
                    <h6 style={{ textAlign: "center" }}>Tidak ada Foto</h6>
                  )}
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-default"
                // disabled={this.state.status.btnForm}
                onClick={() => {
                  let status = { ...this.state.status };
                  status.fotoPersyaratan = false;
                  this.setState({ status });
                }}
                style={{ fontWeight: 600 }}
              >
                Tutup
              </button>
            </div>
          </Modal>
          {/* Modal form penolakan pengajuan */}
          <Modal
            open={this.state.status.tolakValidasi}
            styles={{
              modal: {
                width: "80%",
              },
            }}
            onClose={() => {
              let status = { ...this.state.status };
              status.tolakValidasi = false;
              this.setState({ status });
            }}
          >
            <Form className="theme-form">
              <div className="modal-header">
                <h5 className="modal-title">Penolakan Pengajuan Layanan</h5>
              </div>
              <div className="modal-body">
                <FormGroup className="row" style={{ marginBottom: "0px" }}>
                  <Label
                    className="col-sm-3 col-form-label"
                    style={{ fontWeight: 600 }}
                  >
                    Alasan Penolakan
                  </Label>
                  <div className="col-sm-9" style={{ textAlign: "center" }}>
                    <Input type="textarea" id="alasan" nama="alasan" />
                  </div>
                </FormGroup>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-info"
                  // disabled={this.state.status.btnForm}
                  onClick={() => {
                    let status = { ...this.state.status };
                    status.tolakValidasi = false;
                    this.setState({ status });
                  }}
                  style={{ fontWeight: 600 }}
                >
                  Batal
                </button>
                <button
                  type="submit"
                  className="btn btn-success"
                  // disabled={this.state.status.btnForm}
                  onClick={(e) => {
                    e.preventDefault();
                    this.tolakSurat(this.state.detailSurat.uuid);
                  }}
                  style={{ fontWeight: 600 }}
                >
                  Simpan
                </button>
              </div>
            </Form>
          </Modal>
          {/* Modal upload berkas */}
          <Modal
            open={this.state.status.modalUpload}
            styles={{
              modal: {
                width: "85%",
              },
            }}
            onClose={() => {
              let status = { ...this.state.status };
              status.modalUpload = false;
              this.setState({ 
                status, 
                linkGambar: listFile
              });
            }}
          >
            <Form className="theme-form" onSubmit={this.uploadFiles}>
              <div className="modal-header">
                <h5 className="modal-title">Form Upload Berkas</h5>
              </div>
              <div className="modal-body">
                {Object.keys(this.state.linkGambar).map((attribute) => {
                  let titleFile = 'dokumen'
                  switch (attribute) {
                    case 'pindah_datang':
                      titleFile = "Pindah Datang"
                      break;
                  }

                  return (
                    <FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-3 col-form-label"
                        style={{ fontWeight: 600 }}
                      >
                        File {titleFile}
                      </Label>
                      <div
                        className="col-sm-9"
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <input id="file-upload" type="file" onChange={(event) => {
                          this.onFileChange(event, attribute)
                        }} />
                        {this.state.linkGambar[attribute] != "" ? (
                          <a href={this.state.linkGambar[attribute]}>Lihat file</a>
                        ) : (
                          ""
                        )}
                      </div>
                    </FormGroup>
                  )
                })}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-info"
                  // disabled={this.state.status.btnForm}
                  onClick={() => {
                    this.tutupForm();
                  }}
                  style={{ fontWeight: 600 }}
                >
                  Batal
                </button>
                <button
                  type="submit"
                  disabled={uploadButton ? false : true}
                  className="btn btn-success"
                  style={{ fontWeight: 600 }}
                >
                  Simpan
                </button>
              </div>
            </Form>
          </Modal>
          {/* Modal detail berkas */}
          <Modal
            open={this.state.status.modalDetail}
            styles={{
              modal: {
                width: "75%",
              },
            }}
            onClose={() => {
              let status = { ...this.state.status };
              status.modalDetail = false;
              this.setState({ status });
            }}
          >
            <Form className="theme-form">
              <div className="modal-header">
                <h5 className="modal-title">
                  Form Pengajuan Penerbitan Pindah / Datang Keluar Daerah
                </h5>
              </div>
              <div className="modal-body">
                {this.state.dataPelayanan ? (
                  <div>
                    {this.state.dataDokumenOutput ?
                      Object.keys(this.state.dataDokumenOutput).map((val, index) => {
                        let dokumenOutput = this.state.dataDokumenOutput

                        let titleFile = 'dokumen'
												switch (val) {
													case 'pindah_datang':
														titleFile = "Pindah Datang"
														break;
												}

                        return (
                          <FormGroup className="row" style={{ marginBottom: "0px" }}>
                            <Label
                              className="col-sm-4 col-form-label pb-1"
                              style={{ fontWeight: 600 }}
                            >
                              Berkas {titleFile}
                            </Label>
                            <div
                              className="col-sm-8"
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <a href={dokumenOutput[val]}>Lihat file</a>
                            </div>
                          </FormGroup>
                        )
                      })
                    : ''}
                    <FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1 pt-3"
                        style={{ fontWeight: 1000 }}
                      >
                        <h6 style={{ margin: "0px" }}>
                          <b>Data Wilayah</b>
                        </h6>
                      </Label>
                    </FormGroup>
                    <div
                      style={{
                        padding: "10px",
                        border: "1px solid black",
                        borderRadius: "15px",
                      }}
                    >
                    <FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1 pt-0"
                        style={{ fontWeight: 600 }}
                      >
                        Provinsi
                      </Label>
                      <div
                        className="col-sm-8"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6
                          style={{
                            marginBottom: "0px",
                            fontWeight: 400,
                            fontSize: "inherit",
                          }}
                        >
                          {this.state.detailSurat
                            ? this.state.detailSurat.id_desa
                                .toString()
                                .substr(0, 2) +
                              " - " +
                              this.state.detailSurat.data_kelurahan
                                .data_kecamatan.data_kabkota.data_provinsi.nama
                            : ""}
                        </h6>
                      </div>
                    </FormGroup>
                    <FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1"
                        style={{ fontWeight: 600 }}
                      >
                        Kabupaten / Kota
                      </Label>
                      <div
                        className="col-sm-8"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6
                          style={{
                            marginBottom: "0px",
                            fontWeight: 400,
                            fontSize: "inherit",
                          }}
                        >
                          {this.state.detailSurat
                            ? this.state.detailSurat.id_desa
                                .toString()
                                .substr(2, 2) +
                              " - " +
                              this.state.detailSurat.data_kelurahan
                                .data_kecamatan.data_kabkota.nama
                            : ""}
                        </h6>
                      </div>
                    </FormGroup>
                    <FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1"
                        style={{ fontWeight: 600 }}
                      >
                        Kecamatan
                      </Label>
                      <div
                        className="col-sm-8"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6
                          style={{
                            marginBottom: "0px",
                            fontWeight: 400,
                            fontSize: "inherit",
                          }}
                        >
                          {this.state.detailSurat
                            ? this.state.detailSurat.id_desa
                                .toString()
                                .substr(4, 2) +
                              " - " +
                              this.state.detailSurat.data_kelurahan
                                .data_kecamatan.nama
                            : ""}
                        </h6>
                      </div>
                    </FormGroup>
                    <FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1"
                        style={{ fontWeight: 600 }}
                      >
                        Desa / Kelurahan
                      </Label>
                      <div
                        className="col-sm-8"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6
                          style={{
                            marginBottom: "0px",
                            fontWeight: 400,
                            fontSize: "inherit",
                          }}
                        >
                          {this.state.detailSurat
                            ? this.state.detailSurat.id_desa
                                .toString()
                                .substr(6, 4) +
                              " - " +
                              this.state.detailSurat.data_kelurahan.nama
                            : ""}
                        </h6>
                      </div>
                    </FormGroup>
                    <FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1"
                        style={{ fontWeight: 600 }}
                      >
                        Dusun / Dukuh / Kampung
                      </Label>
                      <div
                        className="col-sm-8"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6
                          style={{
                            marginBottom: "0px",
                            fontWeight: 400,
                            fontSize: "inherit",
                          }}
                        >
                          {
                            this.state.dataPelayanan.data_daerah_asal
                              .jenis_desa_asal
                          }
                        </h6>
                      </div>
                    </FormGroup>
                    </div>

                    <FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1 pt-3"
                        style={{ fontWeight: 1000 }}
                      >
                        <h6 style={{ margin: "0px" }}>
                          <b>Data Daerah Asal</b>
                        </h6>
                      </Label>
                    </FormGroup>
                    <div
                      style={{
                        padding: "10px",
                        border: "1px solid black",
                        borderRadius: "15px",
                      }}
                    >
                      <FormGroup
                        className="row"
                        style={{ marginBottom: "0px" }}
                      >
                        <Label
                          className="col-sm-4 col-form-label pb-1 pt-0"
                          style={{ fontWeight: 600 }}
                        >
                          Nomor Kartu Keluarga
                        </Label>
                        <div
                          className="col-sm-8"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <h6
                            style={{
                              marginBottom: "0px",
                              fontWeight: 400,
                              fontSize: "inherit",
                            }}
                          >
                            {
                              this.state.dataPelayanan.data_daerah_asal
                                .no_kk_asal
                            }
                          </h6>
                        </div>
                      </FormGroup>
                      <FormGroup
                        className="row"
                        style={{ marginBottom: "0px" }}
                      >
                        <Label
                          className="col-sm-4 col-form-label pb-1"
                          style={{ fontWeight: 600 }}
                        >
                          Nama Kepala Keluarga
                        </Label>
                        <div
                          className="col-sm-8"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <h6
                            style={{
                              marginBottom: "0px",
                              fontWeight: 400,
                              fontSize: "inherit",
                            }}
                          >
                            {
                              this.state.dataPelayanan.data_daerah_asal
                                .nama_kepala_keluarga_asal
                            }
                          </h6>
                        </div>
                      </FormGroup>
                      <FormGroup
                        className="row"
                        style={{ marginBottom: "0px" }}
                      >
                        <Label
                          className="col-sm-4 col-form-label pb-1"
                          style={{ fontWeight: 600 }}
                        >
                          Alamat
                        </Label>
                        <div
                          className="col-sm-8"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <h6
                            style={{
                              marginBottom: "0px",
                              fontWeight: 400,
                              fontSize: "inherit",
                            }}
                          >
                            {
                              this.state.dataPelayanan.data_daerah_asal
                                .alamat_asal
                            }
                          </h6>
                        </div>
                      </FormGroup>
                      <FormGroup
                        className="row"
                        style={{ marginBottom: "0px" }}
                      >
                        <Label
                          className="col-sm-4 col-form-label pb-1"
                          style={{ fontWeight: 600 }}
                        >
                          RT/RW
                        </Label>
                        <div
                          className="col-sm-8"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <h6
                            style={{
                              marginBottom: "0px",
                              fontWeight: 400,
                              fontSize: "inherit",
                            }}
                          >
                            {this.state.dataPelayanan.data_daerah_asal.rt_asal}{" "}
                            /{" "}
                            {this.state.dataPelayanan.data_daerah_asal.rw_asal}
                          </h6>
                        </div>
                      </FormGroup>
                      <FormGroup
                        className="row"
                        style={{ marginBottom: "0px" }}
                      >
                        <Label
                          className="col-sm-4 col-form-label pb-1"
                          style={{ fontWeight: 600 }}
                        >
                          Dusun/Dukuh/Kampung
                        </Label>
                        <div
                          className="col-sm-8"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <h6
                            style={{
                              marginBottom: "0px",
                              fontWeight: 400,
                              fontSize: "inherit",
                            }}
                          >
                            {
                              this.state.dataPelayanan.data_daerah_asal
                                .jenis_desa_asal
                            }
                          </h6>
                        </div>
                      </FormGroup>
                      <FormGroup
                        className="row"
                        style={{ marginBottom: "0px", paddingLeft: "1.5rem" }}
                      >
                        <Label
                          className="col-sm-4 col-form-label pb-1"
                          style={{ fontWeight: 600 }}
                        >
                          Desa/Kelurahan
                        </Label>
                        <div
                          className="col-sm-8"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <h6
                            style={{
                              marginBottom: "0px",
                              fontWeight: 400,
                              fontSize: "inherit",
                            }}
                          >
                            {
                              this.state.dataPelayanan.data_daerah_asal
                                .deskel_asal
                            }
                          </h6>
                        </div>
                      </FormGroup>
                      <FormGroup
                        className="row"
                        style={{ marginBottom: "0px", paddingLeft: "1.5rem" }}
                      >
                        <Label
                          className="col-sm-4 col-form-label pb-1"
                          style={{ fontWeight: 600 }}
                        >
                          Kecamatan
                        </Label>
                        <div
                          className="col-sm-8"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <h6
                            style={{
                              marginBottom: "0px",
                              fontWeight: 400,
                              fontSize: "inherit",
                            }}
                          >
                            {
                              this.state.dataPelayanan.data_daerah_asal
                                .kecamatan_asal
                            }
                          </h6>
                        </div>
                      </FormGroup>
                      <FormGroup
                        className="row"
                        style={{ marginBottom: "0px", paddingLeft: "1.5rem" }}
                      >
                        <Label
                          className="col-sm-4 col-form-label pb-1"
                          style={{ fontWeight: 600 }}
                        >
                          Kab/Kota
                        </Label>
                        <div
                          className="col-sm-8"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <h6
                            style={{
                              marginBottom: "0px",
                              fontWeight: 400,
                              fontSize: "inherit",
                            }}
                          >
                            {
                              this.state.dataPelayanan.data_daerah_asal
                                .kabkota_asal
                            }
                          </h6>
                        </div>
                      </FormGroup>
                      <FormGroup
                        className="row"
                        style={{ marginBottom: "0px", paddingLeft: "1.5rem" }}
                      >
                        <Label
                          className="col-sm-4 col-form-label pb-1"
                          style={{ fontWeight: 600 }}
                        >
                          Provinsi
                        </Label>
                        <div
                          className="col-sm-8"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <h6
                            style={{
                              marginBottom: "0px",
                              fontWeight: 400,
                              fontSize: "inherit",
                            }}
                          >
                            {
                              this.state.dataPelayanan.data_daerah_asal
                                .provinsi_asal
                            }
                          </h6>
                        </div>
                      </FormGroup>
                      <FormGroup
                        className="row"
                        style={{ marginBottom: "0px" }}
                      >
                        <Label
                          className="col-sm-4 col-form-label pb-1"
                          style={{ fontWeight: 600 }}
                        >
                          Kode Pos
                        </Label>
                        <div
                          className="col-sm-8"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <h6
                            style={{
                              marginBottom: "0px",
                              fontWeight: 400,
                              fontSize: "inherit",
                            }}
                          >
                            {
                              this.state.dataPelayanan.data_daerah_asal
                                .kode_pos_asal
                            }
                          </h6>
                        </div>
                      </FormGroup>
                      <FormGroup
                        className="row"
                        style={{ marginBottom: "0px" }}
                      >
                        <Label
                          className="col-sm-4 col-form-label pb-1"
                          style={{ fontWeight: 600 }}
                        >
                          Telepon
                        </Label>
                        <div
                          className="col-sm-8"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <h6
                            style={{
                              marginBottom: "0px",
                              fontWeight: 400,
                              fontSize: "inherit",
                            }}
                          >
                            {this.state.detailSurat.no_hp_pelapor}
                          </h6>
                        </div>
                      </FormGroup>
                      <FormGroup
                        className="row"
                        style={{ marginBottom: "0px" }}
                      >
                        <Label
                          className="col-sm-4 col-form-label pb-1"
                          style={{ fontWeight: 600 }}
                        >
                          NIK Pemohon
                        </Label>
                        <div
                          className="col-sm-8"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <h6
                            style={{
                              marginBottom: "0px",
                              fontWeight: 400,
                              fontSize: "inherit",
                            }}
                          >
                            {this.state.detailSurat.nik_pelapor}
                          </h6>
                        </div>
                      </FormGroup>
                      <FormGroup
                        className="row"
                        style={{ marginBottom: "0px" }}
                      >
                        <Label
                          className="col-sm-4 col-form-label pb-1"
                          style={{ fontWeight: 600 }}
                        >
                          Nama Lengkap
                        </Label>
                        <div
                          className="col-sm-8"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <h6
                            style={{
                              marginBottom: "0px",
                              fontWeight: 400,
                              fontSize: "inherit",
                            }}
                          >
                            {this.state.detailSurat.nama_pelapor}
                          </h6>
                        </div>
                      </FormGroup>
                    </div>
                    <FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1 pt-3"
                        style={{ fontWeight: 1000 }}
                      >
                        <h6 style={{ margin: "0px" }}>
                          <b>Data Daerah Tujuan</b>
                        </h6>
                      </Label>
                    </FormGroup>
                    <div
                      style={{
                        padding: "10px",
                        border: "1px solid black",
                        borderRadius: "15px",
                      }}
                    >
                      <FormGroup
                        className="row"
                        style={{ marginBottom: "0px" }}
                      >
                        <Label
                          className="col-sm-4 col-form-label pb-1 pt-0"
                          style={{ fontWeight: 600 }}
                        >
                          Status Nomor KK Bagi Yang Pindah
                        </Label>
                        <div
                          className="col-sm-8"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <h6
                            style={{
                              marginBottom: "0px",
                              fontWeight: 400,
                              fontSize: "inherit",
                            }}
                          >
                            {
                              this.state.dataPelayanan.data_daerah_tujuan
                                .status_no_kk_tujuan
                            }
                          </h6>
                        </div>
                      </FormGroup>
                      <FormGroup
                        className="row"
                        style={{ marginBottom: "0px" }}
                      >
                        <Label
                          className="col-sm-4 col-form-label pb-1"
                          style={{ fontWeight: 600 }}
                        >
                          Nomor Kartu Keluarga
                        </Label>
                        <div
                          className="col-sm-8"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <h6
                            style={{
                              marginBottom: "0px",
                              fontWeight: 400,
                              fontSize: "inherit",
                            }}
                          >
                            {
                              this.state.dataPelayanan.data_daerah_tujuan
                                .no_kk_tujuan
                            }
                          </h6>
                        </div>
                      </FormGroup>
                      <FormGroup
                        className="row"
                        style={{ marginBottom: "0px" }}
                      >
                        <Label
                          className="col-sm-4 col-form-label pb-1"
                          style={{ fontWeight: 600 }}
                        >
                          NIK Kepala Keluarga
                        </Label>
                        <div
                          className="col-sm-8"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <h6
                            style={{
                              marginBottom: "0px",
                              fontWeight: 400,
                              fontSize: "inherit",
                            }}
                          >
                            {
                              this.state.dataPelayanan.data_daerah_tujuan
                                .nik_kepala_keluarga_tujuan
                            }
                          </h6>
                        </div>
                      </FormGroup>
                      <FormGroup
                        className="row"
                        style={{ marginBottom: "0px" }}
                      >
                        <Label
                          className="col-sm-4 col-form-label pb-1"
                          style={{ fontWeight: 600 }}
                        >
                          Nama Kepala Keluarga
                        </Label>
                        <div
                          className="col-sm-8"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <h6
                            style={{
                              marginBottom: "0px",
                              fontWeight: 400,
                              fontSize: "inherit",
                            }}
                          >
                            {
                              this.state.dataPelayanan.data_daerah_tujuan
                                .nama_kepala_keluarga_tujuan
                            }
                          </h6>
                        </div>
                      </FormGroup>
                      <FormGroup
                        className="row"
                        style={{ marginBottom: "0px" }}
                      >
                        <Label
                          className="col-sm-4 col-form-label pb-1"
                          style={{ fontWeight: 600 }}
                        >
                          Tanggal Kedatangan
                        </Label>
                        <div
                          className="col-sm-8"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <h6
                            style={{
                              marginBottom: "0px",
                              fontWeight: 400,
                              fontSize: "inherit",
                            }}
                          >
                            {
                              this.state.dataPelayanan.data_daerah_tujuan
                                .tgl_kedatangan
                            }
                          </h6>
                        </div>
                      </FormGroup>
                      <FormGroup
                        className="row"
                        style={{ marginBottom: "0px" }}
                      >
                        <Label
                          className="col-sm-4 col-form-label pb-1"
                          style={{ fontWeight: 600 }}
                        >
                          Alamat
                        </Label>
                        <div
                          className="col-sm-8"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <h6
                            style={{
                              marginBottom: "0px",
                              fontWeight: 400,
                              fontSize: "inherit",
                            }}
                          >
                            {
                              this.state.dataPelayanan.data_daerah_tujuan
                                .alamat_tujuan
                            }
                          </h6>
                        </div>
                      </FormGroup>
                      <FormGroup
                        className="row"
                        style={{ marginBottom: "0px" }}
                      >
                        <Label
                          className="col-sm-4 col-form-label pb-1"
                          style={{ fontWeight: 600 }}
                        >
                          RT/RW
                        </Label>
                        <div
                          className="col-sm-8"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <h6
                            style={{
                              marginBottom: "0px",
                              fontWeight: 400,
                              fontSize: "inherit",
                            }}
                          >
                            {
                              this.state.dataPelayanan.data_daerah_tujuan
                                .rt_tujuan
                            }{" "}
                            /{" "}
                            {
                              this.state.dataPelayanan.data_daerah_tujuan
                                .rw_tujuan
                            }
                          </h6>
                        </div>
                      </FormGroup>
                      <FormGroup
                        className="row"
                        style={{ marginBottom: "0px" }}
                      >
                        <Label
                          className="col-sm-4 col-form-label pb-1"
                          style={{ fontWeight: 600 }}
                        >
                          Dusun/Dukuh/Kampung
                        </Label>
                        <div
                          className="col-sm-8"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <h6
                            style={{
                              marginBottom: "0px",
                              fontWeight: 400,
                              fontSize: "inherit",
                            }}
                          >
                            {
                              this.state.dataPelayanan.data_daerah_tujuan
                                .jenis_desa_tujuan
                            }
                          </h6>
                        </div>
                      </FormGroup>
                      <FormGroup
                        className="row"
                        style={{ marginBottom: "0px", paddingLeft: "1.5rem" }}
                      >
                        <Label
                          className="col-sm-4 col-form-label pb-1"
                          style={{ fontWeight: 600 }}
                        >
                          Desa/Kelurahan
                        </Label>
                        <div
                          className="col-sm-8"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <h6
                            style={{
                              marginBottom: "0px",
                              fontWeight: 400,
                              fontSize: "inherit",
                            }}
                          >
                            {
                              this.state.dataPelayanan.data_daerah_tujuan
                                .deskel_tujuan
                            }
                          </h6>
                        </div>
                      </FormGroup>
                      <FormGroup
                        className="row"
                        style={{ marginBottom: "0px", paddingLeft: "1.5rem" }}
                      >
                        <Label
                          className="col-sm-4 col-form-label pb-1"
                          style={{ fontWeight: 600 }}
                        >
                          Kecamatan
                        </Label>
                        <div
                          className="col-sm-8"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <h6
                            style={{
                              marginBottom: "0px",
                              fontWeight: 400,
                              fontSize: "inherit",
                            }}
                          >
                            {
                              this.state.dataPelayanan.data_daerah_tujuan
                                .kecamatan_tujuan
                            }
                          </h6>
                        </div>
                      </FormGroup>
                      <FormGroup
                        className="row"
                        style={{ marginBottom: "0px", paddingLeft: "1.5rem" }}
                      >
                        <Label
                          className="col-sm-4 col-form-label pb-1"
                          style={{ fontWeight: 600 }}
                        >
                          Kab/Kota
                        </Label>
                        <div
                          className="col-sm-8"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <h6
                            style={{
                              marginBottom: "0px",
                              fontWeight: 400,
                              fontSize: "inherit",
                            }}
                          >
                            {
                              this.state.dataPelayanan.data_daerah_tujuan
                                .kabkota_tujuan
                            }
                          </h6>
                        </div>
                      </FormGroup>
                      <FormGroup
                        className="row"
                        style={{ marginBottom: "0px", paddingLeft: "1.5rem" }}
                      >
                        <Label
                          className="col-sm-4 col-form-label pb-1"
                          style={{ fontWeight: 600 }}
                        >
                          Provinsi
                        </Label>
                        <div
                          className="col-sm-8"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <h6
                            style={{
                              marginBottom: "0px",
                              fontWeight: 400,
                              fontSize: "inherit",
                            }}
                          >
                            {
                              this.state.dataPelayanan.data_daerah_tujuan
                                .provinsi_tujuan
                            }
                          </h6>
                        </div>
                      </FormGroup>
                    </div>
                    <FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-5 col-form-label mt-2"
                        style={{ fontWeight: 1000 }}
                      >
                        <h6 style={{ margin: "0px" }}>
                          <b>Keluarga Yang Datang :</b>
                        </h6>
                      </Label>
                    </FormGroup>

                    <FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <div className="col-sm-12" style={{ overflowX: "auto" }}>
                        <Table className="table-custom" striped>
                          <thead>
                            <tr>
                              <th>No</th>
                              <th>NIK</th>
                              <th>Nama</th>
                              <th>Masa Berlaku KTP s/d</th>
                              <th>SHDK</th>
                            </tr>
                          </thead>
                          <tbody>
                            {keluarga_datang.data_daerah_tujuan
                              .keluarga_yang_datang &&
                              keluarga_datang.data_daerah_tujuan.keluarga_yang_datang.map(
                                (kel, index) => (
                                  <tr>
                                    <td scope="row">{index + 1}</td>
                                    <td>{kel.nik_keluarga}</td>
                                    <td>{kel.nama_keluarga}</td>
                                    <td>{kel.expired_ktp}</td>
                                    <td>{kel.shdk}</td>
                                  </tr>
                                )
                              )}
                          </tbody>
                        </Table>
                      </div>
                    </FormGroup>
                    <hr />
                    <FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-3 col-form-label pt-0"
                        style={{ fontWeight: 600 }}
                      >
                        <h5>Persyaratan</h5>
                      </Label>
                      <div
                        className="col-sm-9"
                        style={{ display: "flex", alignItems: "center" }}
                      />
                    </FormGroup>
                    <FormGroup className="row">
                      {/* Looping foto persyaratan */}
                      {Object.keys(
                        JSON.parse(this.state.detailSurat.dokumen_input)
                      ).map((val, index) => {
                        let dokumen_input = JSON.parse(
                          this.state.detailSurat.dokumen_input
                        );
                        let nama_syarat = "Foto Persyaratan";

                        return (
                          <div className="col-sm-12">
                            <h6
                              style={{
                                textTransform: "capitalize",
                                marginBottom: "1rem",
                              }}
                            >
                              {val}
                            </h6>
                            <div className="row">
                              {Object.keys(dokumen_input[val]).map(
                                (syarat, index3) => {
                                  switch (syarat) {
                                    case "skpwni":
                                      nama_syarat = "SKPWNI";
                                      break;

                                    case "f1_03":
                                      nama_syarat = "Blank F1 03";
                                      break;

                                    case "f1_01_desa_tujuan":
                                      nama_syarat = "Blanko F1 01 Desa Tujuan";
                                      break;

                                    case "akta_nikah":
                                      nama_syarat = "Akta Nikah";
                                      break;

                                    case "kk_asli":
                                      nama_syarat = "Kartu Keluarga Asli";
                                      break;

                                    case "pas_poto":
                                      nama_syarat = "Pas Foto";
                                      break;

                                    case "surat_menikah":
                                      nama_syarat = "Surat Menikah";
                                      break;

                                    case "surat_pengantar":
                                      nama_syarat = "Surat Pengantar";
                                      break;

                                    default:
                                      break;
                                  }

                                  return (
                                    <div
                                      className="col-md-4"
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                      }}
                                    >
                                      {dokumen_input[val][syarat] != '' ? (
                                        <img
                                          src={
                                            dokumen_input[val][syarat] != ""
                                              ? dokumen_input[val][syarat]
                                              : require("../../assets/images/calender-bg.png")
                                          }
                                          style={{
                                            width: "200px",
                                            height: "200px", 
                                            objectFit: "cover",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            let status = { ...this.state.status };
                                            status.fotoPersyaratan = true;
                                            this.setState({
                                              status,
                                              fotoPersyaratan:
                                                dokumen_input[val][syarat],
                                              titlePersyaratan: nama_syarat,
                                            });
                                          }}
                                        />
                                      ) : (
                                        <div style={{ height: "200px", width: "200px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                          <p style={{ textAlign: "center", fontWeight: 600 }}>Tidak ada foto persyaratan</p>
                                        </div>
                                      )}
                                      <br/>
                                      <p style={{ textAlign: "center" }}><b>{nama_syarat}</b></p>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </FormGroup>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-info"
                  // disabled={this.state.status.btnForm}
                  onClick={() => {
                    this.tutupForm();
                  }}
                  style={{ fontWeight: 600 }}
                >
                  Tutup
                </button>
              </div>
            </Form>
          </Modal>

          <SweetAlert
						show={this.state.loadingPreview}
						showCancel = {false}
						showConfirm={false}
					>
						<div align="center">
							<h5 className="modal-title" style={{ textAlign: "center", color: 'black' }}>Sedang Dalam Proses</h5>
							<br/>
							<img 
								alt="loading"
								src={require("../../assets/images/loading-preview.gif")} 
								style={{
									// width: "100%",
									borderRadius: "10px",
									width: '150px'
								}}
							/> 
							<br/><br/>
						</div>
					</SweetAlert>
        </div>
      </div>
    );
  }
}

export default PindahDatang;
