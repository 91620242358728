import React, { Component } from "react";
import Modal from "react-responsive-modal";
import BootstrapTable from "react-bootstrap-table-next";
import { Link } from "react-router-dom";
import { Button, CardImg, Form, FormGroup, Input, Label, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import SweetAlert from "react-bootstrap-sweetalert";
import classnames from "classnames";

const listFile = {
  akta_kematian: '',
  kartu_keluarga: '',
}

class ComingSoonLayanan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      dataDiproses: [],
			dataSelesai: [],
			dataDitolak: [],
			dataDokumenOutput: [],

      detailSurat: null,
      dataPelayanan: null,
			dataPelapor: null,
      fotoPersyaratan: null,
      titlePersyaratan: '',
      linkGambar: listFile,
			active_tab_icon: "1",
      pagination: [],
      status: {
        alert: false,
        modalValidasi: false,
        modalUpload: false,
        modalDetail: false,
        tolakValidasi: false,
        fotoPersyaratan: false,
        btnForm: false,
        btnAksi: false,
      },
      awal: "",
      sebelum: "",
      hal: [],
      setelah: "",
      akhir: "",
      // dt:frmDef,
      // ============== Select 2 ==============
      frmTingkat: [],
      tingkatStat: [],
      frmDetail: [],
      detailStat: [],
      judul: "Tambah Jabatan Perangkat",
      alert: null,
      show: false,
      basicTitle: "",
      basicType: "default",
      pesanAlert: "",
    };
  }

  componentDidMount() {

  }

  render() {
    return (
      <div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card mb-0">
                <div className="card-body datatable-react">
                  <div className="row">
                      <div className="col-sm-4">
                        &nbsp;
                      </div>
                      <div className="col-sm-8 text-right">
                        <button onClick={this.props.history.goBack} color="primary" className="btn btn-secondary"><i className="fa fa-arrow-left"></i> Kembali</button>
                      </div>
									</div>
                  <h2 align="center">Segera Hadir</h2>
                  <h6><p align="center">Wah, fitur yang kamu inginkan akan segera hadir ! <br/> Tunggu update selanjutnya ya !</p></h6>
                  <br/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
      </div>
    );
  }
}

export default ComingSoonLayanan;
