import React, { Component } from 'react';
import Modal from 'react-responsive-modal';
import BootstrapTable from 'react-bootstrap-table-next';
import { Link } from 'react-router-dom';
import { Button, CardImg, Form, FormGroup, Input, Label, Nav, NavItem, NavLink, TabContent, Table, TabPane } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Get, Post } from '../../function/Koneksi';
import classnames from 'classnames';

const listFile = {
  kartu_keluarga: '',
}

class KartuKeluarga extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			dataDiproses: [],
			dataSelesai: [],
			dataDitolak: [],
			dataDokumenOutput: [],

			detailSurat: null,
			dataPelayanan: null,
			fotoPersyaratan: null,
			titlePersyaratan: '',
			jenisPerubahan: '',
			linkGambar: '',
			// Tab Pane
			active_tab_icon: "1",
			cek_posisi:'Pertama',
			pagination: [],
			paginationDiproses: [],
			paginationSelesai: [],
			paginationDitolak: [],
			pagination: [],
			status: {
				alert: false,
				modalValidasi: false,
				modalUpload: false,
				modalDetail: false,
				tolakValidasi: false,
				fotoPersyaratan: false,
				btnForm: false,
				btnAksi: false
			},
			
			// Diajukan
			awal: "",
			sebelum: "",
			hal: [],
			setelah: "",
			akhir: "",
			// Diproses
			awal2: "",
			sebelum2: "",
			hal2: [],
			setelah2: "",
			akhir2: "",
			
			// Selesai
			awal3: "",
			sebelum3: "",
			hal3: [],
			setelah3: "",
			akhir3: "",

			// Ditolak
			awal4: "",
			sebelum4: "",
			hal4: [],
			setelah4: "",
			akhir4: "",
			// dt:frmDef,
			// ============== Select 2 ==============
			frmTingkat: [],
			tingkatStat: [],
			frmDetail: [],
			detailStat: [],
			judul: 'Tambah Jabatan Perangkat',
			alert: null,
			show: false,
			basicTitle: '',
			basicType: 'default',
			pesanAlert: '',
			
			id_jenis_pelayanan: this.props.location.state.uuid,
			loadingPreview: false,
		};
	}

	fetch = (params = {}) => {
		let link = "";
		// let where = [];
		// if (params.where) {
		//   where = params.where;
		// }
		if (params.page) {
		  switch (params.page) {
			case ">>":
			  switch (params.tab) {
				case "Diajukan":
				  link =
					"&page=" +
					Math.ceil(
					  this.state.pagination.total / this.state.pagination.per_page
					);
				  break;
	
				case "Diproses":
				  link =
					"&page=" +
					Math.ceil(
					  this.state.paginationDiproses.total /
					  this.state.paginationDiproses.per_page
					);
				  break;
	
				case "Ditolak":
				  link =
					"&page=" +
					Math.ceil(
					  this.state.paginationDitolak.total /
					  this.state.paginationDitolak.per_page
					);
				  break;
	
			  	case "Selesai":
				  link =
					"&page=" +
					Math.ceil(
					  this.state.paginationSelesai.total /
					  this.state.paginationSelesai.per_page
					);
				  break;
				default:
				  // no default
				  break;
			  }
			  break;
			case ">":
			  switch (params.tab) {
				case "Diajukan":
				  link =
					"&page=" + (parseInt(this.state.pagination.current_page) + 1);
				  break;
	
				case "Diproses":
				  link =
					"&page=" +
					(parseInt(this.state.paginationDiproses.current_page) +
					  1);
				  break;
	
				case "Ditolak":
				  link =
					"&page=" +
					(parseInt(this.state.paginationDitolak.current_page) + 1);
				  break;

				case "Selesai":
				link =
				  "&page=" +
				  (parseInt(this.state.paginationSelesai.current_page) + 1);
				break;
				default:
				  // no default
				  break;
	
			  }
			  break;
			case "<":
			  switch (params.tab) {
				case "Diajukan":
				  link =
					"&page=" + (parseInt(this.state.pagination.current_page) - 1);
				  break;
	
				case "Diproses":
				  link =
					"&page=" +
					(parseInt(this.state.paginationDiproses.current_page) -
					  1);
				  break;
	
				case "Ditolak":
				  link =
					"&page=" +
					(parseInt(this.state.paginationDitolak.current_page) - 1);
				  break;
	
				case "Selesai":
				  link =
					"&page=" +
					(parseInt(this.state.paginationSelesai.current_page) - 1);
				  break;
				default:
				  // no default
				  break;
			  }
			  break;
			case "<<":
			  link = "&page=1";
			  break;
			default:
			  link = "&page=" + params.page;
			  break;
		  }
		}
	
		// Get data from db
		if(this.state.cek_posisi === "Pertama"){
		  params.tab = "Diajukan"
		}
		else if(this.state.cek_posisi === "Kedua"){
		  params.tab = "Diproses"
		}
		else if(this.state.cek_posisi === "Ketiga"){
		  params.tab = "Selesai"
		}
		else if(this.state.cek_posisi === "Keempat"){
		  params.tab = "Ditolak"
		}
		
		Get(`pelayanan?uuid_pelayanan_instansi=${this.state.id_jenis_pelayanan}&status=` + params.tab + link, null, (dtkat) => {
		  //console.log("COba tampil bos")
		  //console.log(dtkat)
		  switch (params.tab) {
			case "Diajukan":
			  this.setState({
				data: dtkat.results.data,
				pagination: dtkat.results
			  });
			  break;
	
			case "Diproses":
			  this.setState({
				dataDiproses: dtkat.results.data,
				paginationDiproses: dtkat.results
			  });
			  break;

			case "Ditolak":
			  this.setState({
				dataDitolak: dtkat.results.data,
				paginationDitolak: dtkat.results
			  });
			  break;

			case "Selesai":
			  this.setState({
				dataSelesai: dtkat.results.data,
				paginationSelesai: dtkat.results
			  });
			  break;
			default:
			  // no default
			  break;
		  }
		  // //console.log(dtkat.results);
		  this.pagination(dtkat.results, params.tab);
		});
	};
	
	// Pagination
	pagination = (data, tab) => {
		// const data = this.state.pagination;
		let awal = "";
		let hal = [];
		let sebelum = "";
		let setelah = "";
		let akhir = "";
		if (data.total > 0) {
		  let start = 1;
		  let end = 5;
		  let n = 0;
		  let p = 0;
		  if (data.current_page <= 3) {
			start = 1;
			end = 5;
			if (data.last_page > data.current_page) {
			  n = data.current_page + 1;
			}
		  } else {
			p = data.current_page - 1;
			n = data.current_page + 1;
			start = data.current_page - 2;
			end = data.current_page + 2;
		  }
		  if (end >= data.last_page - 2) {
			p = data.current_page - 1;
			if (start >= 5) {
			  start = data.last_page - 4;
			}
			end = data.last_page;
		  }
	
		  for (let i = start; i <= end; i++) {
			let warna = "success";
			if (i === data.current_page) {
			  warna = "danger";
			}
			hal.push(
			  <a
				href={() => false}
				key={i}
				onClick={() => this.fetch({ page: i, tab: tab })}
				className={
				  "btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
				}
			  >
				{i}
			  </a>
			);
		  }
		  if (p > 0) {
			sebelum = (
			  <a
				href={() => false}
				onClick={() => this.fetch({ page: p, tab: tab })}
				className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
			  >
				{"<"}
			  </a>
			);
		  }
		  if (n > 0) {
			setelah = (
			  <a
				href={() => false}
				onClick={() => this.fetch({ page: n, tab: tab })}
				className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
			  >
				{">"}
			  </a>
			);
		  }
	
		  awal = (
			<a
			  href={() => false}
			  onClick={() => this.fetch({ page: 1, tab: tab })}
			  className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
			>
			  {"<<"}
			</a>
		  );
		  akhir = (
			<a
			  href={() => false}
			  onClick={() => this.fetch({ page: data.last_page, tab: tab })}
			  className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
			>
			  {">>"}
			</a>
		  );
		}
	
		switch (tab) {
		  case "Diajukan":
			this.setState({ awal, sebelum, hal, setelah, akhir });
			break;
		  case "Diproses":
			this.setState({
			  awal2: awal,
			  sebelum2: sebelum,
			  hal2: hal,
			  setelah2: setelah,
			  akhir2: akhir
			});
			break;

		  case "Selesai":
			this.setState({
			  awal3: awal,
			  sebelum3: sebelum,
			  hal3: hal,
			  setelah3: setelah,
			  akhir3: akhir
			});
			break;

		  case "Ditolak":
			this.setState({
			  awal4: awal,
			  sebelum4: sebelum,
			  hal4: hal,
			  setelah4: setelah,
			  akhir4: akhir
			});
			break;
		  default:
			// no default
			break;
		}
	};

	componentDidMount() {
		
		let tabnya = ""
		if(this.state.cek_posisi==="Pertama"){
			tabnya="Diajukan"
			this.setState({
				active_tab_icon: '1',
			})
		}
		else if(this.state.cek_posisi==="Kedua"){
			tabnya="Diproses"
			this.setState({
				active_tab_icon: '2',
			})
		}

		else if(this.state.cek_posisi==="Ketiga"){
			tabnya="Selesai"
			this.setState({
				active_tab_icon: '3',
			})
		}
		
		else if(this.state.cek_posisi==="Keempat"){
			tabnya="Ditolak"
			this.setState({
				active_tab_icon: '4',
			})
		}
		
		this.fetch({ page: null, where: [], tab: tabnya });
	}

	tombolAksi = (cell, value) => {

		const btnDetail = (
      <Button
				size="sm"
				color="info"
				className="btn-square"
				onClick={() => {
					let status = { ...this.state.status };
					status.modalDetail = true;

					let dataPelayanan = JSON.parse(value.data_pelayanan);

					this.setState({
						status,
						detailSurat: value,
						dataPelayanan,
						jenisPerubahan: dataPelayanan.jenis_perubahan,
						dataDokumenOutput: JSON.parse(value.dokumen_output)
					});
					// console.log(value);
					// console.log(dataPelayanan);
				}}
				style={{ padding: '0.25rem 0.5rem', borderRadius: '25px' }}
			>
				{/* <i className="fa fa-eye"></i> */}
				Detail
			</Button>
    )

		if (value.data_status.length > 0) {
			if (value.data_status_baru.status == 'Diajukan') {
				return (
					<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
						{/* {btnDetail} */}
						<Button
							size="sm"
							color="success"
							className="btn-square"
							onClick={() => {
								// Tampilkan modal validasi
								let status = { ...this.state.status };
								status.modalValidasi = true;

								let dataPelayanan = JSON.parse(value.data_pelayanan);

								this.setState({
									status,
									detailSurat: value,
									dataPelayanan,
									jenisPerubahan: dataPelayanan.jenis_perubahan,
									dataDokumenOutput: JSON.parse(value.dokumen_output)
								});
								// console.log(JSON.parse(value.data_pelayanan));
							}}
							style={{ borderRadius: '25px' }}
						>
							<b>Validasi</b>
						</Button>
					</div>
				);
			} else if (value.data_status_baru.status == 'Diproses') {
				return (
					<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
						{btnDetail}
						<Button
							size="sm"
							color="success"
							className="btn-square"
							onClick={() => {
								let status = { ...this.state.status };
								status.modalUpload = true;
								this.setState({ 
									status, 
									detailSurat: value,
									linkGambar:listFile,
								});
							}}
							style={{ padding: '0.25rem 0.5rem', borderRadius: '25px' }}
						>
							Upload
						</Button>
					</div>
				);
			} else if (value.data_status_baru.status == 'Selesai') {
				return (
					<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
						{btnDetail}
					</div>
				);
			} else if (value.data_status_baru.status == "Ditolak") {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
            }}
          >
            {btnDetail}
          </div>
        );
      }
		} else {
			return '';
		}
	};

	tutupForm() {
		let status = { ...this.state.status };
		status.modalValidasi = false;
		status.tolakValidasi = false;
		status.modalUpload = false;
		status.modalDetail = false;
		status.fotoPersyaratan = false;

		this.setState({
			status,
			alert: null,
			detailSurat: null,
			dataPelayanan: null,
			jenisPerubahan: '',
			dataDokumenOutput: null,
			linkGambar: listFile,
		});
	}

	hideAlert = () => {
		let status = { ...this.state.status };
		status.alert = false;
		this.setState({ status });
	};

	updateSurat = (uuid_surat) => {
		this.setState({
			alert: (
				<SweetAlert
					showCancel
					confirmBtnText="Ya, saya yakin"
					confirmBtnBsStyle="warning"
					confirmBtnStyle={{ fontWeight: 600 }}
					cancelBtnText="Batal"
					cancelBtnBsStyle="default"
					cancelBtnStyle={{ fontWeight: 600 }}
					type="warning"
					title="Apakah anda yakin syarat yang diberikan telah sesuai ?"
					onCancel={() => {
						this.setState({ alert: null });
					}}
					onConfirm={() => {
						if (this.state.detailSurat) {
							this.setState({
								loadingPreview: true,
							})

							let bodyFormData = {
								status: 'Diproses'
							};
							Post('pelayanan/verifikasi-status', this.state.detailSurat.uuid, bodyFormData, (data) => {
								// console.log(data);
								if (data.status == 200) {
									let status = { ...this.state.status };
									status.alert = true;
									this.setState({
										status,
										basicType: 'success',
										basicTitle: 'Validasi Pengajuan Pelayanan',
										pesanAlert: 'Berhasil validasi data',
										loadingPreview: false,
									});
									this.componentDidMount(this.state.active_tab_icon);
								} else {
									let status = { ...this.state.status };
									status.alert = true;
									this.setState({
										status,
										basicType: 'danger',
										basicTitle: 'Validasi Pengajuan Pelayanan',
										pesanAlert: 'Gagal validasi data',
										loadingPreview: false,
									});
								}
								this.tutupForm();
							});
						}
					}}
				>
					Proses ini tidak dapat dibatalkan, pastikan sudah memeriksa seluruh persyaratan dengan teliti
				</SweetAlert>
			)
		});
	};

	tolakSurat = (uuid_surat) => {
		let alasan = document.getElementById('alasan').value;
		if (alasan == '') {
			let status = { ...this.state.status };
			status.alert = true;

			this.setState({
				status,
				basicType: 'warning',
				basicTitle: 'Penolakan Pengajuan Layanan',
				pesanAlert: 'Isikan alasan penolakan terlebih dahulu'
			});
		} else {
			this.setState({
				alert: (
					<SweetAlert
						showCancel
						confirmBtnText="Ya, saya yakin"
						confirmBtnBsStyle="warning"
						confirmBtnStyle={{ fontWeight: 600 }}
						cancelBtnText="Batal"
						cancelBtnBsStyle="default"
						cancelBtnStyle={{ fontWeight: 600 }}
						type="warning"
						title="Apakah anda yakin ingin menolak pengajuan layanan ini ?"
						onCancel={() => {
							this.setState({ alert: null });
						}}
						onConfirm={() => {
							if (this.state.detailSurat) {
								this.setState({
									loadingPreview: true,
								})

								let bodyFormData = {
									status: 'Ditolak',
									keterangan: alasan
								};
								Post('pelayanan/verifikasi-status', uuid_surat, bodyFormData, (data) => {
									// console.log(data);
									if (data.status == 200) {
										let status = { ...this.state.status };
										status.alert = true;
										this.setState({
											status,
											basicType: 'success',
											basicTitle: 'Validasi Pengajuan Pelayanan',
											pesanAlert: 'Berhasil melakukan penolakan pengajuan layanan',
											loadingPreview: false,
										});
										this.componentDidMount(this.state.active_tab_icon);
									} else {
										let status = { ...this.state.status };
										status.alert = true;
										this.setState({
											status,
											basicType: 'danger',
											basicTitle: 'Validasi Pengajuan Pelayanan',
											pesanAlert: 'Gagal melakukan penolakan pengajuan layanan',
											loadingPreview: false,
										});
									}
									this.tutupForm();
								});
							}
						}}
					>
						Proses ini tidak dapat dibatalkan, pastikan anda sudah teliti dalam melakukan proses penolakan
						pengajuan
					</SweetAlert>
				)
			});
			// this.tutupForm()
		}
	};

	onFileChange = (event, attribute) => {
    const file = event.target.files[0];
    const fileType = file.type.split('/')
    const type = fileType[1]
    
    if (type == "pdf") {
		this.setState({
			loadingPreview: true,
		})

		let bodyFormData = new FormData();
		bodyFormData.append("files", file);
  
		Post("upload-file/pdf", null, bodyFormData, (response) => {
			const link = response.data.results
			let linkGambar = {...this.state.linkGambar};

			linkGambar[attribute] = link
	
			this.setState({ 
				linkGambar,
				loadingPreview: false,
			});
		});
    } else {
      let status = { ...this.state.status };
      status.alert = true;

      this.setState({
        status,
        basicType: "warning",
        basicTitle: "Upload file",
        pesanAlert: "Format file harus berupa PDF",
      });
      document.getElementById("file-upload").value = null
    }

  };

	uploadFiles = (e) => {
    e.preventDefault()

    let isUploadEnable = true
    // Cek upload button
    Object.keys(this.state.linkGambar).map((attribute) => {
      isUploadEnable = isUploadEnable && this.state.linkGambar[attribute] != ''
    })

    if (isUploadEnable) {
		this.setState({
			loadingPreview: true,
		})

		let bodyFormData = {
			status: "Selesai",
			dokumen_output: this.state.linkGambar,
		};

		Post(
			"pelayanan/verifikasi-status",
			this.state.detailSurat.uuid,
			bodyFormData,
			(data) => {
			console.log(data);
			if (data.status == 200) {
				let status = { ...this.state.status };
				status.alert = true;
				this.setState({
					status,
					basicType: "success",
					basicTitle: "Upload Berkas",
					pesanAlert: "Berhasil upload berkas",
					loadingPreview: false,
				});
				this.componentDidMount(this.state.active_tab_icon);
			} else {
				let status = { ...this.state.status };
				status.alert = true;
				this.setState({
					status,
					basicType: "danger",
					basicTitle: "Upload Berkas",
					pesanAlert: "Gagal upload berkas",
					loadingPreview: false,
				});
			}
			this.tutupForm();
			}
		);
    } else {
      let status = { ...this.state.status };
      status.alert = true;

      this.setState({
        status,
        basicType: "warning",
        basicTitle: "Upload berkas",
        pesanAlert: "Pilih berkas terlebih dahulu",
      });
    }
  };

  toggle_icon(tab) {
    if (this.state.active_tab_icon !== tab) {
      if(tab === '1'){
        this.state.cek_posisi = 'Pertama'
      }
      else if(tab === '2'){
        this.state.cek_posisi = 'Kedua'
      }
      else if(tab === '3'){
        this.state.cek_posisi = 'Ketiga'
      }
      else if(tab === '4'){
        this.state.cek_posisi = 'Keempat'
      }
      
      this.setState({
        active_tab_icon: tab,
      });
    }
    
    this.forceUpdate();
    this.componentDidMount();
  }

	render() {
		
		var data = this.state.data;
		var dataDiproses = this.state.dataDiproses;
		var dataDitolak = this.state.dataDitolak;
		var dataSelesai = this.state.dataSelesai;

		const columns = [
			{
				dataField: "no",
				text: "No",
				isDummyField: true,
				csvExport: false,
				formatter: (key, obj, index) =>{ 
					let current_pagenum = '';
					let total_records_per_page = '';
					let row_index = '';
					let serial_num = '';
	  
					if(this.state.active_tab_icon ==='1'){
					  current_pagenum = this.state.pagination.current_page;
					  total_records_per_page = this.state.pagination.per_page;
					  row_index = (index+1);
					  serial_num = ((total_records_per_page*(current_pagenum-1))+row_index);
					}    
					else if(this.state.active_tab_icon ==='2'){
					  current_pagenum = this.state.paginationDiproses.current_page;
					  total_records_per_page = this.state.paginationDiproses.per_page;
					  row_index = (index+1);
					  serial_num = ((total_records_per_page*(current_pagenum-1))+row_index);
					}    
					else if(this.state.active_tab_icon ==='3'){
						current_pagenum = this.state.paginationSelesai.current_page;
						total_records_per_page = this.state.paginationSelesai.per_page;
						row_index = (index+1);
						serial_num = ((total_records_per_page*(current_pagenum-1))+row_index);
					}    
					else if(this.state.active_tab_icon ==='4'){
					  current_pagenum = this.state.paginationDitolak.current_page;
					  total_records_per_page = this.state.paginationDitolak.per_page;
					  row_index = (index+1);
					  serial_num = ((total_records_per_page*(current_pagenum-1))+row_index);
					}    
					
					return serial_num; 
				}
			},
			{
				dataField: 'tgl_melapor',
				text: 'Tanggal Melapor',
				sort: false
			},
			{
				dataField: 'data_pelayanan',
				text: 'Jenis Perubahan',
				sort: true,
				formatter: (value) => {
					let dataPelayanan = JSON.parse(value);

					return dataPelayanan.jenis_perubahan;
				}
			},
			{
				dataField: 'nik_pelapor',
				text: 'NIK',
				sort: true
			},
			{
				dataField: 'nama_pelapor',
				text: 'Nama',
				sort: true
			},
			{
				dataField: 'data_status_baru.status',
				text: 'status',
				sort: true
				// formatter: (value)=> {
				//   return value[0]
				// }
			},
			{
				dataField: 'aksi',
				text: 'Aksi',
				isDummyField: true,
				csvExport: false,
				formatter: this.tombolAksi
			}
		];


		let uploadButton = true
    // Cek upload button
    Object.keys(this.state.linkGambar).map((attribute) => {
      uploadButton = uploadButton && this.state.linkGambar[attribute] != ''
    })

		return (
			<div>
				<div className="container-fluid">
					<SweetAlert
						show={this.state.status.alert}
						type={this.state.basicType}
						title={this.state.basicTitle}
						confirmBtnBsStyle="info"
						onConfirm={this.hideAlert}
					>
						{this.state.pesanAlert}
					</SweetAlert>

					{this.state.alert}

					<div className="row">
						<div className="col-lg-12 p-0 m-0">
							<div className="card col-lg-12 p-10">
								<div className="card-header" style={{ padding: '20px' }}>
									<div className="row">
										<div className="col-sm-6 text-left">
											<h6 style={{ color: 'black', fontSize: '17px' }} className="mb-0">
												Data Pengajuan Penerbitan Kartu Keluarga
											</h6>
										</div>
									</div>
								</div>
								<div className="card-body datatable-react" style={{ paddingTop: '15px' }}>
									<div className="row">
										<div className="col-sm-12 p-0">
											<Nav tabs className="tabs-color">
												<NavItem style={{ cursor: 'pointer' }}>
													<NavLink
														className={classnames({
															active: this.state.active_tab_icon === '1'
														})}
														onClick={() => {
															this.toggle_icon('1');
														}}
													>
														Diajukan
													</NavLink>
												</NavItem>
												<NavItem style={{ cursor: 'pointer' }}>
													<NavLink
														className={classnames({
															active: this.state.active_tab_icon === '2'
														})}
														onClick={() => {
															this.toggle_icon('2');
														}}
													>
														Diproses
													</NavLink>
												</NavItem>
												<NavItem style={{ cursor: 'pointer' }}>
													<NavLink
														className={classnames({
															active: this.state.active_tab_icon === '3'
														})}
														onClick={() => {
															this.toggle_icon('3');
														}}
													>
														Selesai
													</NavLink>
												</NavItem>
												<NavItem style={{ cursor: 'pointer' }}>
													<NavLink
														className={classnames({
															active: this.state.active_tab_icon === '4'
														})}
														onClick={() => {
															this.toggle_icon('4');
														}}
													>
														Ditolak
													</NavLink>
												</NavItem>
											</Nav>
											<TabContent activeTab={this.state.active_tab_icon}>
												<TabPane tabId="1">
													<div style={{ marginTop: '15px' }}>
														<div className="row">
															<div className="col-sm-12">
																<BootstrapTable
																	keyField="uuid"
																	data={data}
																	columns={columns}
																/>
																<div className="pull-right text-white">
																	{this.state.awal}
																	{this.state.sebelum}
																	{this.state.hal.map(dt => {
																		return dt;
																	})}
																	{this.state.setelah}
																	{this.state.akhir}
																</div>
															</div>
														</div>
													</div>
												</TabPane>
												<TabPane tabId="2">
													<div style={{ marginTop: '15px' }}>
														<div className="row">
															<div className="col-sm-12">
																<BootstrapTable
																	keyField="uuid_surat"
																	data={dataDiproses}
																	columns={columns}
																/>
																<div className="pull-right text-white">
																	{this.state.awal2}
																	{this.state.sebelum2}
																	{this.state.hal2.map(dt => {
																		return dt;
																	})}
																	{this.state.setelah2}
																	{this.state.akhir2}
																</div>
															</div>
														</div>
													</div>
												</TabPane>
												<TabPane tabId="3">
													<div style={{ marginTop: '15px' }}>
														<div className="row">
															<div className="col-sm-12">
																<BootstrapTable
																	keyField="uuid_surat"
																	data={dataSelesai}
																	columns={columns}
																/>
																<div className="pull-right text-white">
																	{this.state.awal3}
																	{this.state.sebelum3}
																	{this.state.hal3.map(dt => {
																		return dt;
																	})}
																	{this.state.setelah3}
																	{this.state.akhir3}
																</div>
															</div>
														</div>
													</div>
												</TabPane>
												<TabPane tabId="4">
													<div style={{ marginTop: '15px' }}>
														<div className="row">
															<div className="col-sm-12">
																<BootstrapTable
																	keyField="uuid_surat"
																	data={dataDitolak}
																	columns={columns}
																/>
																<div className="pull-right text-white">
																	{this.state.awal4}
																	{this.state.sebelum4}
																	{this.state.hal4.map(dt => {
																		return dt;
																	})}
																	{this.state.setelah4}
																	{this.state.akhir4}
																</div>
															</div>
														</div>
													</div>
												</TabPane>
											</TabContent>
										</div>
									</div>
									<div className="row" style={{ marginTop: '10px' }}>
										<div className="col-sm-12">
											<Link to="/admin/dashboard">Kembali ke dashboard</Link>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					{/* Modal validasi */}
					<Modal
						open={this.state.status.modalValidasi}
						styles={{
							modal: {
								width: '85%'
							}
						}}
						onClose={() => {
							// Tutup modal validasi
							let status = { ...this.state.status };
							status.modalValidasi = false;
							this.setState({ 
								status,
								detailSurat: null,
								dataPelayanan: null,
								jenisPerubahan: '',
								dataDokumenOutput: [],
							});
						}}
					>
						<Form className="theme-form">
							<div className="modal-header">
								<h5 className="modal-title">Form Validasi Penerbitan Kartu Keluarga</h5>
							</div>
							<div className="modal-body">
								{this.state.detailSurat ? (
									<div>
										{/* Detail Surat */}
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-3 col-form-label" style={{ fontWeight: 600 }}>
												Nama
											</Label>
											<div className="col-sm-9" style={{ display: 'flex', alignItems: 'center' }}>
												<h6 style={{ marginBottom: '0px', fontWeight: 400 }}>
													{this.state.detailSurat.nama_pelapor}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-3 col-form-label" style={{ fontWeight: 600 }}>
												NIK
											</Label>
											<div className="col-sm-9" style={{ display: 'flex', alignItems: 'center' }}>
												<h6 style={{ marginBottom: '0px', fontWeight: 400 }}>
													{this.state.detailSurat.nik_pelapor}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-3 col-form-label" style={{ fontWeight: 600 }}>
												Jenis Perubahan
											</Label>
											<div className="col-sm-9" style={{ display: 'flex', alignItems: 'center' }}>
												<h6 style={{ marginBottom: '0px', fontWeight: 400 }}>
													{this.state.dataPelayanan ? this.state.dataPelayanan.jenis_perubahan : ''}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-3 col-form-label" style={{ fontWeight: 600 }}>
												Tanggal Pengajuan
											</Label>
											<div className="col-sm-9" style={{ display: 'flex', alignItems: 'center' }}>
												<h6 style={{ marginBottom: '0px', fontWeight: 400 }}>
													{this.state.detailSurat.tgl_melapor}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-3 col-form-label" style={{ fontWeight: 600 }}>
												Nomor HP
											</Label>
											<div className="col-sm-9" style={{ display: 'flex', alignItems: 'center' }}>
												<h6 style={{ marginBottom: '0px', fontWeight: 400 }}>
													{this.state.detailSurat.no_hp_pelapor}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-3 col-form-label" style={{ fontWeight: 600 }}>
												Pekerjaan
											</Label>
											<div className="col-sm-9" style={{ display: 'flex', alignItems: 'center' }}>
												<h6 style={{ marginBottom: '0px', fontWeight: 400 }}>
													{this.state.detailSurat.pekerjaan_pelapor}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-3 col-form-label" style={{ fontWeight: 600 }}>
												Alamat
											</Label>
											<div className="col-sm-9" style={{ display: 'flex', alignItems: 'center' }}>
												<h6 style={{ marginBottom: '0px', fontWeight: 400 }}>
													{this.state.detailSurat.alamat_pelapor}
												</h6>
											</div>
										</FormGroup>
									</div>
								) : (
									''
								)}
							</div>
							<div className="modal-body">
								{/* Perubahan Data */}
								{this.state.dataPelayanan && (this.state.jenisPerubahan == 'Pecah Kartu Keluarga' || this.state.jenisPerubahan == 'Penambahan Anak') ? (
									<div>
										{this.state.dataDokumenOutput ?
                      Object.keys(this.state.dataDokumenOutput).map((val, index) => {
                        let dokumenOutput = this.state.dataDokumenOutput

												let titleFile = 'dokumen'
												switch (val) {
													case 'kartu_keluarga':
														titleFile = "Kartu Keluarga"
														break;
												}

                        return (
                          <FormGroup className="row" style={{ marginBottom: "0px" }}>
                            <Label
                              className="col-sm-4 col-form-label pb-1"
                              style={{ fontWeight: 600 }}
                            >
                              Berkas {titleFile}
                            </Label>
                            <div
                              className="col-sm-8"
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <a href={dokumenOutput[val]}>Lihat file</a>
                            </div>
                          </FormGroup>
                        )
                      })
                    : ''}
										{/* Data Wilayah */}
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label
												className="col-sm-4 col-form-label pb-1"
												style={{ fontWeight: 1000 }}
											>
												<h6 style={{ margin: "0px" }}>
													<b>Data Wilayah</b>
												</h6>
											</Label>
										</FormGroup>
										<div style={{ padding: "10px", border: "1px solid black", borderRadius: "15px"}}>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1 pt-0" style={{ fontWeight: 600 }}>
												Kode - Nama Provinsi
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.detailSurat ? (
														this.state.detailSurat.id_desa.toString().substr(0, 2) +
														' - ' +
														this.state.detailSurat.data_kelurahan.data_kecamatan
															.data_kabkota.data_provinsi.nama
													) : (
														''
													)}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1" style={{ fontWeight: 600 }}>
												Kode - Nama Kabupaten / Kota
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.detailSurat ? (
														this.state.detailSurat.id_desa.toString().substr(2, 2) +
														' - ' +
														this.state.detailSurat.data_kelurahan.data_kecamatan
															.data_kabkota.nama
													) : (
														''
													)}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1" style={{ fontWeight: 600 }}>
												Kode - Nama Kecamatan
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.detailSurat ? (
														this.state.detailSurat.id_desa.toString().substr(4, 2) +
														' - ' +
														this.state.detailSurat.data_kelurahan.data_kecamatan.nama
													) : (
														''
													)}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1" style={{ fontWeight: 600 }}>
												Kode - Nama Kelurahan / Desa
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.detailSurat ? (
														this.state.detailSurat.id_desa.toString().substr(6, 4) +
														' - ' +
														this.state.detailSurat.data_kelurahan.nama
													) : (
														''
													)}
												</h6>
											</div>
										</FormGroup>
										</div>
										{/* Data Kepala Keluarga */}
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label
												className="col-sm-4 col-form-label pb-1"
												style={{ fontWeight: 1000 }}
											>
												<h6 style={{ margin: "0px" }}>
													<b>Data Kepala Keluarga</b>
												</h6>
											</Label>
										</FormGroup>
										<div style={{ padding: "10px", border: "1px solid black", borderRadius: "15px"}}>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1 pt-0" style={{ fontWeight: 600 }}>
												Nama Kepala Keluarga
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{
														this.state.dataPelayanan.data_f1_01.data_kepala_keluarga
															.nama_kepala_keluarga
													}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1" style={{ fontWeight: 600 }}>
												Alamat
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{
														this.state.dataPelayanan.data_f1_01.data_kepala_keluarga
															.alamat_kepala_keluarga
													}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1" style={{ fontWeight: 600 }}>
												Kode POS
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{
														this.state.dataPelayanan.data_f1_01.data_kepala_keluarga
															.kode_pos_kepala_keluarga
													}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1" style={{ fontWeight: 600 }}>
												RT
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{
														this.state.dataPelayanan.data_f1_01.data_kepala_keluarga
															.rt_kepala_keluarga
													}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1" style={{ fontWeight: 600 }}>
												RW
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{
														this.state.dataPelayanan.data_f1_01.data_kepala_keluarga
															.rw_kepala_keluarga
													}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1" style={{ fontWeight: 600 }}>
												Jumlah Anggota Keluarga
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{
														this.state.dataPelayanan.data_f1_01.data_kepala_keluarga
															.jumlah_anggota
													}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1" style={{ fontWeight: 600 }}>
												Telepon
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{
														this.state.dataPelayanan.data_f1_01.data_kepala_keluarga
															.telepon_kepala_keluarga
													}
												</h6>
											</div>
										</FormGroup>
										</div>
										{/* Data Keluarga */}
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label
												className="col-sm-4 col-form-label pb-2 mt-2"
												style={{ fontWeight: 1000 }}
											>
												<h6 style={{ margin: "0px" }}>
													<b>Data Keluarga</b>
												</h6>
											</Label>
										</FormGroup>
										{/* Tabel 1 */}
										<div className="row">
											<div className="col-sm-12">
												<Table responsive striped bordered size="sm" className="table-custom" >
													<thead style={{ textAlign: "center" }}>
														<tr>
															<th>No</th>
															<th>Nama Lengkap</th>
															<th>Gelar</th>
															<th>No. KTP</th>
															<th>Alamat Sebelumnya</th>
															<th>Nomor Paspor</th>
															<th>Tanggal Berakhir Paspor</th>
														</tr>
													</thead>
													<thead style={{ textAlign: "center" }}>
														<tr>
															<th>1</th>
															<th>2</th>
															<th>3</th>
															<th>4</th>
															<th>5</th>
															<th>6</th>
															<th>7</th>
														</tr>
													</thead>
													<tbody style={{ textAlign: "center" }}>
														{this.state.dataPelayanan ? (
															this.state.dataPelayanan.data_f1_01.data_keluarga.map((val, index) => (
																<tr>
																	<td>{index+1}</td>
																	<td>{val.nama}</td>
																	<td>{val.gelar}</td>
																	<td>{val.no_ktp}</td>
																	<td>{val.alamat_sebelum}</td>
																	<td>{val.no_paspor}</td>
																	<td>{val.tgl_berakhir_paspor}</td>
																</tr>
															))
														) : ''}
													</tbody>
												</Table>
											</div>
										</div>
										{/* Tabel 2 */}
										<div className="row" style={{ marginTop: "15px" }}>
											<div className="col-sm-12">
												<Table responsive striped bordered size="sm" className="table-custom" >
													<thead style={{ textAlign: "center" }}>
														<tr>
															<th>No</th>
															<th>Jenis Kelamin</th>
															<th>Tempat Lahir</th>
															<th>Tgl Lahir</th>
															<th>Umur</th>
															<th>Akte Lahir</th>
															<th>No. Akta Kelahiran</th>
															<th>Golongan Darah</th>
															<th>Agama</th>
															<th>Kepercayaan terhadap Tuhan Yang Maha Esa</th>
															<th>Status Perkawinan</th>
														</tr>
													</thead>
													<thead style={{ textAlign: "center" }}>
														<tr>
															<th></th>
															<th>8</th>
															<th>9</th>
															<th>10</th>
															<th>11</th>
															<th>12</th>
															<th>13</th>
															<th>14</th>
															<th>15</th>
															<th>16</th>
															<th>17</th>
														</tr>
													</thead>
													<tbody style={{ textAlign: "center" }}>
														{this.state.dataPelayanan ? (
															this.state.dataPelayanan.data_f1_01.data_keluarga.map((val, index) => (
																<tr>
																	<td>{index+1}</td>
																	<td>{val.jenis_kelamin}</td>
																	<td>{val.tmpt_lahir}</td>
																	<td>{val.tgl_lahir}</td>
																	<td>{val.umur}</td>
																	<td>{val.akte_lahir}</td>
																	<td>{val.no_akta_kelahiran}</td>
																	<td>{val.golongan_darah}</td>
																	<td>{val.agama}</td>
																	<td>{val.kepercayaan_kpd_tuhan}</td>
																	<td>{val.status_perkawinan}</td>
																</tr>
															))
														) : ''}
													</tbody>
												</Table>
											</div>
										</div>
										{/* Tabel 3 */}
										<div className="row" style={{ marginTop: "15px" }}>
											<div className="col-sm-12">
												<Table responsive striped bordered size="sm" className="table-custom" >
													<thead style={{ textAlign: "center" }}>
														<tr>
															<th>No</th>
															<th>Akta Perkawinan</th>
															<th>No. Akta Perkawinan</th>
															<th>Tgl Perkawinan</th>
															<th>Akta Cerai</th>
															<th>Nomor Akta Cerai</th>
															<th>Tgl Perceraian</th>

															<th>Status Hub. Dlm. Keluarga</th>
															<th>Kelainan Fisik / Mental</th>
															
														</tr>
													</thead>
													<thead style={{ textAlign: "center" }}>
														<tr>
															<th></th>
															<th>18</th>
															<th>19</th>
															<th>20</th>
															<th>21</th>
															<th>22</th>
															<th>23</th>
															<th>24</th>
															<th>25</th>
															
														</tr>
													</thead>
													<tbody style={{ textAlign: "center" }}>
														{this.state.dataPelayanan ? (
															this.state.dataPelayanan.data_f1_01.data_keluarga.map((val, index) => (
																<tr>
																	<td>{index+1}</td>
																	<td>{val.akte_perkawinan}</td>
																	<td>{val.no_akta_perkawinan}</td>
																	<td>{val.tgl_perkawinan}</td>
																	<td>{val.akta_cerai}</td>
																	<td>{val.no_akta_cerai}</td>
																	<td>{val.tgl_cerai}</td>

																	<td>{val.status_hub_keluarga}</td>
																	<td>{val.kelainan_fisik}</td>
																	
																</tr>
															))
														) : ''}
													</tbody>
												</Table>
											</div>
										</div>
										{/* Tabel 4 */}
										<div className="row" style={{ marginTop: "15px", marginBottom: "15px" }}>
											<div className="col-sm-12">
												<Table responsive striped bordered size="sm" className="table-custom" >
													<thead style={{ textAlign: "center" }}>
														<tr>
															<th>No</th>
															<th>Penyandang Cacat</th>
															<th>Pendidikan Terakhir</th>
															<th>Pekerjaan</th>
															<th>NIK Ibu</th>
															<th>Nama Lengkap Ibu</th>
															<th>NIK Ayah</th>
															<th>Nama Lengkap Ayah</th>
														</tr>
													</thead>
													<thead style={{ textAlign: "center" }}>
														<tr>
															<th></th>
															<th>26</th>
															<th>27</th>
															<th>28</th>
															<th>29</th>
															<th>30</th>
															<th>31</th>
															<th>32</th>
														</tr>
													</thead>
													<tbody style={{ textAlign: "center" }}>
														{this.state.dataPelayanan ? (
															this.state.dataPelayanan.data_f1_01.data_keluarga.map((val, index) => (
																<tr>
																	<td>{index+1}</td>
																	<td>{val.penyandang_cacat}</td>
																	<td>{val.pendidikan_terakhir}</td>
																	<td>{val.pekerjaan}</td>
																	<td>{val.nik_ibu}</td>
																	<td>{val.nama_ibu}</td>
																	<td>{val.nik_ayah}</td>
																	<td>{val.nama_ayah}</td>
																</tr>
															))
														) : ''}
													</tbody>
												</Table>
											</div>
										</div>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1" style={{ fontWeight: 600 }}>
												Nama Ketua RT
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_f1_01.nama_ketua_rt}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1" style={{ fontWeight: 600 }}>
												Nama Ketua RW
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_f1_01.nama_ketua_rw}
												</h6>
											</div>
										</FormGroup>
										<hr />
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-3 col-form-label pt-0" style={{ fontWeight: 600 }}>
												<h5>Persyaratan</h5>
											</Label>
											<div
												className="col-sm-9"
												style={{ display: 'flex', alignItems: 'center' }}
											/>
										</FormGroup>
										<div className="row" id="list-persyaratan">
											{/* Looping foto persyaratan */}
											{Object.keys(
												JSON.parse(this.state.detailSurat.dokumen_input)
											).map((val, index) => {
												let dokumen_input = JSON.parse(this.state.detailSurat.dokumen_input);
												let nama_syarat = 'Foto Persyaratan';
												// console.log(dokumen_input)

												switch (val) {
													case 'foto_akta':
														nama_syarat = 'Akta';
														break;

													case 'foto_blanko_f1_05':
														nama_syarat = 'Blanko F1 05';
														break;

													case 'foto_ijazah':
														nama_syarat = 'Ijazah';
														break;

													case 'foto_kk_1':
														nama_syarat = 'Kartu Keluarga';
														break;

													case 'foto_surat_nikah_semua_lembaran':
														nama_syarat = 'Surat Nikah';
														break;

													case 'foto_ijazah_terakhir':
														nama_syarat = 'Ijazah Terakhir';
														break;

													case 'foto_kk_pihak_kedua':
														nama_syarat = 'KK Pihak Kedua';
														break;

													case 'foto_kk_pihak_pertama':
														nama_syarat = 'KK Pihak Pertama';
														break;

													case 'foto_surat_lahir_dari_bidan':
														nama_syarat = 'Surat Lahir dari Bidan';
														break;

													case 'foto_f1_03':
														nama_syarat = 'Blanko F1 03';
														break;

													case 'foto_kk':
														nama_syarat = 'Kartu Keluarga';
														break;

													case 'foto_surat_nikah_orang_tua':
														nama_syarat = 'Surat Nikah Orang Tua';
														break;

													case 'surat_pernyataan_anak_kandung':
														nama_syarat = 'Surat Pernyataan Anak Kandung';
														break;

													default:
														break;
												}

												let listGambar = [];

												if (Array.isArray(dokumen_input[val])) {
													dokumen_input[val].map((val2, index2) => {
														listGambar.push(val2);
													});
												} else {
													listGambar.push(dokumen_input[val]);
												}

												return (
													<>
														{listGambar.map((val2, index2) => {
															return (
																<div
																	className="col-md-4"
																	style={{
																		display: 'flex',
																		flexDirection: 'column',
																		alignItems: 'center'
																	}}
																>
																	{val2 != "" ? (
																		<img
																			src={val2}
																			style={{ width: "200px", height: "200px", objectFit: "cover", cursor: "pointer" }}
																			onClick={() => {
																				let status = { ...this.state.status };
																				status.fotoPersyaratan = true;
																				this.setState({
																					status,
																					fotoPersyaratan: val2,
																					titlePersyaratan: nama_syarat,
																				});
																			}}
																		/>
																	) : (
																		<div style={{ height: "200px", width: "200px", display: "flex", alignItems: "center", justifyContent: "center" }}>
																			<p style={{ textAlign: "center", fontWeight: 600 }}>Tidak ada foto persyaratan</p>
																		</div>
																	)}
																	<p style={{ textAlign: "center" }}>{nama_syarat}</p>
																</div>
															);
														})}
													</>
												);
											})}
										</div>
									</div>
								) : this.state.dataPelayanan && this.state.jenisPerubahan == "Perubahan Data" ? (
									<div>
										{this.state.dataDokumenOutput ?
                      Object.keys(this.state.dataDokumenOutput).map((val, index) => {
                        let dokumenOutput = this.state.dataDokumenOutput

												let titleFile = 'dokumen'
												switch (val) {
													case 'kartu_keluarga':
														titleFile = "Kartu Keluarga"
														break;
												}

                        return (
                          <FormGroup className="row" style={{ marginBottom: "0px" }}>
                            <Label
                              className="col-sm-4 col-form-label pb-1"
                              style={{ fontWeight: 600 }}
                            >
                              Berkas {titleFile}
                            </Label>
                            <div
                              className="col-sm-8"
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <a href={dokumenOutput[val]}>Lihat file</a>
                            </div>
                          </FormGroup>
                        )
                      })
                    : ''}
										{/* Data Wilayah */}
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label
												className="col-sm-4 col-form-label pb-1"
												style={{ fontWeight: 1000 }}
											>
												<h6 style={{ margin: "0px" }}>
													<b>Data Wilayah</b>
												</h6>
											</Label>
										</FormGroup>
										<div style={{ padding: "10px", border: "1px solid black", borderRadius: "15px"}}>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1 pt-0" style={{ fontWeight: 600 }}>
												Kode - Nama Provinsi
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.detailSurat ? (
														this.state.detailSurat.id_desa.toString().substr(0, 2) +
														' - ' +
														this.state.detailSurat.data_kelurahan.data_kecamatan
															.data_kabkota.data_provinsi.nama
													) : (
														''
													)}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1" style={{ fontWeight: 600 }}>
												Kode - Nama Kabupaten / Kota
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.detailSurat ? (
														this.state.detailSurat.id_desa.toString().substr(2, 2) +
														' - ' +
														this.state.detailSurat.data_kelurahan.data_kecamatan
															.data_kabkota.nama
													) : (
														''
													)}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1" style={{ fontWeight: 600 }}>
												Kode - Nama Kecamatan
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.detailSurat ? (
														this.state.detailSurat.id_desa.toString().substr(4, 2) +
														' - ' +
														this.state.detailSurat.data_kelurahan.data_kecamatan.nama
													) : (
														''
													)}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1" style={{ fontWeight: 600 }}>
												Kode - Nama Kelurahan / Desa
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.detailSurat ? (
														this.state.detailSurat.id_desa.toString().substr(6, 4) +
														' - ' +
														this.state.detailSurat.data_kelurahan.nama
													) : (
														''
													)}
												</h6>
											</div>
										</FormGroup>
										</div>
										{/* Data Keluarga */}
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label
												className="col-sm-4 col-form-label pb-1 pt-3"
												style={{ fontWeight: 1000 }}
											>
												<h6 style={{ margin: "0px" }}>
													<b>Data Keluarga</b>
												</h6>
											</Label>
										</FormGroup>
										<div style={{ padding: "10px", border: "1px solid black", borderRadius: "15px"}}>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1 pt-0" style={{ fontWeight: 600 }}>
												Nama Kepala Keluarga
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_keluarga.nama_kepala_keluarga}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1" style={{ fontWeight: 600 }}>
												Nomor Kartu Keluarga
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_keluarga.no_kk}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1" style={{ fontWeight: 600 }}>
												Alamat Keluarga
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_keluarga.alamat_keluarga}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px', paddingLeft: "1.5rem", paddingLeft: "1.5rem" }}>
											<Label
												className="col-sm-4 col-form-label pb-1"
												style={{ fontWeight: 600 }}
											>
												RT
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_keluarga.rt}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px', paddingLeft: "1.5rem", paddingLeft: "1.5rem" }}>
											<Label
												className="col-sm-4 col-form-label pb-1"
												style={{ fontWeight: 600 }}
											>
												RW
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_keluarga.rw}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px', paddingLeft: "1.5rem", paddingLeft: "1.5rem" }}>
											<Label
												className="col-sm-4 col-form-label pb-1"
												style={{ fontWeight: 600 }}
											>
												Desa / Nagari
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.detailSurat ? (
														this.state.detailSurat.data_kelurahan.nama
													) : (
														''
													)}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px', paddingLeft: "1.5rem", paddingLeft: "1.5rem" }}>
											<Label
												className="col-sm-4 col-form-label pb-1"
												style={{ fontWeight: 600 }}
											>
												Kode POS
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_keluarga.kode_pos}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px', paddingLeft: "1.5rem" }}>
											<Label
												className="col-sm-4 col-form-label pb-1"
												style={{ fontWeight: 600 }}
											>
												Telepon
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_keluarga.telepon}
												</h6>
											</div>
										</FormGroup>
										</div>
										{/* Data Individu */}
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label
												className="col-sm-4 col-form-label pb-1 pt-3"
												style={{ fontWeight: 1000 }}
											>
												<h6 style={{ margin: "0px" }}>
													<b>Data Individu</b>
												</h6>
											</Label>
										</FormGroup>
										<div style={{ padding: "10px", border: "1px solid black", borderRadius: "15px"}}>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1 pt-0" style={{ fontWeight: 600 }}>
												Nama Lengkap
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_individu.nama_lengkap}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1" style={{ fontWeight: 600 }}>
												Gelar
											</Label>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px', paddingLeft: "1.5rem" }}>
											<Label
												className="col-sm-4 col-form-label pb-1"
												style={{ fontWeight: 600 }}
											>
												Gelar Akademis
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_individu.gelar.akademis != '' ? (
														this.state.dataPelayanan.data_individu.gelar.akademis
													) : (
														'-'
													)}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px', paddingLeft: "1.5rem" }}>
											<Label
												className="col-sm-4 col-form-label pb-1"
												style={{ fontWeight: 600 }}
											>
												Gelar Kebangsawanan
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_individu.gelar.kebangsawanan !=
													'' ? (
														this.state.dataPelayanan.data_individu.gelar.kebangsawanan
													) : (
														'-'
													)}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px', paddingLeft: "1.5rem" }}>
											<Label
												className="col-sm-4 col-form-label pb-1"
												style={{ fontWeight: 600 }}
											>
												Gelar Keagamaan
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_individu.gelar.keagamaan != '' ? (
														this.state.dataPelayanan.data_individu.gelar.keagamaan
													) : (
														'-'
													)}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1" style={{ fontWeight: 600 }}>
												No. KTP / Nopen
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_individu.no_ktp}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1" style={{ fontWeight: 600 }}>
												Alamat Sebelumnya
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_individu.alamat_sebelumnya.alamat}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px', paddingLeft: "1.5rem" }}>
											<Label
												className="col-sm-4 col-form-label pb-1"
												style={{ fontWeight: 600 }}
											>
												RT
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_individu.alamat_sebelumnya.rt}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px', paddingLeft: "1.5rem" }}>
											<Label
												className="col-sm-4 col-form-label pb-1"
												style={{ fontWeight: 600 }}
											>
												RW
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_individu.alamat_sebelumnya.rw}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px', paddingLeft: "1.5rem" }}>
											<Label
												className="col-sm-4 col-form-label pb-1"
												style={{ fontWeight: 600 }}
											>
												Desa / Nagari
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.detailSurat ? (
														this.state.detailSurat.data_kelurahan.nama
													) : (
														''
													)}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px', paddingLeft: "1.5rem" }}>
											<Label
												className="col-sm-4 col-form-label pb-1"
												style={{ fontWeight: 600 }}
											>
												Kode POS
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_individu.alamat_sebelumnya.kode_pos}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px', paddingLeft: "1.5rem" }}>
											<Label
												className="col-sm-4 col-form-label pb-1"
												style={{ fontWeight: 600 }}
											>
												Telepon
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_individu.alamat_sebelumnya.telepon}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1" style={{ fontWeight: 600 }}>
												Nomor Paspor
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_individu.nomor_paspor}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1" style={{ fontWeight: 600 }}>
												Tanggal Berakhir Paspor
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_individu.tanggal_berakhir_paspor}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1" style={{ fontWeight: 600 }}>
												Jenis Kelamin
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_individu.jenis_kelamin}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1" style={{ fontWeight: 600 }}>
												Tempat Lahir
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_individu.tempat_lahir}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1" style={{ fontWeight: 600 }}>
												Tanggal Lahir
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_individu.tanggal_lahir}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1" style={{ fontWeight: 600 }}>
												Umur
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_individu.umur}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1" style={{ fontWeight: 600 }}>
												Akta Kelahiran
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_individu.akta_kelahiran}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1" style={{ fontWeight: 600 }}>
												Nomor Akta Kelahiran
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_individu.nomor_akta_kelahiran}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1" style={{ fontWeight: 600 }}>
												Golongan Darah
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_individu.golongan_darah}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1" style={{ fontWeight: 600 }}>
												Agama / Kepercayaan
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_individu.agama}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1" style={{ fontWeight: 600 }}>
												Status Perkawinan
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_individu.status_perkawinan}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1" style={{ fontWeight: 600 }}>
												Akta Perkawinan
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_individu.akta_perkawinan}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1" style={{ fontWeight: 600 }}>
												Nomor Akta Perkawinan
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_individu.nomor_akta_perkawinan}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1" style={{ fontWeight: 600 }}>
												Tanggal Perkawinan
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_individu.tanggal_perkawinan}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1" style={{ fontWeight: 600 }}>
												Akta Perceraian / Surat Cerai
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_individu.akta_perceraian}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1" style={{ fontWeight: 600 }}>
												Nomor Akta Perceraian
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_individu.nomor_akta_perceraian}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1" style={{ fontWeight: 600 }}>
												Tanggal Perceraian
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_individu.tanggal_perceraian}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1" style={{ fontWeight: 600 }}>
												Status Hubungan Dalam Keluarga
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_individu.shdk}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1" style={{ fontWeight: 600 }}>
												Kelainan Fisik dan Mental
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_individu.kelainan_fisik_dan_mental}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1" style={{ fontWeight: 600 }}>
												Penyandang Cacat
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_individu.penyandang_cacat}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1" style={{ fontWeight: 600 }}>
												Pendidikan Terakhir
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_individu.pendidikan_terakhir}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1" style={{ fontWeight: 600 }}>
												Jenis Pekerjaan
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_individu.jenis_pekerjaan}
												</h6>
											</div>
										</FormGroup>
										</div>
										{/* Data Orang Tua */}
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label
												className="col-sm-4 col-form-label pb-1 pt-3"
												style={{ fontWeight: 1000 }}
											>
												<h6 style={{ margin: "0px" }}>
													<b>Data Orang Tua</b>
												</h6>
											</Label>
										</FormGroup>
										<div style={{ padding: "10px", border: "1px solid black", borderRadius: "15px"}}>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1 pt-0" style={{ fontWeight: 600 }}>
												NIK Ibu
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_orang_tua.nik_ibu}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1" style={{ fontWeight: 600 }}>
												Nama Lengkap Ibu
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_orang_tua.nama_ibu}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1" style={{ fontWeight: 600 }}>
												NIK Ayah
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_orang_tua.nik_ayah}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1" style={{ fontWeight: 600 }}>
												Nama Lengkap Ayah
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_orang_tua.nama_ayah}
												</h6>
											</div>
										</FormGroup>
										</div>
										{/* <hr/> */}
										{/* Data Administrasi */}
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label
												className="col-sm-4 col-form-label pb-1 pt-3"
												style={{ fontWeight: 1000 }}
											>
												<h6 style={{ margin: "0px" }}>
													<b>Data Administrasi</b>
												</h6>
											</Label>
										</FormGroup>
										<div style={{ padding: "10px", border: "1px solid black", borderRadius: "15px"}}>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1 pt-0" style={{ fontWeight: 600 }}>
												Nama Ketua RT
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_administrasi.nama_ketua_rt}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1" style={{ fontWeight: 600 }}>
												Nama Ketua RW
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_administrasi.nama_ketua_rw}
												</h6>
											</div>
										</FormGroup>
										</div>
										<hr />
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-3 col-form-label pt-0" style={{ fontWeight: 600 }}>
												<h5>Persyaratan</h5>
											</Label>
											<div
												className="col-sm-9"
												style={{ display: 'flex', alignItems: 'center' }}
											/>
										</FormGroup>
										<div className="row" id="list-persyaratan">
											{/* Looping foto persyaratan */}
											{Object.keys(
												JSON.parse(this.state.detailSurat.dokumen_input)
											).map((val, index) => {
												let dokumen_input = JSON.parse(this.state.detailSurat.dokumen_input);
												let nama_syarat = 'Foto Persyaratan';
												// console.log(dokumen_input)

												switch (val) {
													case 'foto_akta':
														nama_syarat = 'Akta';
														break;

													case 'foto_blanko_f1_05':
														nama_syarat = 'Blanko F1 05';
														break;

													case 'foto_ijazah':
														nama_syarat = 'Ijazah';
														break;

													case 'foto_kk_1':
														nama_syarat = 'Kartu Keluarga';
														break;

													case 'foto_surat_nikah_semua_lembaran':
														nama_syarat = 'Surat Nikah';
														break;

													case 'foto_ijazah_terakhir':
														nama_syarat = 'Ijazah Terakhir';
														break;

													case 'foto_kk_pihak_kedua':
														nama_syarat = 'KK Pihak Kedua';
														break;

													case 'foto_kk_pihak_pertama':
														nama_syarat = 'KK Pihak Pertama';
														break;

													case 'foto_surat_lahir_dari_bidan':
														nama_syarat = 'Surat Lahir dari Bidan';
														break;

													case 'foto_f1_03':
														nama_syarat = 'Blanko F1 03';
														break;

													case 'foto_kk':
														nama_syarat = 'Kartu Keluarga';
														break;

													case 'foto_surat_nikah_orang_tua':
														nama_syarat = 'Surat Nikah Orang Tua';
														break;

													case 'surat_pernyataan_anak_kandung':
														nama_syarat = 'Surat Pernyataan Anak Kandung';
														break;

													default:
														break;
												}

												let listGambar = [];

												if (Array.isArray(dokumen_input[val])) {
													dokumen_input[val].map((val2, index2) => {
														listGambar.push(val2);
													});
												} else {
													listGambar.push(dokumen_input[val]);
												}

												return (
													<>
														{listGambar.map((val2, index2) => {
															return (
																<div
																	className="col-md-4"
																	style={{
																		display: 'flex',
																		flexDirection: 'column',
																		alignItems: 'center'
																	}}
																>
																	{val2 != "" ? (
																		<img
																			src={val2}
																			style={{ width: "200px", height: "200px", objectFit: "cover", cursor: "pointer" }}
																			onClick={() => {
																				let status = { ...this.state.status };
																				status.fotoPersyaratan = true;
																				this.setState({
																					status,
																					fotoPersyaratan: val2,
																					titlePersyaratan: nama_syarat,
																				});
																			}}
																		/>
																	) : (
																		<div style={{ height: "200px", width: "200px", display: "flex", alignItems: "center", justifyContent: "center" }}>
																			<p style={{ textAlign: "center", fontWeight: 600 }}>Tidak ada foto persyaratan</p>
																		</div>
																	)}
																	<br/>
																	<p style={{ textAlign: "center" }}><b>{nama_syarat}</b></p>
																</div>
															);
														})}
													</>
												);
											})}
										</div>
									</div>
								) : (
									''
								)}
							</div>
							<div className="modal-footer">
								<button
									type="button"
									className="btn btn-primary"
									// disabled={this.state.status.btnForm}
									onClick={() => {
										// Tutup modal validasi
										let status = { ...this.state.status };
										status.modalValidasi = false;
										this.setState({ 
										status,
										detailSurat: null,
										dataPelayanan: null,
										jenisPerubahan: '',
										dataDokumenOutput: [],
										});
									}}
									style={{ fontWeight: 600 }}
									>
									Tutup
								</button>
								<button
									type="button"
									className="btn btn-warning"
									// disabled={this.state.status.btnForm}
									onClick={() => {
										let status = { ...this.state.status };
										status.tolakValidasi = true;

										this.setState({ status });
									}}
									style={{ fontWeight: 600 }}
								>
									Tolak
								</button>
								<button
									type="submit"
									className="btn btn-success"
									// disabled={this.state.status.btnForm}
									onClick={(e) => {
										e.preventDefault();
										if (this.state.detailSurat) {
											this.updateSurat(this.state.detailSurat.uuid);
										}
									}}
									style={{ fontWeight: 600 }}
								>
									Terima
								</button>
							</div>
						</Form>
					</Modal>
					{/* Modal Detail Gambar */}
					<Modal
						open={this.state.status.fotoPersyaratan}
						styles={{
							modal: {
								width: '90%'
							}
						}}
						onClose={() => {
							let status = { ...this.state.status };
							status.fotoPersyaratan = false;
							this.setState({ status });
						}}
					>
						<div className="modal-header">
							<h5 className="modal-title">{this.state.titlePersyaratan}</h5>
						</div>
						<div className="modal-body">
							<div className="row">
								<div className="col-md-12">
									{this.state.fotoPersyaratan != '' ? (
										<img
										src={this.state.fotoPersyaratan}
										style={{ width: "100%" }}
									/>
									) : (
										<h6 style={{ textAlign: "center" }}>Tidak ada Foto</h6>
									)}
								</div>
							</div>
						</div>
						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-default"
								// disabled={this.state.status.btnForm}
								onClick={() => {
									let status = { ...this.state.status };
									status.fotoPersyaratan = false;
									this.setState({ status });
								}}
								style={{ fontWeight: 600 }}
							>
								Tutup
							</button>
						</div>
					</Modal>
					{/* Modal form penolakan pengajuan */}
					<Modal
						open={this.state.status.tolakValidasi}
						styles={{
							modal: {
								width: '80%'
							}
						}}
						onClose={() => {
							let status = { ...this.state.status };
							status.tolakValidasi = false;
							this.setState({ status });
						}}
					>
						<Form className="theme-form">
							<div className="modal-header">
								<h5 className="modal-title">Penolakan Pengajuan Layanan</h5>
							</div>
							<div className="modal-body">
								<FormGroup className="row" style={{ marginBottom: '0px' }}>
									<Label className="col-sm-3 col-form-label" style={{ fontWeight: 600 }}>
										Alasan Penolakan
									</Label>
									<div className="col-sm-9" style={{ textAlign: 'center' }}>
										<Input type="textarea" id="alasan" nama="alasan" />
									</div>
								</FormGroup>
							</div>
							<div className="modal-footer">
								<button
									type="button"
									className="btn btn-info"
									// disabled={this.state.status.btnForm}
									onClick={() => {
										let status = { ...this.state.status };
										status.tolakValidasi = false;
										this.setState({ status });
									}}
									style={{ fontWeight: 600 }}
								>
									Batal
								</button>
								<button
									type="submit"
									className="btn btn-success"
									// disabled={this.state.status.btnForm}
									onClick={(e) => {
										e.preventDefault();
										this.tolakSurat(this.state.detailSurat.uuid);
									}}
									style={{ fontWeight: 600 }}
								>
									Simpan
								</button>
							</div>
						</Form>
					</Modal>
					{/* Modal upload berkas */}
					<Modal
						open={this.state.status.modalUpload}
						styles={{
							modal: {
								width: '85%'
							}
						}}
						onClose={() => {
							let status = { ...this.state.status };
							status.modalUpload = false;
							this.setState({ 
                status, 
                linkGambar: listFile
              });
						}}
					>
						<Form className="theme-form" onSubmit={this.uploadFiles}>
							<div className="modal-header">
								<h5 className="modal-title">Form Upload Berkas</h5>
							</div>
							<div className="modal-body">
								{Object.keys(this.state.linkGambar).map((attribute) => {
                  let titleFile = 'dokumen'
                  switch (attribute) {
                    case 'kartu_keluarga':
                      titleFile = "Kartu Keluarga"
                      break;
                  }

                  return (
                    <FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-3 col-form-label"
                        style={{ fontWeight: 600 }}
                      >
                        File {titleFile}
                      </Label>
                      <div
                        className="col-sm-9"
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <input id="file-upload" type="file" onChange={(event) => {
                          this.onFileChange(event, attribute)
                        }} />
                        {this.state.linkGambar[attribute] != "" ? (
                          <a href={this.state.linkGambar[attribute]}>Lihat file</a>
                        ) : (
                          ""
                        )}
                      </div>
                    </FormGroup>
                  )
                })}
							</div>
							<div className="modal-footer">
								<button
									type="button"
									className="btn btn-info"
									// disabled={this.state.status.btnForm}
									onClick={() => {
										this.tutupForm();
									}}
									style={{ fontWeight: 600 }}
								>
									Batal
								</button>
								<button
                  type="submit"
                  disabled={uploadButton ? false : true}
                  className="btn btn-success"
                  style={{ fontWeight: 600 }}
                >
                  Simpan
                </button>
							</div>
						</Form>
					</Modal>
					{/* Modal detail berkas */}
					<Modal
						open={this.state.status.modalDetail}
						styles={{
							modal: {
								width: '90%',
								maxWidth: "none"
							}
						}}
						onClose={() => {
							let status = { ...this.state.status };
							status.modalDetail = false;
							this.setState({ status });
						}}
					>
						<Form className="theme-form">
							<div className="modal-header">
								<h5 className="modal-title">Form Pengajuan Penerbitan Kartu Keluarga</h5>
							</div>
							<div className="modal-body">
								{/* Perubahan Data */}
								{this.state.dataPelayanan && (this.state.jenisPerubahan == 'Pecah Kartu Keluarga' || this.state.jenisPerubahan == 'Penambahan Anak') ? (
									<div>
										{this.state.dataDokumenOutput ?
                      Object.keys(this.state.dataDokumenOutput).map((val, index) => {
                        let dokumenOutput = this.state.dataDokumenOutput

												let titleFile = 'dokumen'
												switch (val) {
													case 'kartu_keluarga':
														titleFile = "Kartu Keluarga"
														break;
												}

                        return (
                          <FormGroup className="row" style={{ marginBottom: "0px" }}>
                            <Label
                              className="col-sm-4 col-form-label pb-1"
                              style={{ fontWeight: 600 }}
                            >
                              Berkas {titleFile}
                            </Label>
                            <div
                              className="col-sm-8"
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <a href={dokumenOutput[val]}>Lihat file</a>
                            </div>
                          </FormGroup>
                        )
                      })
                    : ''}
										{/* Data Wilayah */}
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label
												className="col-sm-4 col-form-label pb-1"
												style={{ fontWeight: 1000 }}
											>
												<h6 style={{ margin: "0px" }}>
													<b>Data Wilayah</b>
												</h6>
											</Label>
										</FormGroup>
										<div style={{ padding: "10px", border: "1px solid black", borderRadius: "15px"}}>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1 pt-0" style={{ fontWeight: 600 }}>
												Kode - Nama Provinsi
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.detailSurat ? (
														this.state.detailSurat.id_desa.toString().substr(0, 2) +
														' - ' +
														this.state.detailSurat.data_kelurahan.data_kecamatan
															.data_kabkota.data_provinsi.nama
													) : (
														''
													)}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1" style={{ fontWeight: 600 }}>
												Kode - Nama Kabupaten / Kota
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.detailSurat ? (
														this.state.detailSurat.id_desa.toString().substr(2, 2) +
														' - ' +
														this.state.detailSurat.data_kelurahan.data_kecamatan
															.data_kabkota.nama
													) : (
														''
													)}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1" style={{ fontWeight: 600 }}>
												Kode - Nama Kecamatan
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.detailSurat ? (
														this.state.detailSurat.id_desa.toString().substr(4, 2) +
														' - ' +
														this.state.detailSurat.data_kelurahan.data_kecamatan.nama
													) : (
														''
													)}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1" style={{ fontWeight: 600 }}>
												Kode - Nama Kelurahan / Desa
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.detailSurat ? (
														this.state.detailSurat.id_desa.toString().substr(6, 4) +
														' - ' +
														this.state.detailSurat.data_kelurahan.nama
													) : (
														''
													)}
												</h6>
											</div>
										</FormGroup>
										</div>
										{/* Data Kepala Keluarga */}
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label
												className="col-sm-4 col-form-label pb-1"
												style={{ fontWeight: 1000 }}
											>
												<h6 style={{ margin: "0px" }}>
													<b>Data Kepala Keluarga</b>
												</h6>
											</Label>
										</FormGroup>
										<div style={{ padding: "10px", border: "1px solid black", borderRadius: "15px"}}>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1 pt-0" style={{ fontWeight: 600 }}>
												Nama Kepala Keluarga
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{
														this.state.dataPelayanan.data_f1_01.data_kepala_keluarga
															.nama_kepala_keluarga
													}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1" style={{ fontWeight: 600 }}>
												Alamat
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{
														this.state.dataPelayanan.data_f1_01.data_kepala_keluarga
															.alamat_kepala_keluarga
													}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1" style={{ fontWeight: 600 }}>
												Kode POS
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{
														this.state.dataPelayanan.data_f1_01.data_kepala_keluarga
															.kode_pos_kepala_keluarga
													}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1" style={{ fontWeight: 600 }}>
												RT
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{
														this.state.dataPelayanan.data_f1_01.data_kepala_keluarga
															.rt_kepala_keluarga
													}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1" style={{ fontWeight: 600 }}>
												RW
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{
														this.state.dataPelayanan.data_f1_01.data_kepala_keluarga
															.rw_kepala_keluarga
													}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1" style={{ fontWeight: 600 }}>
												Jumlah Anggota Keluarga
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{
														this.state.dataPelayanan.data_f1_01.data_kepala_keluarga
															.jumlah_anggota
													}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1" style={{ fontWeight: 600 }}>
												Telepon
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{
														this.state.dataPelayanan.data_f1_01.data_kepala_keluarga
															.telepon_kepala_keluarga
													}
												</h6>
											</div>
										</FormGroup>
										</div>
										{/* Data Keluarga */}
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label
												className="col-sm-4 col-form-label pb-2 mt-2"
												style={{ fontWeight: 1000 }}
											>
												<h6 style={{ margin: "0px" }}>
													<b>Data Keluarga</b>
												</h6>
											</Label>
										</FormGroup>
										{/* Tabel 1 */}
										<div className="row">
											<div className="col-sm-12">
												<Table responsive striped bordered size="sm" className="table-custom" >
													<thead style={{ textAlign: "center" }}>
														<tr>
															<th>No</th>
															<th>Nama Lengkap</th>
															<th>Gelar</th>
															<th>No. KTP</th>
															<th>Alamat Sebelumnya</th>
															<th>Nomor Paspor</th>
															<th>Tanggal Berakhir Paspor</th>
														</tr>
													</thead>
													<thead style={{ textAlign: "center" }}>
														<tr>
															<th>1</th>
															<th>2</th>
															<th>3</th>
															<th>4</th>
															<th>5</th>
															<th>6</th>
															<th>7</th>
														</tr>
													</thead>
													<tbody style={{ textAlign: "center" }}>
														{this.state.dataPelayanan ? (
															this.state.dataPelayanan.data_f1_01.data_keluarga.map((val, index) => (
																<tr>
																	<td>{index+1}</td>
																	<td>{val.nama}</td>
																	<td>{val.gelar}</td>
																	<td>{val.no_ktp}</td>
																	<td>{val.alamat_sebelum}</td>
																	<td>{val.no_paspor}</td>
																	<td>{val.tgl_berakhir_paspor}</td>
																</tr>
															))
														) : ''}
													</tbody>
												</Table>
											</div>
										</div>
										{/* Tabel 2 */}
										<div className="row" style={{ marginTop: "15px" }}>
											<div className="col-sm-12">
												<Table responsive striped bordered size="sm" className="table-custom" >
													<thead style={{ textAlign: "center" }}>
														<tr>
															<th>No</th>
															<th>Jenis Kelamin</th>
															<th>Tempat Lahir</th>
															<th>Tgl Lahir</th>
															<th>Umur</th>
															<th>Akte Lahir</th>
															<th>No. Akta Kelahiran</th>
															<th>Golongan Darah</th>
															<th>Agama</th>
															<th>Kepercayaan terhadap Tuhan Yang Maha Esa</th>
															<th>Status Perkawinan</th>
														</tr>
													</thead>
													<thead style={{ textAlign: "center" }}>
														<tr>
															<th></th>
															<th>8</th>
															<th>9</th>
															<th>10</th>
															<th>11</th>
															<th>12</th>
															<th>13</th>
															<th>14</th>
															<th>15</th>
															<th>16</th>
															<th>17</th>
														</tr>
													</thead>
													<tbody style={{ textAlign: "center" }}>
														{this.state.dataPelayanan ? (
															this.state.dataPelayanan.data_f1_01.data_keluarga.map((val, index) => (
																<tr>
																	<td>{index+1}</td>
																	<td>{val.jenis_kelamin}</td>
																	<td>{val.tmpt_lahir}</td>
																	<td>{val.tgl_lahir}</td>
																	<td>{val.umur}</td>
																	<td>{val.akte_lahir}</td>
																	<td>{val.no_akta_kelahiran}</td>
																	<td>{val.golongan_darah}</td>
																	<td>{val.agama}</td>
																	<td>{val.kepercayaan_kpd_tuhan}</td>
																	<td>{val.status_perkawinan}</td>
																</tr>
															))
														) : ''}
													</tbody>
												</Table>
											</div>
										</div>
										{/* Tabel 3 */}
										<div className="row" style={{ marginTop: "15px" }}>
											<div className="col-sm-12">
												<Table responsive striped bordered size="sm" className="table-custom" >
													<thead style={{ textAlign: "center" }}>
														<tr>
															<th>No</th>
															<th>Akta Perkawinan</th>
															<th>No. Akta Perkawinan</th>
															<th>Tgl Perkawinan</th>
															<th>Akta Cerai</th>
															<th>Nomor Akta Cerai</th>
															<th>Tgl Perceraian</th>

															<th>Status Hub. Dlm. Keluarga</th>
															<th>Kelainan Fisik / Mental</th>
															
														</tr>
													</thead>
													<thead style={{ textAlign: "center" }}>
														<tr>
															<th></th>
															<th>18</th>
															<th>19</th>
															<th>20</th>
															<th>21</th>
															<th>22</th>
															<th>23</th>
															<th>24</th>
															<th>25</th>
															
														</tr>
													</thead>
													<tbody style={{ textAlign: "center" }}>
														{this.state.dataPelayanan ? (
															this.state.dataPelayanan.data_f1_01.data_keluarga.map((val, index) => (
																<tr>
																	<td>{index+1}</td>
																	<td>{val.akte_perkawinan}</td>
																	<td>{val.no_akta_perkawinan}</td>
																	<td>{val.tgl_perkawinan}</td>
																	<td>{val.akta_cerai}</td>
																	<td>{val.no_akta_cerai}</td>
																	<td>{val.tgl_cerai}</td>

																	<td>{val.status_hub_keluarga}</td>
																	<td>{val.kelainan_fisik}</td>
																	
																</tr>
															))
														) : ''}
													</tbody>
												</Table>
											</div>
										</div>
										{/* Tabel 4 */}
										<div className="row" style={{ marginTop: "15px", marginBottom: "15px" }}>
											<div className="col-sm-12">
												<Table responsive striped bordered size="sm" className="table-custom" >
													<thead style={{ textAlign: "center" }}>
														<tr>
															<th>No</th>
															<th>Penyandang Cacat</th>
															<th>Pendidikan Terakhir</th>
															<th>Pekerjaan</th>
															<th>NIK Ibu</th>
															<th>Nama Lengkap Ibu</th>
															<th>NIK Ayah</th>
															<th>Nama Lengkap Ayah</th>
														</tr>
													</thead>
													<thead style={{ textAlign: "center" }}>
														<tr>
															<th></th>
															<th>26</th>
															<th>27</th>
															<th>28</th>
															<th>29</th>
															<th>30</th>
															<th>31</th>
															<th>32</th>
														</tr>
													</thead>
													<tbody style={{ textAlign: "center" }}>
														{this.state.dataPelayanan ? (
															this.state.dataPelayanan.data_f1_01.data_keluarga.map((val, index) => (
																<tr>
																	<td>{index+1}</td>
																	<td>{val.penyandang_cacat}</td>
																	<td>{val.pendidikan_terakhir}</td>
																	<td>{val.pekerjaan}</td>
																	<td>{val.nik_ibu}</td>
																	<td>{val.nama_ibu}</td>
																	<td>{val.nik_ayah}</td>
																	<td>{val.nama_ayah}</td>
																</tr>
															))
														) : ''}
													</tbody>
												</Table>
											</div>
										</div>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1" style={{ fontWeight: 600 }}>
												Nama Ketua RT
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_f1_01.nama_ketua_rt}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1" style={{ fontWeight: 600 }}>
												Nama Ketua RW
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_f1_01.nama_ketua_rw}
												</h6>
											</div>
										</FormGroup>
										<hr />
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-3 col-form-label pt-0" style={{ fontWeight: 600 }}>
												<h5>Persyaratan</h5>
											</Label>
											<div
												className="col-sm-9"
												style={{ display: 'flex', alignItems: 'center' }}
											/>
										</FormGroup>
										<div className="row" id="list-persyaratan">
											{/* Looping foto persyaratan */}
											{Object.keys(
												JSON.parse(this.state.detailSurat.dokumen_input)
											).map((val, index) => {
												let dokumen_input = JSON.parse(this.state.detailSurat.dokumen_input);
												let nama_syarat = 'Foto Persyaratan';
												// console.log(dokumen_input)

												switch (val) {
													case 'foto_akta':
														nama_syarat = 'Akta';
														break;

													case 'foto_blanko_f1_05':
														nama_syarat = 'Blanko F1 05';
														break;

													case 'foto_ijazah':
														nama_syarat = 'Ijazah';
														break;

													case 'foto_kk_1':
														nama_syarat = 'Kartu Keluarga';
														break;

													case 'foto_surat_nikah_semua_lembaran':
														nama_syarat = 'Surat Nikah';
														break;

													case 'foto_ijazah_terakhir':
														nama_syarat = 'Ijazah Terakhir';
														break;

													case 'foto_kk_pihak_kedua':
														nama_syarat = 'KK Pihak Kedua';
														break;

													case 'foto_kk_pihak_pertama':
														nama_syarat = 'KK Pihak Pertama';
														break;

													case 'foto_surat_lahir_dari_bidan':
														nama_syarat = 'Surat Lahir dari Bidan';
														break;

													case 'foto_f1_03':
														nama_syarat = 'Blanko F1 03';
														break;

													case 'foto_kk':
														nama_syarat = 'Kartu Keluarga';
														break;

													case 'foto_surat_nikah_orang_tua':
														nama_syarat = 'Surat Nikah Orang Tua';
														break;

													case 'surat_pernyataan_anak_kandung':
														nama_syarat = 'Surat Pernyataan Anak Kandung';
														break;

													default:
														break;
												}

												let listGambar = [];

												if (Array.isArray(dokumen_input[val])) {
													dokumen_input[val].map((val2, index2) => {
														listGambar.push(val2);
													});
												} else {
													listGambar.push(dokumen_input[val]);
												}

												return (
													<>
														{listGambar.map((val2, index2) => {
															return (
																<div
																	className="col-md-4"
																	style={{
																		display: 'flex',
																		flexDirection: 'column',
																		alignItems: 'center'
																	}}
																>
																	{val2 != "" ? (
																		<img
																			src={val2}
																			style={{ width: "200px", height: "200px", objectFit: "cover", cursor: "pointer" }}
																			onClick={() => {
																				let status = { ...this.state.status };
																				status.fotoPersyaratan = true;
																				this.setState({
																					status,
																					fotoPersyaratan: val2,
																					titlePersyaratan: nama_syarat,
																				});
																			}}
																		/>
																	) : (
																		<div style={{ height: "200px", width: "200px", display: "flex", alignItems: "center", justifyContent: "center" }}>
																			<p style={{ textAlign: "center", fontWeight: 600 }}>Tidak ada foto persyaratan</p>
																		</div>
																	)}
																	<br/>
																	<p style={{ textAlign: "center" }}><b>{nama_syarat}</b></p>
																</div>
															);
														})}
													</>
												);
											})}
										</div>
									</div>
								) : this.state.dataPelayanan && this.state.jenisPerubahan == "Perubahan Data" ? (
									<div>
										{this.state.dataDokumenOutput ?
                      Object.keys(this.state.dataDokumenOutput).map((val, index) => {
                        let dokumenOutput = this.state.dataDokumenOutput

												let titleFile = 'dokumen'
												switch (val) {
													case 'kartu_keluarga':
														titleFile = "Kartu Keluarga"
														break;
												}

                        return (
                          <FormGroup className="row" style={{ marginBottom: "0px" }}>
                            <Label
                              className="col-sm-4 col-form-label pb-1"
                              style={{ fontWeight: 600 }}
                            >
                              Berkas {titleFile}
                            </Label>
                            <div
                              className="col-sm-8"
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <a href={dokumenOutput[val]}>Lihat file</a>
                            </div>
                          </FormGroup>
                        )
                      })
                    : ''}
										{/* Data Wilayah */}
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label
												className="col-sm-4 col-form-label pb-1"
												style={{ fontWeight: 1000 }}
											>
												<h6 style={{ margin: "0px" }}>
													<b>Data Wilayah</b>
												</h6>
											</Label>
										</FormGroup>
										<div style={{ padding: "10px", border: "1px solid black", borderRadius: "15px"}}>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1 pt-0" style={{ fontWeight: 600 }}>
												Kode - Nama Provinsi
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.detailSurat ? (
														this.state.detailSurat.id_desa.toString().substr(0, 2) +
														' - ' +
														this.state.detailSurat.data_kelurahan.data_kecamatan
															.data_kabkota.data_provinsi.nama
													) : (
														''
													)}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1" style={{ fontWeight: 600 }}>
												Kode - Nama Kabupaten / Kota
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.detailSurat ? (
														this.state.detailSurat.id_desa.toString().substr(2, 2) +
														' - ' +
														this.state.detailSurat.data_kelurahan.data_kecamatan
															.data_kabkota.nama
													) : (
														''
													)}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1" style={{ fontWeight: 600 }}>
												Kode - Nama Kecamatan
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.detailSurat ? (
														this.state.detailSurat.id_desa.toString().substr(4, 2) +
														' - ' +
														this.state.detailSurat.data_kelurahan.data_kecamatan.nama
													) : (
														''
													)}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1" style={{ fontWeight: 600 }}>
												Kode - Nama Kelurahan / Desa
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.detailSurat ? (
														this.state.detailSurat.id_desa.toString().substr(6, 4) +
														' - ' +
														this.state.detailSurat.data_kelurahan.nama
													) : (
														''
													)}
												</h6>
											</div>
										</FormGroup>
										</div>
										{/* Data Keluarga */}
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label
												className="col-sm-4 col-form-label pb-1 pt-3"
												style={{ fontWeight: 1000 }}
											>
												<h6 style={{ margin: "0px" }}>
													<b>Data Keluarga</b>
												</h6>
											</Label>
										</FormGroup>
										<div style={{ padding: "10px", border: "1px solid black", borderRadius: "15px"}}>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1 pt-0" style={{ fontWeight: 600 }}>
												Nama Kepala Keluarga
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_keluarga.nama_kepala_keluarga}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1" style={{ fontWeight: 600 }}>
												Nomor Kartu Keluarga
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_keluarga.no_kk}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1" style={{ fontWeight: 600 }}>
												Alamat Keluarga
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_keluarga.alamat_keluarga}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px', paddingLeft: "1.5rem", paddingLeft: "1.5rem" }}>
											<Label
												className="col-sm-4 col-form-label pb-1"
												style={{ fontWeight: 600 }}
											>
												RT
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_keluarga.rt}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px', paddingLeft: "1.5rem", paddingLeft: "1.5rem" }}>
											<Label
												className="col-sm-4 col-form-label pb-1"
												style={{ fontWeight: 600 }}
											>
												RW
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_keluarga.rw}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px', paddingLeft: "1.5rem", paddingLeft: "1.5rem" }}>
											<Label
												className="col-sm-4 col-form-label pb-1"
												style={{ fontWeight: 600 }}
											>
												Desa / Nagari
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.detailSurat ? (
														this.state.detailSurat.data_kelurahan.nama
													) : (
														''
													)}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px', paddingLeft: "1.5rem", paddingLeft: "1.5rem" }}>
											<Label
												className="col-sm-4 col-form-label pb-1"
												style={{ fontWeight: 600 }}
											>
												Kode POS
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_keluarga.kode_pos}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px', paddingLeft: "1.5rem" }}>
											<Label
												className="col-sm-4 col-form-label pb-1"
												style={{ fontWeight: 600 }}
											>
												Telepon
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_keluarga.telepon}
												</h6>
											</div>
										</FormGroup>
										</div>
										{/* Data Individu */}
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label
												className="col-sm-4 col-form-label pb-1 pt-3"
												style={{ fontWeight: 1000 }}
											>
												<h6 style={{ margin: "0px" }}>
													<b>Data Individu</b>
												</h6>
											</Label>
										</FormGroup>
										<div style={{ padding: "10px", border: "1px solid black", borderRadius: "15px"}}>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1 pt-0" style={{ fontWeight: 600 }}>
												Nama Lengkap
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_individu.nama_lengkap}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1" style={{ fontWeight: 600 }}>
												Gelar
											</Label>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px', paddingLeft: "1.5rem" }}>
											<Label
												className="col-sm-4 col-form-label pb-1"
												style={{ fontWeight: 600 }}
											>
												Gelar Akademis
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_individu.gelar.akademis != '' ? (
														this.state.dataPelayanan.data_individu.gelar.akademis
													) : (
														'-'
													)}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px', paddingLeft: "1.5rem" }}>
											<Label
												className="col-sm-4 col-form-label pb-1"
												style={{ fontWeight: 600 }}
											>
												Gelar Kebangsawanan
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_individu.gelar.kebangsawanan !=
													'' ? (
														this.state.dataPelayanan.data_individu.gelar.kebangsawanan
													) : (
														'-'
													)}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px', paddingLeft: "1.5rem" }}>
											<Label
												className="col-sm-4 col-form-label pb-1"
												style={{ fontWeight: 600 }}
											>
												Gelar Keagamaan
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_individu.gelar.keagamaan != '' ? (
														this.state.dataPelayanan.data_individu.gelar.keagamaan
													) : (
														'-'
													)}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1" style={{ fontWeight: 600 }}>
												No. KTP / Nopen
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_individu.no_ktp}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1" style={{ fontWeight: 600 }}>
												Alamat Sebelumnya
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_individu.alamat_sebelumnya.alamat}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px', paddingLeft: "1.5rem" }}>
											<Label
												className="col-sm-4 col-form-label pb-1"
												style={{ fontWeight: 600 }}
											>
												RT
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_individu.alamat_sebelumnya.rt}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px', paddingLeft: "1.5rem" }}>
											<Label
												className="col-sm-4 col-form-label pb-1"
												style={{ fontWeight: 600 }}
											>
												RW
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_individu.alamat_sebelumnya.rw}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px', paddingLeft: "1.5rem" }}>
											<Label
												className="col-sm-4 col-form-label pb-1"
												style={{ fontWeight: 600 }}
											>
												Desa / Nagari
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.detailSurat ? (
														this.state.detailSurat.data_kelurahan.nama
													) : (
														''
													)}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px', paddingLeft: "1.5rem" }}>
											<Label
												className="col-sm-4 col-form-label pb-1"
												style={{ fontWeight: 600 }}
											>
												Kode POS
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_individu.alamat_sebelumnya.kode_pos}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px', paddingLeft: "1.5rem" }}>
											<Label
												className="col-sm-4 col-form-label pb-1"
												style={{ fontWeight: 600 }}
											>
												Telepon
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_individu.alamat_sebelumnya.telepon}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1" style={{ fontWeight: 600 }}>
												Nomor Paspor
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_individu.nomor_paspor}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1" style={{ fontWeight: 600 }}>
												Tanggal Berakhir Paspor
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_individu.tanggal_berakhir_paspor}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1" style={{ fontWeight: 600 }}>
												Jenis Kelamin
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_individu.jenis_kelamin}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1" style={{ fontWeight: 600 }}>
												Tempat Lahir
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_individu.tempat_lahir}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1" style={{ fontWeight: 600 }}>
												Tanggal Lahir
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_individu.tanggal_lahir}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1" style={{ fontWeight: 600 }}>
												Umur
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_individu.umur}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1" style={{ fontWeight: 600 }}>
												Akta Kelahiran
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_individu.akta_kelahiran}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1" style={{ fontWeight: 600 }}>
												Nomor Akta Kelahiran
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_individu.nomor_akta_kelahiran}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1" style={{ fontWeight: 600 }}>
												Golongan Darah
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_individu.golongan_darah}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1" style={{ fontWeight: 600 }}>
												Agama / Kepercayaan
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_individu.agama}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1" style={{ fontWeight: 600 }}>
												Status Perkawinan
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_individu.status_perkawinan}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1" style={{ fontWeight: 600 }}>
												Akta Perkawinan
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_individu.akta_perkawinan}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1" style={{ fontWeight: 600 }}>
												Nomor Akta Perkawinan
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_individu.nomor_akta_perkawinan}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1" style={{ fontWeight: 600 }}>
												Tanggal Perkawinan
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_individu.tanggal_perkawinan}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1" style={{ fontWeight: 600 }}>
												Akta Perceraian / Surat Cerai
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_individu.akta_perceraian}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1" style={{ fontWeight: 600 }}>
												Nomor Akta Perceraian
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_individu.nomor_akta_perceraian}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1" style={{ fontWeight: 600 }}>
												Tanggal Perceraian
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_individu.tanggal_perceraian}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1" style={{ fontWeight: 600 }}>
												Status Hubungan Dalam Keluarga
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_individu.shdk}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1" style={{ fontWeight: 600 }}>
												Kelainan Fisik dan Mental
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_individu.kelainan_fisik_dan_mental}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1" style={{ fontWeight: 600 }}>
												Penyandang Cacat
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_individu.penyandang_cacat}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1" style={{ fontWeight: 600 }}>
												Pendidikan Terakhir
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_individu.pendidikan_terakhir}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1" style={{ fontWeight: 600 }}>
												Jenis Pekerjaan
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_individu.jenis_pekerjaan}
												</h6>
											</div>
										</FormGroup>
										</div>
										{/* Data Orang Tua */}
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label
												className="col-sm-4 col-form-label pb-1 pt-3"
												style={{ fontWeight: 1000 }}
											>
												<h6 style={{ margin: "0px" }}>
													<b>Data Orang Tua</b>
												</h6>
											</Label>
										</FormGroup>
										<div style={{ padding: "10px", border: "1px solid black", borderRadius: "15px"}}>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1 pt-0" style={{ fontWeight: 600 }}>
												NIK Ibu
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_orang_tua.nik_ibu}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1" style={{ fontWeight: 600 }}>
												Nama Lengkap Ibu
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_orang_tua.nama_ibu}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1" style={{ fontWeight: 600 }}>
												NIK Ayah
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_orang_tua.nik_ayah}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1" style={{ fontWeight: 600 }}>
												Nama Lengkap Ayah
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_orang_tua.nama_ayah}
												</h6>
											</div>
										</FormGroup>
										</div>
										{/* <hr/> */}
										{/* Data Administrasi */}
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label
												className="col-sm-4 col-form-label pb-1 pt-3"
												style={{ fontWeight: 1000 }}
											>
												<h6 style={{ margin: "0px" }}>
													<b>Data Administrasi</b>
												</h6>
											</Label>
										</FormGroup>
										<div style={{ padding: "10px", border: "1px solid black", borderRadius: "15px"}}>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1 pt-0" style={{ fontWeight: 600 }}>
												Nama Ketua RT
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_administrasi.nama_ketua_rt}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-4 col-form-label pb-1" style={{ fontWeight: 600 }}>
												Nama Ketua RW
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												>
													{this.state.dataPelayanan.data_administrasi.nama_ketua_rw}
												</h6>
											</div>
										</FormGroup>
										</div>
										<hr />
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-3 col-form-label pt-0" style={{ fontWeight: 600 }}>
												<h5>Persyaratan</h5>
											</Label>
											<div
												className="col-sm-9"
												style={{ display: 'flex', alignItems: 'center' }}
											/>
										</FormGroup>
										<div className="row" id="list-persyaratan">
											{/* Looping foto persyaratan */}
											{Object.keys(
												JSON.parse(this.state.detailSurat.dokumen_input)
											).map((val, index) => {
												let dokumen_input = JSON.parse(this.state.detailSurat.dokumen_input);
												let nama_syarat = 'Foto Persyaratan';
												// console.log(dokumen_input)

												switch (val) {
													case 'foto_akta':
														nama_syarat = 'Akta';
														break;

													case 'foto_blanko_f1_05':
														nama_syarat = 'Blanko F1 05';
														break;

													case 'foto_ijazah':
														nama_syarat = 'Ijazah';
														break;

													case 'foto_kk_1':
														nama_syarat = 'Kartu Keluarga';
														break;

													case 'foto_surat_nikah_semua_lembaran':
														nama_syarat = 'Surat Nikah';
														break;

													case 'foto_ijazah_terakhir':
														nama_syarat = 'Ijazah Terakhir';
														break;

													case 'foto_kk_pihak_kedua':
														nama_syarat = 'KK Pihak Kedua';
														break;

													case 'foto_kk_pihak_pertama':
														nama_syarat = 'KK Pihak Pertama';
														break;

													case 'foto_surat_lahir_dari_bidan':
														nama_syarat = 'Surat Lahir dari Bidan';
														break;

													case 'foto_f1_03':
														nama_syarat = 'Blanko F1 03';
														break;

													case 'foto_kk':
														nama_syarat = 'Kartu Keluarga';
														break;

													case 'foto_surat_nikah_orang_tua':
														nama_syarat = 'Surat Nikah Orang Tua';
														break;

													case 'surat_pernyataan_anak_kandung':
														nama_syarat = 'Surat Pernyataan Anak Kandung';
														break;

													default:
														break;
												}

												let listGambar = [];

												if (Array.isArray(dokumen_input[val])) {
													dokumen_input[val].map((val2, index2) => {
														listGambar.push(val2);
													});
												} else {
													listGambar.push(dokumen_input[val]);
												}

												return (
													<>
														{listGambar.map((val2, index2) => {
															return (
																<div
																	className="col-md-4"
																	style={{
																		display: 'flex',
																		flexDirection: 'column',
																		alignItems: 'center'
																	}}
																>
																	{val2 != "" ? (
																		<img
																			src={val2}
																			style={{ width: "200px", height: "200px", objectFit: "cover", cursor: "pointer" }}
																			onClick={() => {
																				let status = { ...this.state.status };
																				status.fotoPersyaratan = true;
																				this.setState({
																					status,
																					fotoPersyaratan: val2,
																					titlePersyaratan: nama_syarat,
																				});
																			}}
																		/>
																	) : (
																		<div style={{ height: "200px", width: "200px", display: "flex", alignItems: "center", justifyContent: "center" }}>
																			<p style={{ textAlign: "center", fontWeight: 600 }}>Tidak ada foto persyaratan</p>
																		</div>
																	)}
																	<br/>
																	<p style={{ textAlign: "center" }}><b>{nama_syarat}</b></p>
																</div>
															);
														})}
													</>
												);
											})}
										</div>
									</div>
								) : (
									''
								)}
							</div>
							<div className="modal-footer">
								<button
									type="button"
									className="btn btn-info"
									// disabled={this.state.status.btnForm}
									onClick={() => {
										this.tutupForm();
									}}
									style={{ fontWeight: 600 }}
								>
									Tutup
								</button>
							</div>
						</Form>
					</Modal>

					<SweetAlert
						show={this.state.loadingPreview}
						showCancel = {false}
						showConfirm={false}
					>
						<div align="center">
							<h5 className="modal-title" style={{ textAlign: "center", color: 'black' }}>Sedang Dalam Proses</h5>
							<br/>
							<img 
								alt="loading"
								src={require("../../assets/images/loading-preview.gif")} 
								style={{
									// width: "100%",
									borderRadius: "10px",
									width: '150px'
								}}
							/> 
							<br/><br/>
						</div>
					</SweetAlert>
				</div>
			</div>
		);
	}
}

export default KartuKeluarga;
