import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import '../assets/css/site.css';

import SweetAlert from 'react-bootstrap-sweetalert';
// import {DESA} from "../function/Koneksi"
import { Nama_DESA, DESA, NAMA_KABUPATEN, PROVINSI, KECAMATAN } from '../function/Koneksi';

// koneksi
import { Post, Get, Delete, cekLogin } from '../function/Koneksi';
import { DataToken } from '../function/Format';

const jwt = require('jsonwebtoken');
let listLayanan = null
// let LIST_LAYANAN = JSON.parse(LAYANAN_DESA);
class Dashboard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			alert: null,
			show: false,
			basicTitle: '',
			basicType: 'default',
			pesanAlert: '',
			layanan: [],
			perizinan: [],
			listPelayanan: [],
			jumlahMenungguEksekusi: 0,
			jumlahSelesai: 0,
			jumlahCatatanMenungguEksekusi: 0,
			jumlahVerifikasiMenungguEksekusi: 0,
			jumlahPengaduanDiajukan: 0,
			menuUtama: 'inherit',
			menuWilayah: 'none',
			menuPengaturan: 'none',
			menuPerangkat: 'none',
			menuLaporan: 'none',
			menuAdministrasi: 'none',
			menuSuku: 'none',
			menuLayanan: 'none',
			menuPerubahanData: 'none',

			jumlah_diajukan_iumk:0,
			jumlah_diajukan_usaha:0,
			total_diajukan:"",

			link_gambar_show: null,
			data_cek: '',
			akun: DataToken(sessionStorage.getItem('access_token')),
			layananList: JSON.parse(localStorage.getItem('listPelayanan')),
			loading: true,
		};
	}

	// ================== Sweet Alert ==================
	closeAlert = () => {
		this.setState({
			show: false
		});
	};

	onRecieveInput = (value) => {
		this.setState({
			alert: (
				<SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
					You wrote: {value}
				</SweetAlert>
			)
		});
	};

	hideAlert = () => {
		this.setState({
			alert: null
		});
	};

	getData = (fil = null) => {
		console.log('List Jenis Surat 1')
		if (fil) {
			let filJenisSurat = {
				nama: fil
			};
			Post('jenis-surat/list', null, filJenisSurat, (data) => {
				console.log('List Jenis Surat')
				console.log(data)
				let layanan = [];
				let perizinan = [];
				data.data.results.map((dt) => {
					if (dt.kategori == 'Layanan Masyarakat') {
						layanan.push(dt);
					}
					if (dt.kategori == 'Perizinan Masyarakat') {
						perizinan.push(dt);
					}
				});
				this.setState({ layanan, perizinan });
			});
		} else {
			//   Get("jenis-surat/list", null, data => {
			//     let layanan = [];
			//     let perizinan = [];
			//     data.results.map(dt => {
			//       if (dt.kategori == "Layanan Masyarakat") {
			//         layanan.push(dt);
			//       }
			//       if (dt.kategori == "Perizinan Masyarakat") {
			//         perizinan.push(dt);
			//       }
			//     });
			//     this.setState({ layanan, perizinan });
			//   });
		}
	};

	componentDidMount() {
		let cek = localStorage.getItem('listPelayanan')

		if(localStorage.getItem('role').toLowerCase() === 'admin' && cek==null){
			setTimeout(
				function() {
					window.location.reload();
				},
				2500
			);
		}
		else{
			this.setState({ loading: false })

			if(localStorage.getItem('role').toLowerCase() === 'admin'){
				listLayanan = (
					<>
						{this.state.layananList.map((val) => {
							let pathname = ''
							let inisial = ''
		
							switch (val.data_pelayanan.nama.toLowerCase()) {
								// LAYANAN CAPIL
								case 'akta kelahiran':
									pathname = '/akta-kelahiran'
									inisial = 'AKL'
									break;
								case 'akta kematian':
									pathname = '/akta-kematian'
									inisial = 'AKM'
									break;
								case 'pindah datang':
									pathname = '/pindah-datang'
									inisial = 'PDT'
									break;
								case 'penambahan anak':
									pathname = '/kartu-keluarga'
									inisial = 'PA'
									break;
								case 'perubahan data':
									pathname = '/kartu-keluarga'
									inisial = 'PD'
									break;
								case 'pecah kk':
									pathname = '/kartu-keluarga'
									inisial = 'PKK'
									break;
								case 'kartu keluarga':
									pathname = '/kartu-keluarga'
									inisial = 'KK'
									break;
								case 'akta perkawinan':
									pathname = '/coming_soon'
									inisial = 'AP'
									break;
								case 'akta perceraian':
									pathname = '/coming_soon'
									inisial = 'AC'
									break;
								case 'kartu tanda penduduk':
									pathname = '/coming_soon'
									inisial = 'KTP'
									break;
								case 'kartu identitas anak':
									pathname = '/coming_soon'
									inisial = 'KIA'
									break;
								// END LAYANAN CAPIL
								// LAYANAN KECAMATAN
								case 'surat izin usaha mikro kecil':
									pathname = '/izin-usaha-mikro'
									inisial = 'IUM'
									break;
								case 'surat rekomendasi izin usaha':
									pathname = '/rekomendasi-izin-usaha'
									inisial = 'RIU'
									break;
								// END LAYANAN KECAMATAN
								// LAYANAN KUA
								case 'pendaftaran pernikahan':
									pathname = '/pendaftaran-pernikahan'
									inisial = 'PP'
									break;
								// END LAYANAN KUA
							}
		
							if (pathname != '') {
								return (
									<div className="col-sm-6 col-md-12 col-lg-4" style={{ padding: 5 }}>
										{/* <Link to={{ pathname: '/coming_soon' }}> */}
										<Link to={{ pathname, state: val }} >
											<div className="dashboard-over">
												<b>
													<span>{inisial}</span>
													<h6>{val.data_pelayanan.nama}</h6>
												</b>
											</div>
										</Link>
									</div>
								)
							} else {
								return ''
							}
		
						})}
					</>
				)
			}
			else{
				listLayanan = (
					<>
					{/* <div className="col-sm-6 col-md-12 col-lg-4" style={{ padding: 5 }}>
						<Link to={{ pathname: "/admin/pengguna"}}>
							<div className="dashboard-over">
							<b>
								<span><i className="fa fa-user"></i></span>
								<h6>Kelola Data User</h6>
							</b>
							</div>
						</Link>
					</div> */}
					<div className="col-sm-6 col-md-12 col-lg-4" style={{ padding: 5 }}>
						<Link to={{ pathname: "/admin/instansi"}}>
							<div className="dashboard-over">
							<b>
								<span><i className="fa fa-building"></i></span>
								<h6>Kelola Data Instansi</h6>
							</b>
							</div>
						</Link>
					</div>
					{/* <div className="col-sm-6 col-md-12 col-lg-4" style={{ padding: 5 }}>
						<Link to={{ pathname: "/admin/perangkat"}}>
							<div className="dashboard-over">
							<b>
								<span><i className="fa fa-users"></i></span>
								<h6>Kelola Data Perangkat</h6>
							</b>
							</div>
						</Link>
					</div> */}
					<div className="col-sm-6 col-md-12 col-lg-4" style={{ padding: 5 }}>
						<Link to={{ pathname: "/admin/master-pelayanan"}}>
							<div className="dashboard-over">
							<b>
								<span><i className="fa fa-clipboard"></i></span>
								<h6>Kelola Data Master Pelayanan</h6>
							</b>
							</div>
						</Link>
					</div>
					{/* <div className="col-sm-6 col-md-12 col-lg-4" style={{ padding: 5 }}>
						<Link to={{ pathname: "/admin/pelayanan-instansi"}}>
							<div className="dashboard-over">
							<b>
								<span><i className="fa fa-clipboard"></i></span>
								<h6>Kelola Data Pelayanan Instansi</h6>
							</b>
							</div>
						</Link>
					</div> */}
					</>
					);
			}
		}

		
		// this.getData();
		if(localStorage.getItem('role').toLowerCase() === 'admin'){
			console.log('data didmount list')
			console.log(localStorage.getItem('listPelayanan'))
			this.setState({
				layananList: JSON.parse(localStorage.getItem('listPelayanan')),
			})
		}
		let data_akun = this.state.akun.sub
		if(data_akun.data_instansi){
			Get("pelayanan-instansi/jenis/" + data_akun.data_instansi.jenis + '/' + data_akun.data_instansi.id_wilayah, null, dtkat => {
				console.log('List Pelayanan Instansinya');
				console.log(dtkat);
				if (dtkat.results) {
					// let listPelayanan = []
					// dtkat.results.data.map((val) => {
					// 	listPelayanan.push({ data: val })
					// })
					this.setState({ listPelayanan: dtkat.results })
				}
			});
		}

		// Get("pelayanan?jenis_layanan=IUMK&status=Diajukan", null, dtkat => {
		// 	if (dtkat.results) {
		// 		Get("pelayanan?jenis_layanan=Rekomendasi Izin Usaha&status=Diajukan", null, dtkat2 => {
		// 			if (dtkat2.results) {
		// 				this.setState({ 
		// 					jumlah_diajukan_iumk: dtkat.results.total,
		// 					jumlah_diajukan_usaha: dtkat2.results.total
		// 				});
		// 				// this.setState({ jumlah_diajukan_usaha: dtkat2.results.total });
		// 			}
		// 		});
		// 	}
		// });


		// Get("list-pengajuan-layanan/selesai", null, dtkat => {
		//   this.setState({ jumlahSelesai: dtkat.results.total });
		// });

		// Get("pengaduan?status=Diajukan", null, dtkat => {
		//   this.setState({ jumlahPengaduanDiajukan: dtkat.results.total });
		// });

		// Get("catatan-penduduk?status=0", null, dtkat => {
		//   this.setState({ jumlahCatatanMenungguEksekusi: dtkat.results.total });
		// });

		// Get("pengguna?status=1", null, dtkat => {
		//   this.setState({
		//     jumlahVerifikasiMenungguEksekusi: dtkat.results.total
		//   });
		// });

		// Get('banner', null, (dtkat) => {
		// 	if (dtkat.results == null || dtkat.results == 'undefined') {
		// 		this.setState({
		// 			data_cek: 'Kosong',
		// 			loading: false,
		// 			data: {
		// 				uuid: null
		// 			}
		// 		});
		// 	} else {
		// 		let set_link = '';
		// 		let statData = '';

		// 		if (dtkat.results.link_web) {
		// 			let ambil = JSON.parse(dtkat.results.link_web);
		// 			if (ambil.banner_dashboard == null || ambil.banner_dashboard == '[]') {
		// 				statData = 'Kosong';
		// 				set_link = '../assets/images/banner/kampung-tengah.png';
		// 			} else {
		// 				statData = 'Tidak Kosong';
		// 				set_link = ambil.banner_dashboard;
		// 			}
		// 		} else {
		// 			statData = 'Kosong';
		// 			set_link = '../assets/images/banner/kampung-tengah.png';
		// 		}

		// 		this.setState({
		// 			data_cek: statData,
		// 			link_gambar_show: set_link
		// 		});
		// 	}
		// });
	}

	render() {

		console.log("Data Layanan")
		console.log(this.state.listPelayanan)
		let total_diajukan = this.state.jumlah_diajukan_iumk+this.state.jumlah_diajukan_usaha;
		console.log("Data AKun")
		const dataUser = jwt.decode(sessionStorage.getItem('access_token'));
		console.log(this.state.akun);		
		console.log("List Layanan Render")
		console.log(localStorage.getItem('role'))
		console.log(localStorage.getItem('listPelayanan'))
		console.log(this.state.layananList)

		// let bannerAplikasi = null;
		// if (this.state.data_cek == 'Kosong') {
			let bannerAplikasi = (
				<img
					src={require('../assets/images/custom/gampande/banner_web.png')}
					alt=""
					style={{
						maxHeight: '250px',
						width: '100%',
						borderRadius: '10px'
					}}
				/>
			);
		// } else {
		// 	bannerAplikasi = (
		// 		<img
		// 			src={localStorage.getItem('BANNER_DASHBOARD')}
		// 			alt=""
		// 			style={{
		// 				maxHeight: '250px',
		// 				width: '100%',
		// 				borderRadius: '10px'
		// 			}}
		// 		/>
		// 	);
		// }

		// switch (localStorage.getItem('JENIS_APLIKASI').toLowerCase()) {
		// 	case 'halaman kampung':
		// 		bannerAplikasi = (
		// 			<img
		// 				src={require('../assets/images/banner/sungai-petai.png')}
		// 				alt=""
		// 				style={{
		// 					maxHeight: '250px',
		// 					width: '100%',
		// 					borderRadius: '10px'
		// 				}}
		// 			/>
		// 		);
		// 		break;
		// }

		return (
			<div>
			  <SweetAlert
				show={this.state.show}
				type={this.state.basicType}
				title={this.state.basicTitle}
				confirmBtnBsStyle="success"
				onConfirm={this.closeAlert}
			  >
				{this.state.pesanAlert}
			  </SweetAlert>
			  {this.state.alert}
			  <div className="container-fluid">
				{/* <div className="row"> */}
				<div className="row">
				<div className="col-sm-12">
				{this.state.loading ? (
                  <div className="row">
                    <div className="col-sm-12" align="center">
					<br/><br/><br/><br/><br/>
                      <img 
                      alt="loading"
                      src={require("../assets/images/loading-bos.gif")} 
                      style={{
                        // width: "100%",
                        borderRadius: "10px",
                        width: '70px'
                      }}/> 
                    </div>
					<br/><br/><br/><br/><br/><br/><br/><br/>
					<br/><br/><br/><br/><br/><br/><br/><br/>
                  </div>
                ) : 
                <>
					<div className="row">
					  <div className="col-sm-12 col-md-6 col-lg-8">
						<div className="card layanan-baru">
						  <div className="card-body">
							<div className="row">{listLayanan}</div>
						  </div>
						</div>
					  </div>
					  <div className="col-sm-12 col-md-6 col-lg-4">
						<div className="row">
						  <div className="col-sm-12 col-md-12">
							<div className="card" style={{ borderRadius: "10px" }}>
							  <div className="text-center">
								{bannerAplikasi}
							  </div>
							</div>
						  </div>
						  {/* pengajuan selesai */}
						  <div className="col-sm-12 col-md-12">
							{/* <Link
							  to={{
								pathname:
								  process.env.PUBLIC_URL + "/admin/disposisi",
								tabProps: "5"
							  }}
							> */}
							  <div className="pengajuan-selesai">
								<b>
								  <span>
									{/* <b>24%</b> */}
									{this.state.jumlahSelesai}
								  </span>
								  <h4>Pengajuan</h4>
								  {/* <span>100 Pengajuan</span> */}
								</b>
							  </div>
							{/* </Link> */}
						  </div>
	
						  
						  {/* pengajuan menunggu eksekusi */}
						  {/* <div className="col-sm-12 col-md-12">
							<Link
							  to={{
								pathname:
								  process.env.PUBLIC_URL + "/admin/disposisi",
								tabProps: "2"
							  }}
							>
							  <div className="pengajuan-menunggu-eksekusi">
								<b>
								  <span>{this.state.jumlahMenungguEksekusi}</span>
								  <h4>Proses</h4>
								  <h6>Menunggu Eksekusi</h6>
								</b>
							  </div>
							</Link>
						  </div> */}
						  {/* pengaduan menunggu eksekusi */}
						  {/* <div className="col-sm-12 col-md-12">
							<div className="pengaduan-menunggu-eksekusi">
							  <b>
								<span>{this.state.jumlahPengaduanDiajukan}</span>
								<h4>Selesai</h4>
								<h6>Diajukan</h6>
							  </b>
							</div>
						  </div> */}
						  {/* catatan menunggu eksekusi */}
						  {/* <div className="col-sm-12 col-md-12">
							<Link
							  to={{
								pathname: process.env.PUBLIC_URL + "/admin/catatan",
								tabProps: "1"
							  }}
							>
							  <div className="catatan-menunggu-eksekusi">
								<b>
								  <span>
									{this.state.jumlahCatatanMenungguEksekusi}
								  </span>
								  <h4>Tolak</h4>
								  <h6>Belum Selesai</h6>
								</b>
							  </div>
							</Link>
						  </div> */}
						  {/* verifikasi menunggu eksekusi */}
						  {/* <div className="col-sm-12 col-md-12">
							<Link
							  to={{
								pathname:
								  process.env.PUBLIC_URL + "/admin/verifikasi",
								tabProps: "2"
							  }}
							>
							  <div className="verifikasi-menunggu-eksekusi">
								<b>
								  <span>
									{this.state.jumlahVerifikasiMenungguEksekusi}
								  </span>
								  <h4>Verifikasi</h4>
								  <h6>Menunggu Verifikasi</h6>
								</b>
							  </div>
							</Link>
						  </div> */}
						</div>
					  </div>
					</div>
					</>
					}
				  </div>
				  <div className="col-sm-12">
					<div className="row" style={{ marginTop: "50px" }}>
						<div className="col-sm-6 col-md-6 col-lg-3 mb-4">
							{/* <a
							href="https://solusidatamadani.co.id/web"
							target="_blank"
							rel="noopener noreferrer"
							> */}
							<div style={{ color: "#707070", textAlign: "center" }}>
								<img
								src={require("../assets/images/custom/gampande/logo_ternate.png")}
								alt=""
								style={{ maxWidth: "70%", maxHeight: "60px" }}
								/>
								&nbsp;&nbsp;&nbsp;
								<img
								src={require("../assets/images/custom/gampande/logo.png")}
								alt=""
								style={{ maxWidth: "70%", maxHeight: "80px" }}
								/>
							</div>
							{/* </a> */}
						</div>
						<div className="col-sm-6 col-md-6 col-lg-3 mb-4">
							<div
							style={{
								color: "#707070",
								textAlign: "center",
								wordWrap: "break-word"
							}}
							>
							<h5>
								<b>PT Theko Digital Solusindo</b>
							</h5>
							<h6>2022 All Rights Reserved</h6>
							</div>
						</div>
						<div className="col-sm-6 col-md-6 col-lg-3 mb-4">
							<div
							style={{
								color: "#707070",
								textAlign: "center",
								wordWrap: "break-word"
							}}
							>
							<h5>
								<b>Hubungi Kami</b>
							</h5>
							<a
								href="tel: (0812) 41958669"
								target="_blank"
								rel="noopener noreferrer"
								style={{ color: "#707070" }}
							>
								<h6>(0812) 4195 8669</h6>
							</a>
							<a
								href="mailto: info@gampande.id"
								target="_blank"
								rel="noopener noreferrer"
								style={{ color: "#707070" }}
							>
								<h6>info@gampande.id</h6>
							</a>
							</div>
						</div>
						<div className="col-sm-6 col-md-6 col-lg-3 mb-4">
							<div
							style={{
								color: "#707070",
								textAlign: "center",
								wordWrap: "break-word"
							}}
							>
							<span style={{ fontSize: "30px" }}>
								<a
								href="https://wa.me/6281241958669"
								target="_blank"
								rel="noopener noreferrer"
								style={{ color: "#707070" }}
								>
								<i className="icofont icofont-brand-whatsapp"></i>{" "}
								</a>
								<a
								href="https://www.instagram.com/thekonet.id/"
								target="_blank"
								rel="noopener noreferrer"
								style={{ color: "#707070" }}
								>
								<i className="fa fa-instagram"></i>
								</a>
							</span>
							<a
								href="https://gampande.id/"
								target="_blank"
								rel="noopener noreferrer"
								style={{ color: "#707070" }}
							>
								<h6>www.gampande.id</h6>
							</a>
							</div>
						</div>
					</div>
					</div>
				</div>
			  </div>
			</div>
		  );
		}
	  }
	  
	  export default Dashboard;