import React, { Component } from "react";
import { Link } from 'react-router-dom'

// import Custom Componenets
import Breadcrumb from "../components/common/breadcrumb.component";
import BootstrapTable from "react-bootstrap-table-next";
// import paginationFactory from 'react-bootstrap-table2-paginator';
import paginationFactory, {
  PaginationProvider,
  PaginationTotalStandalone,
  PaginationListStandalone
} from "react-bootstrap-table2-paginator";
import Datepicker from "react-datepicker";
import Lightbox from "react-image-lightbox";

import Modal from "react-responsive-modal";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  ButtonGroup,
  CustomInput,
  Row,
  Col
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

// koneksi
import { PostStatus, Post, Get, Delete, cekLogin } from "../function/Koneksi";

// css files
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import DatePicker from "reactdatepicker";

//json file
// var data = require('../assets/json/company');
const frmDef = {
  uuid: "",
  username: "",
  password: "",
  nik: 0,
  id_role: "",
  status: null,
  no_hp: null,
  email: null
};

class Perangkat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pagination: [],
      status: {
        form: false,
        btnForm: false,
        btnAksi: false,
      },
      awal: "",
      sebelum: "",
      hal: [],
      setelah: "",
      akhir: "",
      statUbah: false,
      dt: frmDef,
      alert: null,
      show: false,
      basicTitle: "",
      basicType: "default",
      pesanAlert: "",
      // =========== Select 2 ============
      frmInstansi: [],
      statInstansi: null,
    };
  }


  // ================== Sweet Alert ==================
  closeAlert = () => {
    this.setState({
      show: false
    });
  };

  onRecieveInput = value => {
    this.setState({
      alert: (
        <SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
          You wrote: {value}
        </SweetAlert>
      )
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  // =================================================

  // ================== Ambil data dari db untuk table ==================
  fetch = (params = {}) => {
    let link = "";
    let where = [];
    if (params.where) {
      where = params.where;
    }
    if (params.page) {
      switch (params.page) {
        case ">>":
          link =
            "?page=" +
            Math.ceil(
              this.state.pagination.total / this.state.pagination.per_page
            );
          break;
        case ">":
          link = "?page=" + (parseInt(this.state.pagination.current_page) + 1);
          break;
        case "<":
          link = "?page=" + (parseInt(this.state.pagination.current_page) - 1);
          break;
        case "<<":
          link = "?page=1";
          break;
        default:
          link = "?page=" + params.page;
          break;
      }
    }
    Get("instansi" + link, null, dtkat => {
      console.log("Data Instansi")
      console.log(dtkat)
      this.setState({ data: dtkat.results.data, pagination: dtkat.results });
      this.pagination(dtkat.results);
    });
  };

  // Pagination
  pagination = data => {
    // const data = this.state.pagination;
    console.log(data);
    let awal = "";
    let hal = [];
    let sebelum = "";
    let setelah = "";
    let akhir = "";
    if (data.total > 0) {
      let start = 1;
      let end = 5;
      let n = 0;
      let p = 0;
      if (data.current_page <= 3) {
        start = 1;
        end = 5;
        if (data.last_page > data.current_page) {
          n = data.current_page + 1;
        }
      } else {
        p = data.current_page - 1;
        n = data.current_page + 1;
        start = data.current_page - 2;
        end = data.current_page + 2;
      }
      if (end >= data.last_page - 2) {
        p = data.current_page - 1;
        if (start >= 5) {
          start = data.last_page - 4;
        }
        end = data.last_page;
      }

      for (let i = start; i <= end; i++) {
        let warna = "success";
        if (i == data.current_page) {
          warna = "danger";
        }
        hal.push(
          <a
            onClick={() => this.fetch({ page: i })}
            className={
              "btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
            }
          >
            {i}
          </a>
        );
      }
      if (p > 0) {
        sebelum = (
          <a
            onClick={() => this.fetch({ page: p })}
            className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {"<"}
          </a>
        );
      }
      if (n > 0) {
        setelah = (
          <a
            onClick={() => this.fetch({ page: n })}
            className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {">"}
          </a>
        );
      }
      awal = (
        <a
          onClick={() => this.fetch({ page: 1 })}
          className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {"<<"}
        </a>
      );
      akhir = (
        <a
          onClick={() => this.fetch({ page: data.last_page })}
          className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {">>"}
        </a>
      );
    }
    this.setState({ awal, sebelum, hal, setelah, akhir });
  };
  // Cari Data Table
  cariData = e => {
    if (e.key == "Enter") {
      let addRole = {
        q: e.target.value
      };
      cekLogin("jenis-surat", addRole, data => {
        this.setState({
          data: data.data.results.data,
          pagination: data.data.results
        });
      });
    }
  };

  // ========================================================================

  // ================== simpan data ==================
  simpan = e => {
    e.preventDefault();
    
    
    if (!this.state.dt.tingkat) {
      this.setState({
        show: true,
        basicType: "danger",
        basicTitle: "Data Instansi",
        pesanAlert: "Data Tingkat Instansi Tidak Boleh Kosong"
      });
    }
    else if (!this.state.dt.jenis) {
      this.setState({
        show: true,
        basicType: "danger",
        basicTitle: "Data Instansi",
        pesanAlert: "Data Jenis Instansi Tidak Boleh Kosong"
      });
    }
    else{
      // let id_wil = "";
      //   if(this.state.dt.tingkat.value=="Kecamatan"){
      //     id_wil = this.state.kecStat.value;
      //   }
      //   else if(this.state.dt.tingkat.value=="Kabupaten"){
      //     id_wil = this.state.kbktStat.value
      //   }
      let add =null
        if(this.state.dt.tingkat.value=="Kecamatan"){
          if(!this.state.kbktStat || !this.state.kecStat){
            this.setState({
              show: true,
              basicType: "danger",
              basicTitle: "Data Instansi",
              pesanAlert: "Data Kecamatan Tidak Boleh Kosong"
            });
          }
          else{
            add = {
              uuid: this.state.dt.uuid,
              id_wilayah: this.state.kecStat.value,
              nama: document.getElementById("nama").value,
              tingkat: this.state.dt.tingkat.value,
              jenis: this.state.dt.jenis.value,
            };
          }
        }
        else if(this.state.dt.tingkat.value=="Kabupaten"){
          if(!this.state.kbktStat){
            this.setState({
              show: true,
              basicType: "danger",
              basicTitle: "Data Instansi",
              pesanAlert: "Data Kabupaten/Kota Tidak Boleh Kosong"
            });
          }
          else{
            add = {
              uuid: this.state.dt.uuid,
              id_wilayah: this.state.kbktStat.value,
              nama: document.getElementById("nama").value,
              tingkat: this.state.dt.tingkat.value,
              jenis: this.state.dt.jenis.value,
            };
          }
        }
          
          if(add){
            console.log("Data Simpan")
            console.log(add)
            let psn = "";
            let resstat = 204;
            let metode = "create";

            if (add.uuid == "") {
              psn = "Tambah";
              resstat = 201;
              add.uuid = null;
            } else {
              psn = "Ubah";
              resstat = 200;
              metode = "update";
            }
            
            PostStatus("instansi/" + metode, add.uuid, add, res => {
              console.log(res);
              if (res === resstat) {
                this.setState({
                  show: true,
                  basicType: "success",
                  basicTitle: "Data Instansi",
                  pesanAlert: "Berhasil " + psn + " Data"
                });
                this.state.status.form = false;
                this.state.status.btnForm = false;
              } else {
                this.setState({
                  show: true,
                  basicType: "danger",
                  basicTitle: "Data Instansi",
                  pesanAlert: "Gagal " + psn + " Data"
                });
              }
              this.componentDidMount();
              this.forceUpdate();
            });
          }
          else{
            this.setState({
              show: true,
              basicType: "danger",
              basicTitle: "Data Instansi",
              pesanAlert: "Data Instansi Tidak Boleh Kosong"
            });
          }
    }
    // else{
    //   this.setState({
    //     show: true,
    //     basicType: "danger",
    //     basicTitle: "Data Instansi",
    //     pesanAlert: "Data Instansi Tidak Boleh Kosong"
    //   });
    // }
        // let id_wil = "";
        // if(this.state.dt.tingkat.value=="Kecamatan"){
        //   id_wil = this.state.kecStat.value;
        // }
        // else if(this.state.dt.tingkat.value=="Kabupaten"){
        //   id_wil = this.state.kbktStat.value
        // }
        
        //   let add = {
        //     uuid: this.state.dt.uuid,
        //     id_wilayah: id_wil,
        //     nama: document.getElementById("nama").value,
        //     tingkat: this.state.dt.tingkat.value,
        //     jenis: this.state.dt.jenis.value,
        //   };
          
        //   console.log("Data Simpan")
        //   console.log(add)
        //   let psn = "";
        //   let resstat = 204;
        //   let metode = "create";

        //   if (add.uuid == "") {
        //     psn = "Tambah";
        //     resstat = 201;
        //     add.uuid = null;
        //   } else {
        //     psn = "Ubah";
        //     resstat = 200;
        //     metode = "update";
        //   }
          
        //   Post("instansi/" + metode, add.uuid, add, res => {
        //     console.log(res);
        //     if (res === resstat) {
        //       this.setState({
        //         show: true,
        //         basicType: "success",
        //         basicTitle: "Data Instansi",
        //         pesanAlert: "Berhasil " + psn + " Data"
        //       });
        //       this.state.status.form = false;
        //       this.state.status.btnForm = false;
        //     } else {
        //       this.setState({
        //         show: true,
        //         basicType: "danger",
        //         basicTitle: "Data Instansi",
        //         pesanAlert: "Gagal " + psn + " Data"
        //       });
        //     }
        //     this.componentDidMount();
        //     this.forceUpdate();
        //   });
       
      // }
      
      // else{
      //   this.setState({
      //     show: true,
      //     basicType: "danger",
      //     basicTitle: "Data Instansi",
      //     pesanAlert: "Data Instansi Tidak Boleh Kosong"
      //   });
      // }
  };
  // ========================================================================

  
  // ============================== Ubah Data ===============================

  ubahData = data => {
    console.log("Ubah Datanya")
    console.log(data)

    let prov = null;
    let kab = null;
    let kec = null;
    if(data.data_kabkota){
      prov={value: data.data_kabkota.data_provinsi.id, label: data.data_kabkota.data_provinsi.nama}
      kab={value: data.data_kabkota.id, label: data.data_kabkota.nama}
    }
    else if(data.data_kecamatan){
      prov={value: data.data_kecamatan.data_kabkota.data_provinsi.id, label: data.data_kecamatan.data_kabkota.data_provinsi.nama}
      kab={value: data.data_kecamatan.data_kabkota.id, label: data.data_kecamatan.data_kabkota.nama}
      kec={value: data.data_kecamatan.id, label: data.data_kecamatan.nama}
    }

    this.setState({
      prvStat: prov,
      kbktStat: kab,
      kecStat: kec,
      dt:{
        uuid: data.uuid,
        nama: data.nama,
        tingkat: {value: data.tingkat, label: data.tingkat},
        jenis: {value: data.jenis, label: data.jenis},
      }
    })
      
      // this.state.status.btnAksi = false;
      this.forceUpdate();
      this.bukaForm();
  };
  
 
  // ========================================================================
  // ============================= Hapus Data ===============================
  konfirmHapus = id => {
    this.setState({
      alert: (
        <SweetAlert
          showCancel
          confirmBtnText="Hapus"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="success"
          type="danger"
          title="Yakin ingin hapus data ?"
          onCancel={this.hideAlert}
          onConfirm={() => this.hapusData(id)}
        >
          Proses ini tidak dapat dibatalkan!
        </SweetAlert>
      )
    });
  };

  hapusData = id => {
    this.hideAlert();
    let psn = "Hapus";
    Delete("instansi/delete", id, res => {
      if (res == 200) {
        this.setState({
          show: true,
          basicType: "success",
          basicTitle: "Data Instansi",
          pesanAlert: "Berhasil " + psn + " Data"
        });
      } else {
        this.setState({
          show: true,
          basicType: "danger",
          basicTitle: "Data Instansi",
          pesanAlert: "Gagal " + psn + " Data"
        });
      }
      this.componentDidMount();
    });
  };
  // ========================================================================

  bukaForm = () => {
    this.state.status.form = true;
    this.forceUpdate();
  };

  tutupForm = () => {
    this.state.dt.uuid = null
    this.state.prvStat = null
    this.state.kbktStat = null
    this.state.kecStat = null
    this.state.dt = []
    
    this.state.status.form = false;
    this.forceUpdate();
  };

  tombolAksi = (cell, row) => {
    console.log(row)
    return (
      <>
        <Link to={{ pathname: "/admin/instansiDetail", state: row }}>
					<Button size="xs" color="primary" className="btn-eye"><i className="fa fa-eye"></i></Button>{" "}
				</Link>
        <Button
          size="xs"
          color="success"
          className="btn-icon"
          onClick={() => {
            this.setState({
              judul: "Ubah Perangkat",
              statUbah: false
            });
            this.ubahData(row);
          }}
          disabled={this.state.status.btnAksi}
        >
          <i className="fa fa-pencil"></i>
        </Button>{" "}
        <Button 
          size="xs" 
          color="danger" 
          className="btn-icon" 
          onClick={() => this.konfirmHapus(row.uuid)} disabled={this.state.status.btnAksi}
        >
          <i className="fa fa-trash"></i>
        </Button>
      </>
    );
  };

  

  componentDidMount() {
    this.fetch({ page: null, where: [] });

    Get('provinsi/list', null, (data) => {
			let frmPrv = [];
			data.results.map((dt) => {
				frmPrv.push({ value: dt.id, label: dt.nama });
			});
			this.setState({ frmPrv });
		});
  }

  getChild = (e, self, parent, child) => {
		this.state.status.select = true;
		this.forceUpdate();
    this.changeSelectValue(e, self);
    let formDusun = this.state.frmDus

    console.log("===================")
    console.log("Data Get CHild")
    console.log(e, self, parent, child)
    console.log("===================")
		if (e) {
      if(self=="keldes"){

      }
      else{
        Get(child + '/' + parent + '/' + e.value + '/list', null, (data) => {
          let frm = [];
          data.results.map((dt) => {
            frm.push({ value: dt.id, label: dt.nama });
          });
          switch (child) {
            case 'kab-kota':
              this.setState({ frmKbkt: frm, frmKec: [] });
              break;
            case 'kecamatan':
              this.setState({ frmKec: frm });
              break;
            case 'keldes':
              this.setState({ frmKel: frm });
              break;
            case 'dusun':
              if (this.state.id_kategori_penduduk.value == 1) {
                this.setState({ frmDus: frm });
              }
              else {
                this.setState({ frmDus: formDusun });
              }
              break;

            case 'rw':
              this.setState({ frmRw: frm });
              break;

            case 'rt':
              this.setState({ frmRt: frm });
              break;
          }
          this.state.status.select = false;
          this.forceUpdate();
        });
      }
		}
	};

  changeSelectValue = (e, sel) => {
		if (e) {
			switch (sel) {
				case 'provinsi':
					this.setState({ prvStat: e });
					this.setState({ kbktStat: null });
					this.setState({ kecStat: null });
					break;
				case 'kab-kota':
					this.setState({ kbktStat: e });
					this.setState({ kecStat: null });
					break;
				case 'kecamatan':
					this.setState({ kecStat: e });
					this.setState({ kelStat: null });

					break;
        case 'keldes':
          // let idDus = this.state.dusStat
          this.setState({ kelStat: e });
          this.setState({ dusStat: null });
          break;

				case 'dusun':
					this.setState({ dusStat: e });
					this.setState({ rwStat: null });

					break;

				case 'rw':
					this.setState({ rwStat: e });
					this.setState({ rtStat: null });

					break;

				case 'rt':
					this.state.dt.id_rt = e.value;
					this.setState({ rtStat: e });
					// console.log(this.state.rtStat)
					break;
				case 'ukuran_kertas':
					this.state.dt.id_ukuran_kertas = e.value;
					this.setState({ ukkStat: e });
					break;

				case 'shdk':
					this.state.dt.shdk = e.value;
					this.setState({ shdkStat: e });
					break;
				case 'agama':
					this.state.dt.agama = e.value;
					this.setState({ agmStat: e });
					break;
				case 'pendidikan-terakhir':
					this.state.dt.pendidikan_terakhir = e.value;
					this.setState({ ptrStat: e });
					break;
				case 'pekerjaan':
					this.state.dt.pekerjaan = e.value;
					this.setState({ pkrStat: e });
					break;
				case 'kk':
					this.state.dt.id_kk = e.value;
					this.setState({ kkStat: e });
					break;
				case 'status-penduduk':
					this.state.dt.status_penduduk = e.value;
					this.setState({ stpStat: e });
					break;
				case 'jk':
					this.state.dt.jk = e.value;
					this.setState({ jkStat: e });
					break;
				case 'status-perkawinan':
					this.state.dt.status_perkawinan = e.value;
					this.setState({ spkStat: e });
					break;
				case 'agamakk':
					this.setState({ agmkkStat: e });
					break;
				case 'pendidikan-terakhirkk':
					this.setState({ ptrkkStat: e });
					break;
				case 'pekerjaankk':
					this.setState({ pkrkkStat: e });
					break;
				case 'status-pendudukkk':
					this.setState({ stpkkStat: e });
					break;
				case 'jkkk':
					this.setState({ jkkkStat: e });
					break;
				case 'status-perkawinankk':
					this.setState({ spkkkStat: e });
					break;
        case 'tingkat':
					this.state.dt.tingkat = e;
          this.state.prvStat= null;
          this.state.kbktStat= null;
          this.state.kecStat= null;
					break;
        case 'jenis':
					this.state.dt.jenis = e;
					break;
			}
		} else {
			switch (sel) {
				case 'provinsi':
					this.setState({ prvStat: null });
					this.setState({ kbktStat: null, frmKbkt: [] });
					this.setState({ kecStat: null, frmKec: [] });
					this.state.dt.id_rt = 0;
					this.setState({
						kelStat: null,
						frmKel: [],
						frmDus: [],
						dusStat: null,
						frmRw: [],
						rwStat: null,
						frmRt: [],
						rtStat: null
					});
					break;
				case 'kab-kota':
					this.setState({ kbktStat: null });
					this.setState({ kecStat: null, frmKec: [] });
					this.state.dt.id_rt = 0;
					this.setState({
						kelStat: null,
						frmKel: [],
						frmDus: [],
						dusStat: null,
						frmRw: [],
						rwStat: null,
						frmRt: [],
						rtStat: null
					});

					break;
				case 'kecamatan':
					this.setState({ kecStat: null });
					this.state.dt.id_rt = 0;
					this.setState({
						kelStat: null,
						frmKel: [],
						frmDus: [],
						dusStat: null,
						frmRw: [],
						rwStat: null,
						frmRt: [],
						rtStat: null
					});

					break;
				case 'keldes':
					this.state.dt.id_rt = 0;
					this.setState({
						kelStat: null,
						frmDus: [],
						dusStat: null,
						frmRw: [],
						rwStat: null,
						frmRt: [],
						rtStat: null
					});
					break;
				case 'dusun':
					this.state.dt.id_rt = 0;
					this.setState({ dusStat: null, frmrw: [], rwStat: null, frmRt: [], rtStat: null });
					break;
				case 'rw':
					this.state.dt.id_rt = 0;
					this.setState({ rwStat: null, frmRt: [], rtStat: null });
					break;
				case 'rt':
					this.state.dt.id_rt = 0;
					this.setState({ rtStat: null });
					break;
				case 'shdk':
					this.state.dt.shdk = 0;
					this.setState({ shdkStat: null });
					break;
				case 'agama':
					this.state.dt.agama = 0;
					this.setState({ agmStat: null });
					break;
				case 'pendidikan-terakhir':
					this.state.dt.pendidikan_terakhir = 0;
					this.setState({ ptrStat: null });
					break;
				case 'pekerjaan':
					this.state.dt.pekerjaan = 0;
					this.setState({ pkrStat: null });
					break;
				case 'kk':
					this.state.dt.id_kk = 0;
					this.setState({ kkStat: null });
					break;
				case 'status-penduduk':
					this.state.dt.status_penduduk = 0;
					this.setState({ stpStat: e });
					break;
				case 'jk':
					this.state.dt.jk = 0;
					this.setState({ jkStat: e });
					break;
				case 'status-perkawinan':
					this.state.dt.status_perkawinan = '';
					this.setState({ spkStat: e });
					break;
				case 'jenis-berkas':
					this.setState({ jbkStat: e });
					break;
				case 'status-berkas':
					this.setState({ sbkStat: e });
					break;
				case 'agamakk':
					this.setState({ agmkkStat: e });
					break;
				case 'pendidikan-terakhirkk':
					this.setState({ ptrkkStat: e });
					break;
				case 'pekerjaankk':
					this.setState({ pkrkkStat: e });
					break;
				case 'status-pendudukkk':
					this.setState({ stpkkStat: e });
					break;
				case 'jkkk':
					this.setState({ jkkkStat: e });
					break;
				case 'status-perkawinankk':
					this.setState({ spkkkStat: e });
					break;
        case 'tingkat':
					this.state.dt.tingkat = null;
          this.state.prvStat=null;
          this.state.kbktStat=null;
          this.state.kecStat=null;
					break;
        case 'jenis':
					this.state.dt.jenis = null;
					break;
			}
		}
		this.state.status.select = false;
		this.forceUpdate();
	};

  render() {
    // console.log("Data Render Instansi")
    // console.log(this.state.statInstansi)
    // // console.log(this.state.id_jenisUser)
    // console.log(this.state.prvStat)
    // console.log(this.state.kbktStat)
    // console.log(this.state.kecStat)
    // console.log(this.state.dusStat)
    const { photoIndex, isOpen } = this.state;

    var size = Math.ceil(
      this.state.pagination.total / this.state.pagination.per_page
    );
    // var size = this.state.pagination.total;
    var from = this.state.pagination.from;
    var page = this.state.pagination.current;
    var to = this.state.pagination.to;
    var total = this.state.pagination.total;
    var data = this.state.data;

    // var data = require('../assets/json/company');

    const frmDef = {
      uuid: "",
      username: "",
      password: "",
      nik: "",
      id_role: "",
      status: null,
      no_hp: null,
      email: null
    };
    const { SearchBar } = Search;
    const columns = [
      {
        dataField: "nama",
        text: "Nama",
        sort: true
      },
      {
        dataField: "tingkat",
        text: "Tingkat",
        sort: true
      },
      {
        dataField: "jenis",
        text: "Jenis",
        sort: true
      },
      {
        dataField: "data_kabkota",
        text: "Kabupaten/Kota",
        sort: true,
        formatter: (cell, obj) => {
          console.log('obj')
          console.log(obj)
          if(obj.data_kabkota){
            return (obj.data_kabkota.nama);
          }
          else{
            return ('-');
          }
        },
      },
      {
        dataField: "data_kecamatan",
        text: "Kecamatan",
        sort: true,
        formatter: (cell, obj) => {
          console.log('obj')
          console.log(obj)
          if(obj.data_kecamatan){
            return (obj.data_kecamatan.nama);
          }
          else{
            return ('-');
          }
        },
      },
      {
        dataField: "aksi",
        text: "Aksi",
        isDummyField: true,
        csvExport: false,
        formatter: this.tombolAksi
      }
    ];

    return (
      <div>
        {/* <Breadcrumb title="Penguna" parent="Admin" /> */}
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          confirmBtnBsStyle="success"
          onConfirm={this.closeAlert}
        >
          {this.state.pesanAlert}
        </SweetAlert>

        {this.state.alert}

        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card mb-0">
              <div className="card-header">
                    <div className="row">
                        <div className="col-sm-6">
                            {/* <h5>Data Dusun</h5> */}
                            {/* <span>DataTables has most features enabled by default, so all you need to do to use it with your own tables is to call the construction function:<code>$().DataTable();</code>.</span><span>Searching, ordering and paging goodness will be immediately added to the table, as shown in this example.</span> */}
                        </div>
                        <div className="col-sm-6 text-right">
                            <Button size="sm" color="success" className="btn-square" onClick={() => {
                                this.setState({ judul: "Tambah Perangkat", dt: frmDef, prvStat: null, kbktStat: null, kecStat: null, kldsStat: null});  
                                this.state.dt.uuid = null
                                this.state.id_tingkatUser = null
                                this.state.id_jenisUser = null
                                this.forceUpdate();
                                this.bukaForm();
                            }}>Tambah Data</Button>
                        </div>
                    </div>
                </div>
                <div className="card-body datatable-react">
                  <BootstrapTable keyField="id" data={data} columns={columns} />
                  <div className="pull-right text-white">
                    {this.state.awal}
                    {this.state.sebelum}
                    {this.state.hal.map(dt => {
                      return dt;
                    })}
                    {this.state.setelah}
                    {this.state.akhir}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />

        <Modal
          size="lg"
          open={this.state.status.form}
          onClose={this.tutupForm}
          styles={{ modal: { width: "80%" } }}
          closeOnEsc={false}
          closeOnOverlayClick={false}
        >
          <div size="lg" className="modal-header">
            <h5 className="modal-title">{this.state.judul}</h5>
          </div>
          <Form className="theme-form" onSubmit={this.simpan}>
            <div className="modal-body">
              <input
                className="form-control"
                id="uuid"
                type="hidden"
                placeholder="UUID"
                defaultValue={this.state.dt.uuid}
              />

              <FormGroup className="row">
                <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
                  Tingkat Instansi
                </Label>
                <Col sm="9">
                  <Select
                    // isDisabled={this.state.status.select}
                    classNamePrefix="select"
                    onChange={(e) => {
                      this.changeSelectValue(e, 'tingkat');
                    }}
                    value={this.state.dt.tingkat}
                    defaultValue={this.state.dt.tingkat}
                    name="tingkat"
                    options={[
                      {value: 'Kabupaten', label: 'Kabupaten'},
                      {value: 'Kecamatan', label: 'Kecamatan'},
                    ]}
                    placeholder="Pilih Tingkat Instansi"
                    isClearable
                  />
                </Col>
              </FormGroup>

            { this.state.dt.tingkat && this.state.dt.tingkat.label == "Kabupaten"
            ?
            <>
              <FormGroup className="row">
                <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
                  Provinsi
                </Label>
                <Col sm="9">
                  <Select
                    isDisabled={this.state.status.select}
                    classNamePrefix="select"
                    onChange={(e) => {
                      this.getChild(e, 'provinsi', 'prov', 'kab-kota');
                    }}
                    defaultValue={this.state.prvStat}
                    value={this.state.prvStat}
                    name="provinsi"
                    options={this.state.frmPrv}
                    placeholder="Pilih Provinsi"
                    isClearable
                  />
                </Col>
              </FormGroup>

              <FormGroup className="row">
                <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
                  Kabupaten/Kota
                </Label>
                <Col sm="9">
                <Select
                    isDisabled={this.state.status.select}
                    classNamePrefix="select"
                    onChange={(e) => {
                      this.getChild(e, 'kab-kota', 'kab', 'kecamatan');
                    }}
                    defaultValue={this.state.kbktStat}
                    value={this.state.kbktStat}
                    name="kabkota"
                    options={this.state.frmKbkt}
                    placeholder="Pilih Kabupaten/Kota"
                    isClearable
                  />
                </Col>
              </FormGroup>
            </>
            :
            this.state.dt.tingkat && this.state.dt.tingkat.label == "Kecamatan"
            ?
            <>
              <FormGroup className="row">
                <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
                  Provinsi
                </Label>
                <Col sm="9">
                  <Select
                    isDisabled={this.state.status.select}
                    classNamePrefix="select"
                    onChange={(e) => {
                      this.getChild(e, 'provinsi', 'prov', 'kab-kota');
                    }}
                    defaultValue={this.state.prvStat}
                    value={this.state.prvStat}
                    name="provinsi"
                    options={this.state.frmPrv}
                    placeholder="Pilih Provinsi"
                    isClearable
                  />
                </Col>
              </FormGroup>

              <FormGroup className="row">
                <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
                  Kabupaten/Kota
                </Label>
                <Col sm="9">
                <Select
                    isDisabled={this.state.status.select}
                    classNamePrefix="select"
                    onChange={(e) => {
                      this.getChild(e, 'kab-kota', 'kab', 'kecamatan');
                    }}
                    defaultValue={this.state.kbktStat}
                    value={this.state.kbktStat}
                    name="kabkota"
                    options={this.state.frmKbkt}
                    placeholder="Pilih Kabupaten/Kota"
                    isClearable
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
                  Kecamatan
                </Label>
                <Col sm="9">
                <Select
                    isDisabled={this.state.status.select}
                    classNamePrefix="select"
                    onChange={(e) => {
                      this.getChild(e, 'kecamatan', 'kec', 'keldes');
                    }}
                    defaultValue={this.state.kecStat}
                    value={this.state.kecStat}
                    name="kecamatan"
                    options={this.state.frmKec}
                    placeholder="Pilih Kecamatan"
                    isClearable
                  />
                </Col>
              </FormGroup>
              </>
              :
              <>
              </>
            }
              
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Nama
                </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="nama"
                    type="text"
                    placeholder="Masukkan Nama Instansi"
                    required
                    defaultValue={this.state.dt.nama}
                  />
                </Col>
              </FormGroup>

              <FormGroup className="row">
                <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
                  Jenis Instansi
                </Label>
                <Col sm="9">
                  <Select
                    // isDisabled={this.state.status.select}
                    classNamePrefix="select"
                    onChange={(e) => {
                      this.changeSelectValue(e, 'jenis');
                    }}
                    value={this.state.dt.jenis}
                    defaultValue={this.state.dt.jenis}
                    name="jenis"
                    options={[
                      {value: 'Capil', label: 'Capil'},
                      {value: 'KUA', label: 'KUA'},
                      {value: 'Kecamatan', label: 'Kecamatan'},
                    ]}
                    placeholder="Pilih Jenis Instansi"
                    isClearable
                  />
                </Col>
              </FormGroup>

            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-warning"
                // disabled={this.state.status.btnForm}
                onClick={this.tutupForm}
              >
                Tutup
              </button>
              <button
                type="submit"
                className="btn btn-success"
                // disabled={this.state.status.btnForm}
              >
                Simpan
              </button>
            </div>
          </Form>
        </Modal>
      </div>
    );
  }
}

export default Perangkat;
